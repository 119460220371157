import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { requestDashboard } from 'redux/modules/dashboard/actions';
import { useAuth0 } from '@auth0/auth0-react';
import DashboardTable from 'containers/DashboardTable';
import { IoCheckmarkCircleOutline, IoRefreshCircleOutline } from 'react-icons/io5';
import { getRawToken } from 'utils/auth';

const Statistics = () => {
  const dispatch = useDispatch();
  const { getIdTokenClaims } = useAuth0();
  const [results, setResults] = useState([]);
  const [error, setError] = useState('');
  let refreshDashboard;

  const setDashboard = (data) => {
    setError('');
    setResults(data);
  };

  const getDashboardData = async () => {
    const token = await getIdTokenClaims();
    dispatch(
      requestDashboard({
        token: getRawToken(token),
        setDashboard: setDashboard,
        onError: onError,
      }),
    );
  };

  const onError = () => {
    setError('Error in fetching data, please try again later.');
  };

  useEffect(() => {
    getDashboardData();
    refreshDashboard = setInterval(() => {
      getDashboardData();
    }, 5000);
    return () => {
      clearInterval(refreshDashboard);
    };
  }, []);

  return (
    <div className="px-5 py-4 scrollable-page d-flex flex-column col-12">
      <div className="d-flex flex-justify-between mb-3 flex-content-center">
        <h5 className="color-primary-dark mb-3 mt-0">Dashboard</h5>
      </div>
      {!!error && (
        <p className="color-error pl-0">
          {error} or{' '}
          <span
            className="cursor-pointer color-secondary"
            onClick={() => window.location.reload()}
          >
            Reload
          </span>
        </p>
      )}
      {!!results.reports_in_progress && (
        <div className="row d-flex mb-5 flex-content-center text-center">
          <div className="mr-5 bg-white card col-3 position-relative">
            <IoCheckmarkCircleOutline className="h4 color-success fw-light indicator" />
            <p className="color-text-secondary small pt-3">LAST MONTH</p>
            <p className="color-success h2 p-0">
              {results.reports_generated_in_30_days.count}
            </p>
            <p className="color-text-secondary pb-5 h6">Reports Generated</p>
          </div>

          <div className="mr-5 bg-white card col-3 position-relative">
            <IoRefreshCircleOutline className="h4 color-warning fw-light indicator" />
            <p className="color-text-secondary small pt-5"></p>
            <p className="color-warning h2 p-0">{results.reports_in_progress.count}</p>
            <p className="color-text-secondary pb-5 h6">Reports In Progress</p>
          </div>
        </div>
      )}
      {!!results.reports_in_progress && (
        <>
          <h5 className="color-primary-dark">
            Reports in progress ({results.reports_in_progress.count})
          </h5>
          <div className="mb-3 p-3 card bg-white">
            <DashboardTable data={results.reports_in_progress.data} />
          </div>
        </>
      )}
    </div>
  );
};
export default Statistics;
