import React from 'react';

const LoadingBlock = () => {
  return (
    <div className="p-5 bg-white card overflow-auto">
      <div className="timeline-wrapper d-flex">
        <div className="timeline-item col-8">
          <div className="animated-background">
            <div className="background-masker content-top"></div>
            <div className="background-masker content-first-end"></div>
            <div className="background-masker content-second-line"></div>
            <div className="background-masker content-second-end"></div>
            <div className="background-masker content-third-line"></div>
          </div>
        </div>
        <div className="timeline-item flex-1 ml-3">
          <div className="animated-background">
            <div className="background-masker content-top"></div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default LoadingBlock;
