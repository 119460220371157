import React from 'react';

const AuthLoader = ({ absolute, faded }) => {
  return (
    <div
      className={`authloader top-0 left-0 right-0 bottom-0 bg-white d-flex flex-items-center flex-justify-center ${
        !!absolute ? 'position-absolute' : 'position-fixed'
      }
      ${!!faded ? 'faded' : ''}`}
    >
      <div className="lds-roller">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};
export default AuthLoader;
