import axios from 'axios';

export const viewArtifact = async ({ id, token }) => {
  return axios({
    method: 'get',
    url: `/patient/view-artifacts/?claim_id=${id}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => ({ response: response.data }))
    .catch((error) => ({ error: error.response }));
};

export const getProgressNotes = async ({ id, token, guid }) => {
  return axios({
    method: 'get',
    url: `/claim/${id}/progress-notes/?guid=${guid}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => ({ response: response.data }))
    .catch((error) => ({ error: error.response }));
};

export const getLabReports = async ({ id, token, guid }) => {
  return axios({
    method: 'get',
    url: `/claim/${id}/lab-reports/?guid=${guid}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => ({ response: response.data }))
    .catch((error) => ({ error: error.response }));
};
