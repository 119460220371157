import { SET_ERROR_MODAL } from './actions';

let initialState = {
  isVisible: false,
  fallbackUrl: '',
};

const reducer = (state = initialState, { payload, type }) => {
  switch (type) {
    case SET_ERROR_MODAL:
      return { ...state, ...payload };
    default:
      return state;
  }
};

export default reducer;
