import axios from 'axios';

export const generateReport = async ({ params, token }) => {
  return axios({
    method: 'post',
    url: '/patient/generate/',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    data: params,
  })
    .then((response) => ({ response: response.data }))
    .catch((error) => ({ error: error.response }));
};

export const generateReportInPDF = async ({ token, id }) => {
  return axios({
    method: 'post',
    url: `/patient/artifact-pdf/`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      claim_resource_id: id,
    },
  })
    .then((response) => ({ response: response.data }))
    .catch((error) => ({ error: error.response }));
};

export const generateReportProgress = async ({ job_id, token }) => {
  return axios({
    method: 'get',
    url: `/celery-progress/${job_id}/`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => ({ response: response.data }))
    .catch((error) => ({ error: error.response }));
};
