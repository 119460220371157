import React from 'react';

const LoadingMessageBlock = () => {
  return (
    <>
      <div className="p-3 bg-white card overflow-hidden message-loader">
        <div className="timeline-wrapper d-flex">
          <div className="timeline-item round-view">
            <div className="animated-background"></div>
          </div>
          <div className="timeline-item flex-1 ml-3">
            <div className="animated-background">
              <div className="background-masker content-first-end"></div>
              <div className="background-masker content-second-line"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="p-3 bg-white card overflow-hidden message-loader">
        <div className="timeline-wrapper d-flex">
          <div className="timeline-item round-view">
            <div className="animated-background"></div>
          </div>
          <div className="timeline-item flex-1 ml-3">
            <div className="animated-background" style={{ width: '85%' }}>
              <div className="background-masker content-first-end"></div>
              <div className="background-masker content-second-line"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="p-3 bg-white card overflow-hidden message-loader">
        <div className="timeline-wrapper d-flex">
          <div className="timeline-item round-view">
            <div className="animated-background"></div>
          </div>
          <div className="timeline-item flex-1 ml-3">
            <div className="animated-background" style={{ width: '45%' }}>
              <div className="background-masker content-first-end"></div>
              <div className="background-masker content-second-line"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default LoadingMessageBlock;
