import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  requestGetArtifactData,
  requestProgressNotes,
  requestLabReports,
} from 'redux/modules/viewArtifact/actions';
import { requestPdfReport } from 'redux/modules/generateReport/actions';
import { useAuth0 } from '@auth0/auth0-react';
import { formatAddress } from 'utils/addressFormat';
import AuthLoader from 'components/AuthLoader';
import { IoDownloadOutline, IoPencil } from 'react-icons/io5';
import { getRawToken } from 'utils/auth';
import { MdOutlineCopyAll } from 'react-icons/md';
import { toast } from 'react-toastify';

const ToggleFetch = ({ data, index, id }) => {
  const [show, setShow] = useState(false);

  const getDataToDisplay = () => {
    if (!!data?.reports) {
      return data.reports.map((report, index) => {
        return (
          <div key={index}>
            <p className="fw-semibold fs-regular">{report.group}</p>
            <table className="table border-l border-r border-t">
              <tbody>
                {report.data.map((test) => (
                  <tr key={test.guid}>
                    <td className="data-info p-8 lab-text">{test.text || '-'}</td>
                    <td className="data-info p-8 text-left lab-value">
                      {test.value || '-'}
                    </td>
                    {test.interpretation && (
                      <td className="data-info p-8">{test.interpretation || ''}</td>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        );
      });
    } else {
      return !!data.main_data ? data.main_data : 'No data found.';
    }
  };

  return (
    <>
      <div className="data-row printBlock test-data" id={id}>
        <p className="data-head fs-medium">
          {data?.reports
            ? `Date: ${data.date}`
            : `Date: ${data.date} - Note #${index + 1}`}
          {!show && (
            <span className="data-link" onClick={() => setShow(true)}>
              FETCH
            </span>
          )}
        </p>
        {show && <pre className="data-info fetched_data">{getDataToDisplay()}</pre>}
      </div>
      <div className="divider"></div>
    </>
  );
};

const ViewReport = () => {
  let { id } = useParams();
  const dispatch = useDispatch();
  const { getIdTokenClaims } = useAuth0();
  const viewArtifact = useSelector(({ viewArtifact }) => viewArtifact);
  const artifactsData = viewArtifact.artifactData;
  const isLoading = viewArtifact.isLoading;
  const history = useHistory();
  const [isLoadingExtraData, setIsLoadingExtraData] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    const getArtiFactData = async (id) => {
      const token = await getIdTokenClaims();
      dispatch(
        requestGetArtifactData({
          id: id,
          token: getRawToken(token),
          onError: onErrorGetArtiFactData,
        }),
      );
    };
    getArtiFactData(id);
  }, [id, dispatch, getIdTokenClaims]);

  const onErrorGetArtiFactData = () => {
    setIsLoadingExtraData(false);
    setError('Error in fetching artifacts data, please try again later.');
  };

  const setProgressNotes = (data) => {
    setError('');
    setIsLoadingExtraData(false);
    if (!!data.length) {
      artifactsData.progress_notes.find((item) => item.guid === data[0].guid).main_data =
        data[0].main_data;
    } else {
      artifactsData.progress_notes.find((item) => item.guid === data[0].guid).main_data =
        'No data Found';
    }
  };

  const setLabReports = (data) => {
    setError('');
    setIsLoadingExtraData(false);
    if (!!data.length) {
      artifactsData.lab_reports.find((item) => item.guid === data[0].guid).report_data =
        data[0].report_data;
    } else {
      artifactsData.progress_notes.find(
        (item) => item.guid === data[0].guid,
      ).report_data = 'No data Found';
    }
  };

  const getProgressNotes = async (guid) => {
    setIsLoadingExtraData(true);
    const token = await getIdTokenClaims();
    dispatch(
      requestProgressNotes({
        id: id,
        token: getRawToken(token),
        guid: guid,
        setProgressNotes,
        onError: onErrorGettingReportData,
      }),
    );
  };

  // const getLabReports = async (guid) => {
  //   setIsLoadingExtraData(true);
  //   const token = await getIdTokenClaims();
  //   dispatch(
  //     requestLabReports({
  //       id: id,
  //       token: getRawToken(token),
  //       guid: guid,
  //       setLabReports,
  //       onError: onErrorGettingReportData,
  //     }),
  //   );
  // };

  const onErrorGettingReportData = () => {
    setIsLoadingExtraData(false);
    setError('Error in fetching report data, please try again later.');
  };

  const openPdfInNewTab = (data) => {
    setError('');
    window.open(data, '_blank');
  };

  const getPDF = async () => {
    const token = await getIdTokenClaims();
    dispatch(
      requestPdfReport({
        id: id,
        token: getRawToken(token),
        openPdfInNewTab,
        onError: onError,
      }),
    );
  };

  const onError = () => {
    setIsLoadingExtraData(false);
    setError('Error in creating PDF report, please try again later.');
  };

  return isLoading ? (
    <AuthLoader />
  ) : (
    <div className="view-report-page scrollable-page">
      {isLoadingExtraData && <AuthLoader />}
      <div className="container">
        <div className="d-flex flex-justify-between flex-items-center">
          <div className="d-flex flex-items-center">
            <h5>
              Physician Statement{' '}
              {!!Object.keys(artifactsData).length && artifactsData?.claim_date
                ? `| Claim date: ${moment(
                    moment(artifactsData.claim_date, 'YYYY-MM-DD'),
                  ).format('MM/DD/YYYY')}`
                : ''}{' '}
            </h5>
            <h5 className="ml-1">
              {!!Object.keys(artifactsData).length && artifactsData?.pass
                ? ` | Password: ${artifactsData?.pass}`
                : ''}
            </h5>
            <div className="tooltip ml-1 cursor-pointer">
              <MdOutlineCopyAll
                className="copy"
                size={21}
                onClick={() => {
                  navigator.clipboard.writeText(artifactsData?.pass);
                  toast.success('Password copied to clipboard!', {
                    toastId: 'copiedToClipboard',
                  });
                }}
              ></MdOutlineCopyAll>
              <span class="tooltiptext">Copy password to clipboard</span>
            </div>
          </div>

          <div className="d-flex flex-items-center">
            <button
              className={`btn-primary bg-round-faded pt-2 pl-2 pb-2 pr-3 ml-3 ${
                !!Object.keys(artifactsData).length ? '' : 'btn-disabled'
              } `}
              onClick={() =>
                history.push({
                  pathname: '/compose',
                  state: {
                    claimId: id,
                    claimDate: artifactsData?.claim_date,
                    patientId: artifactsData?.id,
                    name: `${artifactsData?.name ? artifactsData.name : ''}`,
                  },
                })
              }
              disabled={!!Object.keys(artifactsData).length ? false : true}
            >
              <IoPencil className="" /> Compose
            </button>
            <button
              className={`btn-primary bg-round-faded pt-2 pl-2 pb-2 pr-3 ml-3 ${
                !!Object.keys(artifactsData).length ? '' : 'btn-disabled'
              } `}
              onClick={getPDF}
              disabled={!!Object.keys(artifactsData).length ? false : true}
            >
              <IoDownloadOutline className="" /> Download PDF
            </button>
          </div>
        </div>
        {!!error && (
          <p className="color-error pl-0">
            {error} or{' '}
            <span
              className="cursor-pointer color-secondary"
              onClick={() => window.location.reload()}
            >
              Reload
            </span>
          </p>
        )}

        {!!!Object.keys(artifactsData).length ? (
          <div className="mt-4 mb-3 block">No data found</div>
        ) : (
          <>
            <h6 className="mt-4 mb-3">Patient Information</h6>
            <div className="block">
              <div className="data-row">
                <p className="data-head">Name</p>
                <p className="data-info">
                  {`${artifactsData?.name ? artifactsData.name : ''}`}
                </p>
              </div>
              <div className="data-row">
                <p className="data-head">SSN</p>
                <p className="data-info">
                  {artifactsData?.ssn ? artifactsData.ssn : '-'}
                </p>
              </div>
              <div className="data-row">
                <p className="data-head">Date of Birth</p>
                <p className="data-info">
                  {artifactsData?.dob ? artifactsData.dob : '-'}
                </p>
              </div>
              <div className="data-row">
                <p className="data-head">Gender</p>
                <p className="data-info text-capitalize">
                  {artifactsData?.gender ? artifactsData.gender : '-'}
                </p>
              </div>
              <div className="divider"></div>
              <div className="data-row printBlock">
                <p className="data-head">Address</p>
                {artifactsData?.address &&
                  !!artifactsData.address.length &&
                  artifactsData?.address.map((add, index) => (
                    <p className="data-info border mb-1 p-8" key={index}>
                      {formatAddress(add)}
                    </p>
                  ))}
              </div>
              <div className="data-row">
                <p className="data-head">Email</p>
                <p className="data-info">
                  {artifactsData?.email ? artifactsData.email : '-'}
                </p>
              </div>
            </div>

            <h6 className="mt-4 mb-3">Vitals</h6>
            <div className="block">
              {artifactsData?.vitals && !!artifactsData?.vitals?.length ? (
                <div className="data-row printBlock">
                  <table className="table border-l border-r border-t">
                    <thead>
                      <tr>
                        <th className="data-head p-8 border-r">Encounter Date</th>
                        <th className="data-head p-8 border-r">Type</th>
                        <th className="data-head p-8">Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      {artifactsData?.vitals.map((vital, index) => (
                        <tr key={index}>
                          <td className="data-info p-8 border-r">
                            {' '}
                            {vital?.encounter_date ? vital.encounter_date : '-'}
                          </td>
                          <td className="data-info p-8 border-r">
                            {' '}
                            {vital?.display_name ? vital.display_name : '-'}
                          </td>
                          <td className="data-info p-8">
                            {' '}
                            {vital?.value ? vital.value : '-'}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : (
                <div className="data-row printBlock">No data found</div>
              )}
            </div>

            <h6 className="mt-4 mb-3">Diagnoses</h6>
            <div className="block">
              {artifactsData?.diagnosis && !!artifactsData.diagnosis.length ? (
                <div className="data-row printBlock">
                  <table className="table border-l border-r border-t">
                    <thead>
                      <tr>
                        <th className="data-head p-8 border-r">Encounter Date</th>
                        <th className="data-head p-8 border-r">ICD-10 Code</th>
                        <th className="data-head p-8 ">Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      {artifactsData?.diagnosis.map((diagnosisData, index) => (
                        <tr key={index}>
                          <td className="data-info p-8 border-r">
                            {' '}
                            {diagnosisData?.encounter_date_start
                              ? diagnosisData.encounter_date_start
                              : '-'}
                          </td>
                          <td className="data-info p-8 border-r">
                            {' '}
                            {diagnosisData?.icd_10_code ? diagnosisData.icd_10_code : '-'}
                          </td>
                          <td className="data-info p-8 border-r">
                            {' '}
                            {diagnosisData?.title ? diagnosisData.title : '-'}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : (
                <div className="data-row printBlock">No data found</div>
              )}
            </div>

            <h6 className="mt-4 mb-3">
              Objective Findings/Test Results (include current x-rays, EKG's, laboratory
              data and any clinical findings)
            </h6>
            <div className="block">
              {artifactsData?.lab_reports && !!artifactsData.lab_reports.length ? (
                <div className="data-row printBlock">
                  <p className="data-info">
                    {artifactsData?.lab_reports.map((labreport) => (
                      <a
                        key={labreport.date}
                        href={`#result_${labreport.date}`}
                        className="data-link"
                      >
                        Date: {labreport.date}
                      </a>
                    ))}
                  </p>
                </div>
              ) : (
                <div className="data-row printBlock">No data found</div>
              )}
            </div>

            <h6 className="mt-4 mb-3">Office / Progress Notes</h6>
            <div className="block printBlock">
              {artifactsData?.progress_notes && !!artifactsData.progress_notes.length ? (
                <div className="data-row">
                  <p className="data-info">
                    {artifactsData?.progress_notes.map((progressnotes, index) => (
                      <a key={index} href={`#note_${index + 1}`} className="data-link">
                        Date: {progressnotes.date} - Note #{index + 1}
                      </a>
                    ))}
                  </p>
                </div>
              ) : (
                <div className="data-row printBlock">No data found</div>
              )}
            </div>

            <h6 className="mt-4 mb-3">Current procedural terminology (CPT Code)</h6>
            <div className="block">
              {artifactsData?.cpt_codes && !!artifactsData.cpt_codes.length ? (
                <div className="data-row printBlock">
                  <table className="table border-l border-r border-t">
                    <thead>
                      <tr>
                        <th className="data-head p-8 border-r">Encounter Date</th>
                        <th className="data-head p-8 border-r">Procedure</th>
                        <th className="data-head p-8">Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      {artifactsData?.cpt_codes.map((cpt, index) => (
                        <tr key={index}>
                          <td className="data-info p-8 border-r">
                            {' '}
                            {cpt?.performed_date ? cpt.performed_date : '-'}
                          </td>
                          <td className="data-info p-8 border-r">
                            {' '}
                            {cpt?.cpt_code ? cpt.cpt_code : '-'}
                          </td>
                          <td className="data-info p-8 border-r">
                            {' '}
                            {cpt?.title ? cpt.title : '-'}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : (
                <div className="data-row printBlock">No data found</div>
              )}
            </div>

            <h6 className="mt-4 mb-3">Prescriptions</h6>
            <div className="block">
              {artifactsData?.medications && !!artifactsData.medications.length ? (
                <div className="data-row printBlock">
                  <table className="table border-l border-r border-t">
                    <thead>
                      <tr>
                        <th className="data-head p-8 border-r">Encounter Date</th>
                        <th className="data-head p-8 border-r">Name/Type</th>
                        <th className="data-head p-8">Instruction</th>
                      </tr>
                    </thead>
                    <tbody>
                      {artifactsData?.medications.map((medication, index) => (
                        <tr key={index}>
                          <td className="data-info p-8 border-r">
                            {' '}
                            {medication?.prescribed_date
                              ? medication.prescribed_date
                              : '-'}
                          </td>
                          <td className="data-info p-8 border-r">
                            {' '}
                            {medication?.medication ? medication.medication : '-'}
                          </td>
                          <td className="data-info p-8 border-r">
                            {' '}
                            {medication?.dosage ? medication.dosage : '-'}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : (
                <div className="data-row printBlock">No data found</div>
              )}
            </div>

            <h6 className="mt-4 mb-3">Physician Information</h6>
            {artifactsData?.physicians && !!artifactsData.physicians.length ? (
              <div className="block flex-column">
                {artifactsData?.physicians.map((physician, index) => (
                  <div className="block" key={index}>
                    <div className="data-row">
                      <p className="data-head">Name</p>
                      <p className="data-info">
                        {physician?.name ? physician?.name : '-'}
                      </p>
                    </div>
                    <div className="data-row">
                      <p className="data-head">Phone</p>
                      <p className="data-info">
                        {physician?.phone ? physician?.phone : '-'}
                      </p>
                    </div>
                    <div className="data-row">
                      <p className="data-head">FAX</p>
                      <p className="data-info">{physician?.fax ? physician?.fax : '-'}</p>
                    </div>
                    <div className="data-row">
                      <p className="data-head">Specialty</p>
                      <p className="data-info">
                        {physician?.specialty
                          ? Object.values(physician?.specialty).toString()
                          : '-'}
                      </p>
                    </div>
                    <div className="data-row">
                      <p className="data-head">Tax ID/EIN</p>
                      <p className="data-info">
                        {physician?.taxid ? physician?.taxid : '-'}
                      </p>
                    </div>
                    <div className="divider"></div>
                    <div className="data-row printBlock">
                      <p className="data-head">Address</p>
                      <p className="data-info">
                        {physician?.address
                          ? formatAddress(
                              !!physician?.address[0]
                                ? physician?.address[0]
                                : physician?.address,
                            )
                          : '-'}
                      </p>
                    </div>
                    <div className="data-row">
                      <p className="data-head">Email</p>
                      <p className="data-info">
                        {physician?.email ? physician?.email : '-'}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="block flex-column">No data found</div>
            )}

            {artifactsData?.lab_reports && !!artifactsData.lab_reports.length && (
              <>
                <div className="pagebreak"></div>
                <h6 className="mt-4 mb-3">Lab Reports</h6>
                <div className="block">
                  {artifactsData?.lab_reports.map((labreport, i, { length }) => (
                    <>
                      <ToggleFetch
                        data={labreport}
                        key={labreport.date}
                        id={`result_${labreport.date}`}
                      />
                      {length - 1 !== i && <hr className="divider" />}
                    </>
                  ))}
                </div>
              </>
            )}

            {artifactsData?.progress_notes && !!artifactsData.progress_notes.length && (
              <>
                <div className="pagebreak"></div>
                <h6 className="mt-4 mb-3">Progress Notes</h6>
                <div className="block">
                  {artifactsData?.progress_notes.map(
                    (progressnotes, index, { length }) => (
                      <>
                        <ToggleFetch
                          data={progressnotes}
                          key={index}
                          index={index}
                          id={`note_${index + 1}`}
                        />
                        {length - 1 !== index && <hr className="divider" />}
                      </>
                    ),
                  )}
                  <div className="divider"></div>
                </div>{' '}
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};
export default ViewReport;
