/* eslint-disable no-loop-func */
import React, { useState, useEffect, useRef } from 'react';
import { TextInput } from 'components/form-input';
import { IoChevronBack, IoAttach, IoCloseSharp, IoAddOutline } from 'react-icons/io5';
import { validateName, validateEmail, validateClaim } from 'utils/validations';
import { useDispatch } from 'react-redux';
import { sendMessage, requestSignedUrls } from 'redux/modules/inbox/actions';
import { useAuth0 } from '@auth0/auth0-react';
import { useHistory, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { searchFormErrorStr } from 'constants/errorStrings';
import RichTextEditor from 'react-rte';
import AuthLoader from 'components/AuthLoader';
import convertSize from 'convert-size';
import { Prompt } from 'react-router';
import { uploadUsingPresignedUrl, onFileChange, getFiles } from 'utils/upload';
import {
  requestSearchClaimsCompose,
  addNewProvider,
} from 'redux/modules/compose/actions';
import ProviderModal from 'components/ProviderModal';
import { useLocation } from 'react-router-dom';
import { getRawToken } from 'utils/auth';
import AsyncSelect from 'react-select/async';
import { searchPatientsCompose, searchProvidersCompose } from 'api/compose';
import Select from 'react-select';

const dropdownStyles = {
  control: (provided) => ({
    ...provided,
    minHeight: 38,
    outline: 'none',
    boxShadow: 'none',
    border: 0,
  }),
  container: (provided) => ({
    ...provided,
    padding: 0,
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? 'white' : 'white',
    color: state.isSelected ? 'inherit' : 'inherit',
  }),
};

export const toolbarConfig = {
  display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'BLOCK_TYPE_DROPDOWN'],
  INLINE_STYLE_BUTTONS: [
    { label: 'Bold', style: 'BOLD' },
    { label: 'Italic', style: 'ITALIC' },
    { label: 'Underline', style: 'UNDERLINE' },
  ],
  BLOCK_TYPE_DROPDOWN: [
    { label: 'Normal', style: 'unstyled' },
    { label: 'Heading Large', style: 'header-one' },
    { label: 'Heading Medium', style: 'header-two' },
    { label: 'Heading Small', style: 'header-three' },
  ],
  BLOCK_TYPE_BUTTONS: [
    { label: 'UL', style: 'unordered-list-item' },
    { label: 'OL', style: 'ordered-list-item' },
  ],
};

const ComposeMessage = () => {
  let initialState = {
    providerName: {
      value: '',
      isValid: true,
      errorStr: searchFormErrorStr.providerName,
    },
    providerEmail: {
      value: '',
      isValid: true,
      errorStr: searchFormErrorStr.providerEmail,
    },
    newProviderName: {
      value: '',
      isValid: true,
      errorStr: searchFormErrorStr.providerName,
    },
    newProviderEmail: {
      value: '',
      isValid: true,
      errorStr: searchFormErrorStr.providerEmail,
    },
    patientName: {
      value: '',
      isValid: true,
      errorStr: searchFormErrorStr.patientName,
    },
    claim: {
      value: '',
      isValid: true,
      errorStr: searchFormErrorStr.claim,
    },
    subject: {
      value: '',
      isValid: true,
      errorStr: searchFormErrorStr.subject,
    },
    message: {
      value: RichTextEditor.createEmptyValue(),
      isValid: true,
      errorStr: searchFormErrorStr.message,
    },
    files: [],
  };
  const location = useLocation();
  const [formState, setFormState] = useState(initialState);
  const [showToasts, setShowToasts] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [skippedFiles, setSkippedFiles] = useState({});
  const [providerOptions, setProviderOptions] = useState([]);
  const [patientsOptions, setPatientsOptions] = useState([]);
  const [patientName, setPatientName] = useState('');
  const [patientId, setPatientId] = useState('');
  const [provider, setProvider] = useState('');
  const [providerResourceId, setProviderResourceId] = useState('');
  const [claim, setClaim] = useState('');
  const [claimOptions, setClaimOptions] = useState([]);
  const [error, setError] = useState('');
  const { getIdTokenClaims } = useAuth0();
  const dispatch = useDispatch();
  const history = useHistory();
  const [inputFile, setInputFile] = useState([React.createRef()]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalError, setModalError] = useState('');
  const providerRef = useRef();

  const closeModal = () => {
    setIsModalOpen(false);
    resetNewProviderFields();
  };

  useEffect(() => {
    const handleWindowClose = (ev) => {
      ev.preventDefault();
      return checkIsDataEntered(formState) && !isClicked
        ? (ev.returnValue = checkIsDataEntered(formState) && !isClicked)
        : null;
    };
    window.addEventListener('beforeunload', handleWindowClose);
    return () => {
      window.removeEventListener('beforeunload', handleWindowClose);
    };
  }, [formState, isClicked, skippedFiles]);

  useEffect(() => {
    if (!!location?.state) {
      let patientOptions = [
        {
          value: location.state?.patientId || '',
          label: location.state?.name || '',
          resource_id: location.state?.patientId || '',
        },
      ];

      setPatientsOptions([...patientOptions]);

      setPatientName(location.state?.name || '');

      let claimOptions = [
        {
          label: location.state?.claimDate,
          value: location.state?.claimId,
        },
      ];

      setClaimOptions([...claimOptions]);
      handleOnChangeClaim({
        label: location.state?.claimDate,
        value: location.state?.claimId,
      });
      handleChange(location.state?.name || '', 'patientName');
      setPatientId(location.state?.patientId || '');
    }
  }, [location]);

  useEffect(() => {
    showToasts && promptErrors(formState);
  }, [formState, showToasts]);

  useEffect(() => {
    if (inputFile.length > 1) {
      inputFile[inputFile.length - 2].current.click();
    }
  }, [inputFile]);

  const handleNameBlur = (id) => {
    setFormState((formState) => ({
      ...formState,
      [id]: {
        ...formState[id],
        isValid: validateName(formState[id].value),
      },
    }));
  };

  const handleClaimBlur = (id) => {
    setFormState((formState) => ({
      ...formState,
      [id]: {
        ...formState[id],
        isValid: validateClaim(formState[id].value),
      },
    }));
  };

  //email handles
  const handleEmailBlur = (id = 'providerEmail') => {
    setFormState((formState) => ({
      ...formState,
      [id]: {
        ...formState[id],
        isValid: !!formState[id].value && validateEmail(formState[id].value),
      },
    }));
  };

  //generic handles
  const handleFocus = (name) => {
    setFormState((formState) => ({
      ...formState,
      [name]: { ...formState[name], isValid: true },
    }));
  };

  const handleChange = (value, name) => {
    setFormState((formState) => ({
      ...formState,
      [name]: { ...formState[name], value: value },
    }));
  };

  const getFormattedData = (attachmentuuids) => {
    let htmlMessage = formState.message.value.toString('html');
    const formData = new FormData();

    formData.append('patient_claim', formState.claim.value);
    formData.append('provider_id', providerResourceId);
    formData.append('subject', formState.subject.value);
    formData.append('message_text', htmlMessage);

    if (!!attachmentuuids) {
      let all_uuid = attachmentuuids.map((dt) => dt.attachment_uuid);
      for (let i = 0; i < all_uuid.length; i++) {
        formData.append(`attachment_uuids[${i}]`, all_uuid[i]);
      }
    }

    return formData;
  };

  const checkIsFormValid = () => {
    let trimmedMessage = formState.message.value
      .toString('html')
      .replace(/(<([^>]+)>)/gi, '')
      .trim()
      .replaceAll(/\s/g, '');

    !validateName(formState.subject.value) && handleNameBlur('subject');
    !validateClaim(formState.claim.value) && handleClaimBlur('claim');

    return (
      !!providerResourceId &&
      validateName(formState.subject.value) &&
      validateClaim(formState.claim.value) &&
      !!trimmedMessage
    );
  };

  const promptErrors = (state) => {
    for (let key in state) {
      if (!state[key].isValid) {
        toast.error(state[key].errorStr);
        break;
      }
    }
    setShowToasts(false);
    setIsClicked(false);

    if (!providerResourceId) {
      toast.error('Select provider');
    }
  };

  const submitForm = async () => {
    let trimmedMessage = formState.message.value
      .toString('html')
      .replace(/(<([^>]+)>)/gi, '')
      .trim()
      .replaceAll(/\s/g, '');

    if (!!trimmedMessage) {
      if (!isClicked) {
        // handle multi clicks
        setIsClicked(true);
        let isFormValid = checkIsFormValid();
        if (isFormValid) {
          // getSignedUrls
          if (!!formState.files.length && !!getFileNames().filenames?.length) {
            const jwtToken = await getIdTokenClaims();
            dispatch(
              requestSignedUrls({
                data: getFileNames(),
                token: getRawToken(jwtToken),
                onSuccess: onSuccessSignedURLs,
                onError: onErrorSignedURLs,
              }),
            );
          } else {
            createThread();
          }
        } else {
          setShowToasts(true);
        }
      }
    } else {
      toast.error(searchFormErrorStr.message);
    }
  };

  const createThread = async (data = null) => {
    const jwtToken = await getIdTokenClaims();
    dispatch(
      sendMessage({
        formData: getFormattedData(data),
        token: getRawToken(jwtToken),
        navigateToDetail: navigateToDetail,
        onError,
      }),
    );
  };

  const onSuccessSignedURLs = async (data) => {
    let allFiles = getFiles(formState, skippedFiles);
    let uuids = [];
    let uploadingAllFiles = Promise.all(
      data.map(async (dt) => {
        let file = allFiles.find((fl, i) => {
          let isNameFound = fl.name === dt.filename && !uuids.includes(i);
          uuids.push(i);
          return isNameFound;
        });
        let response = await uploadUsingPresignedUrl(dt.url, dt.fields, file);
        if (!response.ok) {
          setError('Error uploading files, please try again later.');
          throw Error('Error uploading files.');
        }
      }),
    );

    uploadingAllFiles
      .then(() => {
        createThread(data);
      })
      .catch((err) => {
        setIsClicked(false);
        setError('Error uploading files, please try again later.');
      });
  };

  const onErrorSignedURLs = () => {
    setIsClicked(false);
    setError('Error uploading files, please try again later.');
  };

  const getFileNames = () => {
    let fileNames = [];
    if (!!formState.files.length) {
      formState.files.forEach((file, index) => {
        for (const key of Object.keys(file)) {
          if (!skippedFiles[index]?.includes(file[key].name)) {
            fileNames.push(file[key].name);
          }
        }
      });

      return {
        filenames: fileNames,
      };
    } else {
      return false;
    }
  };

  const navigateToDetail = (id) => {
    if (!!id) {
      history.push(`/thread-details/${id}`);
    } else {
      history.push('/inbox');
    }
  };

  const onError = () => {
    setIsClicked(false);
    setError('Error sending message, please try again later.');
  };

  const isMessageEmpty = (msg) => {
    return !msg
      .toString('html')
      .replace(/(<([^>]+)>)/gi, '')
      .trim()
      .replaceAll(/\s/g, '');
  };

  const checkIsDataEntered = (formState) => {
    return (
      !!formState.providerName.value ||
      !!formState.providerEmail.value ||
      !!formState.patientName.value ||
      !!formState.subject.value ||
      !!formState.claim.value ||
      !isMessageEmpty(formState.message.value) ||
      (!!getFiles(formState, skippedFiles) && !!getFiles(formState, skippedFiles).length)
    );
  };

  const loadPatientsOptions = async (keyword) => {
    const jwtToken = await getIdTokenClaims();

    let { response } = await searchPatientsCompose({
      keyword,
      token: jwtToken.__raw,
    });

    let options = response.map((item) => ({
      value: item.resource_id,
      label: item.name,
      ...item,
    }));

    setPatientsOptions([...response]);

    return options;
  };

  const loadProvidersOptions = async (key) => {
    const jwtToken = await getIdTokenClaims();

    let { response } = await searchProvidersCompose({
      key,
      token: jwtToken.__raw,
    });

    let options = response.results.map((item) => ({
      value: item.guid,
      label: item.name,
      ...item,
    }));

    setProviderOptions([...response.results]);

    return options;
  };

  const handleOnSearch = async (keyword) => {
    setPatientName(keyword);
  };

  const handleOnSelectPatientName = async (item) => {
    handleChange(item?.name || '', 'patientName');
    setPatientId(item?.resource_id || '');
    const jwtToken = await getIdTokenClaims();

    handleChange('', 'claim');
    setClaim(null);

    !!item &&
      dispatch(
        requestSearchClaimsCompose({
          resource_id: item.resource_id,
          token: jwtToken.__raw,
          setClaims: setClaimOptions,
          onError: onError,
        }),
      );
  };

  const handleOnChangeClaim = async (item) => {
    handleChange(item.value, 'claim');
    handleClaimBlur('claim');
    setClaim(item);
  };

  const handleOnSelectProviderName = async (item) => {
    handleChange(item?.name || '', 'providerName');
    handleChange(!!item?.email ? item?.email : '', 'providerEmail');
    setProviderResourceId(item?.guid || '');
  };

  const resetNewProviderFields = () => {
    setFormState((formState) => ({
      ...formState,
      newProviderName: {
        value: '',
        isValid: true,
        errorStr: searchFormErrorStr.providerName,
      },
      newProviderEmail: {
        value: '',
        isValid: true,
        errorStr: searchFormErrorStr.providerEmail,
      },
    }));
  };

  const submitNewProviderEntry = async () => {
    if (formState.newProviderName.isValid && formState.newProviderEmail.isValid) {
      const jwtToken = await getIdTokenClaims();
      dispatch(
        addNewProvider({
          token: jwtToken.__raw,
          data: {
            email: formState.newProviderEmail.value,
            name: formState.newProviderName.value,
          },
          onSuccess: (data) => {
            setModalError('');
            setProviderOptions((st) => [...st, data]);
            setProvider(data.name);
            providerRef.current.focus();
            handleOnSelectProviderName(data);
            providerRef.current.blur();
            resetNewProviderFields();
            closeModal();
          },
          onError: (data) => {
            setModalError(
              data?.data?.non_field_errors[0] ?? 'Failed to add new provider.',
            );
          },
        }),
      );
    }
  };

  return (
    <div className="px-5 py-4 scrollable-page">
      {checkIsDataEntered(formState) && !isClicked && (
        <Prompt
          when={true}
          message="You will lose the data, are you sure you want to leave?"
        />
      )}
      <h5 className="color-primary-dark mb-4 mt-0 d-flex flex-items-center">
        <IoChevronBack
          onClick={() => {
            history.push('/inbox');
          }}
          className="btn-round-back mr-3"
        />
        Compose
      </h5>
      <div className="pt-5 px-5 pb-1 bg-white card overflow-auto position-relative">
        <div className="d-flex flex-wrap">
          <div className="mr-4 mb-3">
            <p className="mt-0 mb-2 color-text-primary">Select Patient*</p>
            <div className="input-lg" style={{ position: 'relative', zIndex: 3 }}>
              <AsyncSelect
                cacheOptions={false}
                inputValue={patientName}
                autoFocus={!location?.state}
                isClearable
                noOptionsMessage={() =>
                  !!patientName ? 'No patients found.' : 'Type something to search...'
                }
                defaultOptions={patientsOptions.map((item) => ({
                  value: item.resource_id,
                  label: item.name,
                  ...item,
                }))}
                isMulti={false}
                isSearchable
                backspaceRemovesValue
                loadOptions={loadPatientsOptions}
                onInputChange={handleOnSearch}
                onChange={handleOnSelectPatientName}
                formatOptionLabel={(item) => (
                  <div
                    key={item.resource_id}
                    className="autosearch-result-wrapper cursor-pointer text-ellipsis"
                  >
                    <span className="autosearch-result-row">
                      {item.label}{' '}
                      <span className="autosearch-result-row-small text-italic">{`${
                        !!item.dob ? '( DOB: ' + item.dob + ' )' : ''
                      }`}</span>
                    </span>
                  </div>
                )}
                className="form-input"
                styles={dropdownStyles}
              />
            </div>
          </div>
          <div className="mb-3">
            <p className="mt-0 mb-2 color-text-primary">Select Claim*</p>
            <div className="input-lg" style={{ position: 'relative', zIndex: 3 }}>
              <Select
                className="form-input"
                styles={dropdownStyles}
                isMulti={false}
                isSearchable={false}
                options={claimOptions}
                onChange={handleOnChangeClaim}
                onFocus={() => handleFocus('claim')}
                value={claim ? claimOptions.find((i) => i.value === claim?.value) : null}
              />
            </div>
          </div>
          {!!claim && (
            <div className="mt-5 ml-3">
              <Link
                className="fw-medium xx-small color-secondary "
                to={`/view-report/${claim?.value}/${patientId}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                View Claim Details
              </Link>
            </div>
          )}
        </div>

        <div className="d-flex flex-wrap">
          <div className="mr-2 mb-3" style={{ position: 'relative', zIndex: 2 }}>
            <p className="mt-0 mb-2 color-text-primary">Select Provider*</p>
            <div className="input-lg">
              <AsyncSelect
                ref={providerRef}
                cacheOptions={false}
                inputValue={provider}
                autoFocus={!!location?.state}
                isClearable
                noOptionsMessage={() =>
                  !!provider ? 'No providers found.' : 'Type something to search...'
                }
                value={
                  providerResourceId
                    ? providerOptions
                        ?.filter((item) => item.guid === providerResourceId)
                        ?.map((item) => ({
                          value: item.guid,
                          label: item.name,
                          ...item,
                        }))
                    : null
                }
                defaultOptions={providerOptions.map((item) => ({
                  value: item.guid,
                  label: item.name,
                  ...item,
                }))}
                isMulti={false}
                isSearchable
                backspaceRemovesValue
                loadOptions={loadProvidersOptions}
                onInputChange={(key) => setProvider(key)}
                onChange={handleOnSelectProviderName}
                formatOptionLabel={(item) => (
                  <div className="autosearch-result-wrapper cursor-pointer text-ellipsis">
                    <span className="autosearch-result-row">
                      {item.name}{' '}
                      <span className="autosearch-result-row-small">{`${
                        !!item.email ? '(' + item.email + ')' : ''
                      }`}</span>
                    </span>
                  </div>
                )}
                className="form-input"
                styles={dropdownStyles}
              />
            </div>
          </div>
          <p
            className="link ml-0 pt-2 d-inline-flex fw-medium my-0 flex-items-center"
            onClick={() => {
              setIsModalOpen(true);
            }}
          >
            <IoAddOutline className="fw-bold" /> Add Provider
          </p>
        </div>

        <div className="mb-3">
          <div className="mb-3">
            <p className="mt-0 mb-2 color-text-primary">Subject*</p>
            <TextInput
              value={formState.subject.value}
              isError={!formState.subject.isValid}
              onChange={(e) => {
                handleChange(e.target.value, 'subject');
              }}
              onFocus={() => handleFocus('subject')}
              onBlur={() => handleNameBlur('subject')}
            />
          </div>
        </div>
        <div className="mb-3">
          <div className="mb-3">
            <p className="mt-0 mb-2 color-text-primary">Message*</p>
            <RichTextEditor
              toolbarConfig={toolbarConfig}
              className="rte-editor"
              value={formState.message.value}
              onChange={(value) => {
                setFormState((formState) => ({
                  ...formState,
                  message: { ...formState.message, value: value },
                }));
              }}
            />
          </div>
        </div>
        <div className="mb-3">
          <button
            htmlFor="attachments-upload"
            className="btn-secondary py-3 px-6 mb-2 bg-transparent d-inline-flex flex-items-center"
            onClick={() => {
              setInputFile((inputFile) => [...inputFile, React.createRef()]);
            }}
          >
            <IoAttach size={22} className="mr-2" />
            Attach File
          </button>

          <span
            className="d-inline-flex small color-text-secondary ml-2 mb-2 position-relative"
            style={{ top: '-5px' }}
          >
            Allowed Files : doc, docx, pdf, xls, xlsx, ppt
          </span>

          {inputFile.map((file, i) => (
            <input
              key={i}
              onChange={(e) =>
                onFileChange(e, formState, setFormState, skippedFiles, setError)
              }
              id={`attachments-upload-${i}`}
              type="file"
              style={{ display: 'none' }}
              multiple
              ref={file}
              accept=".DOC,.DOCX,.PDF,.XLS,.XLSX,.PPT,.doc,.docx,.pdf,.xls,.xlsx,.ppt"
            />
          ))}
          <div className="mt-2">
            {!!formState.files &&
              formState.files.map((file, index) =>
                Object.keys(file).map((key, i) => {
                  if (skippedFiles[index]?.includes(file[key].name)) {
                    return null;
                  }
                  return (
                    <p
                      className="fw-semibold color-secondary my-1 d-flex align-items-center"
                      key={i + index}
                    >
                      <IoAttach size={22} className="mr-2" />
                      {file[key].name}{' '}
                      <span className="small color-text-secondary ml-2">
                        (Size: {convertSize(file[key].size, { accuracy: 1 })})
                      </span>
                      <IoCloseSharp
                        className="modal-close-btn color-primary"
                        title="Close"
                        size={22}
                        onClick={() =>
                          setSkippedFiles((skippedFiles) => ({
                            ...skippedFiles,
                            [index]: !!skippedFiles[index]
                              ? [...skippedFiles[index], file[key].name]
                              : [file[key].name],
                          }))
                        }
                        style={{ cursor: 'pointer' }}
                      />
                    </p>
                  );
                }),
              )}
          </div>
        </div>
        {isClicked && <AuthLoader absolute={true} faded={true} />}
      </div>
      {!!error && <p className="color-error text-right">{error}</p>}
      <div className="d-flex flex-justify-end mt-3">
        <button className="btn-primary py-3 px-6" onClick={submitForm}>
          {isClicked ? 'Sending' : 'Send'}
        </button>
      </div>
      <ProviderModal
        isOpen={isModalOpen}
        handleClose={closeModal}
        handleConfirm={submitNewProviderEntry}
        formState={formState}
        handleNameBlur={handleNameBlur}
        handleEmailBlur={handleEmailBlur}
        handleChange={handleChange}
        handleFocus={handleFocus}
        modalError={modalError}
      />
    </div>
  );
};
export default ComposeMessage;
