import styled from '@emotion/styled';
import { TextInput } from 'components/form-input';
import LoadingBlock from 'components/LoadingBlock';
import ClaimantCard from 'containers/ClaimantCard';
import React, { useEffect, useState } from 'react';
import { IoAlertCircleOutline, IoSearchOutline } from 'react-icons/io5';
import { requestClaimantHistory } from 'redux/modules/claimantHistory/actions';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch, useSelector } from 'react-redux';
import ReactPaginate from 'react-paginate';
import { getRawToken } from 'utils/auth';

let IoAlertCircleOutlineStyled = styled(IoAlertCircleOutline)`
  font-size: 140px;
  color: rgba(0, 0, 0, 0.2);
`;

let IoSearchOutlineStyled = styled(IoSearchOutline)`
  font-size: 24px;
`;

const ClaimantHistory = () => {
  const dispatch = useDispatch();
  let modalState = useSelector((state) => state.errorModal);
  const { getIdTokenClaims } = useAuth0();
  const [resultStatus, setResultStatus] = useState('in-progress');
  const [claimants, setClaimants] = useState([]);
  const [searchKey, setSearchKey] = useState('');
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [count, setCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [error, setError] = useState('');
  let numberOfPages = Math.ceil(count / 10);

  const handlePageClick = (data) => {
    setCurrentPage(Number(data.selected));
  };

  const setClaimantHistoryData = (data, count) => {
    setError('');
    setClaimants(data);
    setIsInitialLoad(false);
    setCount(Number(count));
  };

  const getClaimantHistoryData = async () => {
    setResultStatus('in-progress');
    const token = await getIdTokenClaims();
    dispatch(
      requestClaimantHistory({
        token: getRawToken(token),
        key: searchKey,
        page: currentPage + 1,
        setClaimantHistoryData,
        onError: onError,
      }),
    );
  };

  const onError = () => {
    setError('Error in fetching data, please try again later.');
  };

  useEffect(() => {
    if (!modalState.isVisible) {
      getClaimantHistoryData();
    }
  }, [currentPage, modalState]);

  useEffect(() => {
    if (!isInitialLoad) {
      if (!!claimants.length) {
        setResultStatus('found');
      } else {
        setResultStatus('not-found');
      }
    }
  }, [claimants, isInitialLoad]);

  const handlePatientNameChange = (event) => {
    setSearchKey(event.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      searchClaimantByName();
    }
  };

  const searchClaimantByName = () => {
    getClaimantHistoryData();
  };

  return (
    <div className="px-5 py-4 scrollable-page d-flex flex-column col-12">
      <div className="d-flex flex-justify-between mb-3 flex-content-center">
        <h5 className="color-primary-dark mb-4 mt-0">Claimant History</h5>
        <div className="d-flex flex-row flex-justify-start">
          <TextInput
            className="search-icon"
            placeholder="Search by patient name"
            value={searchKey}
            onChange={handlePatientNameChange}
            onKeyUp={handleKeyDown}
            size="lg"
          />
          <button
            className="btn-search"
            type="submit"
            style={{ height: 40, width: 45 }}
            onClick={searchClaimantByName}
          >
            <IoSearchOutlineStyled />
          </button>
        </div>
      </div>
      {!!error && (
        <p className="color-error pl-0">
          {error} or{' '}
          <span
            className="cursor-pointer color-secondary"
            onClick={() => window.location.reload()}
          >
            Reload
          </span>
        </p>
      )}
      {resultStatus === 'in-progress' && !error && (
        <div className="col-12">
          <LoadingBlock />
        </div>
      )}
      {resultStatus === 'found' && (
        <div className="d-flex flex-column flex-1 flex-justify-between">
          <div className="col-12">
            {claimants.map((claimant, index) => (
              <React.Fragment key={index}>
                <ClaimantCard claimant={claimant} />
                <div className="col-12 d-flex flex-justify-center mt-3"></div>
              </React.Fragment>
            ))}
          </div>
          <div className="col-12 d-flex flex-justify-center mt-3">
            {numberOfPages > 1 && (
              <ReactPaginate
                previousLabel={'Prev'}
                nextLabel={'Next'}
                initialPage={currentPage}
                previousClassName="pagination_prev-btn"
                nextClassName="pagination_next-btn"
                breakLabel={'...'}
                breakClassName={'break-me'}
                pageCount={numberOfPages}
                marginPagesDisplayed={3}
                pageRangeDisplayed={7}
                onPageChange={handlePageClick}
                containerClassName={'pagination d-inline-flex m-0 p-0'}
                activeClassName={'active'}
              />
            )}
          </div>
        </div>
      )}
      {resultStatus === 'not-found' && (
        <div className="d-flex flex-column col-12 flex-justify-center flex-items-center mb-6 pb-6">
          <IoAlertCircleOutlineStyled />
          <h1 className="color-primary-light mb-5">No Results Found.</h1>
        </div>
      )}
    </div>
  );
};
export default ClaimantHistory;
