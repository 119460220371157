import axios from 'axios';

export const uploadPatientDetails = async ({ formData, token }) => {
  return axios({
    method: 'post',
    url: `patient/upload-patient-details/`,
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
    },
    data: formData,
  })
    .then((response) => ({ response: response.data }))
    .catch((error) => ({ error: error }));
};
