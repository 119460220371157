export const searchFormErrorStr = {
  firstName: 'First name is not valid!',
  lastName: 'Last name is not valid!',
  dob: 'Date of birth not valid!',
  gender: 'Select any Gender!',
  email: 'Email is not valid!',
  mobilePhone: 'Mobile phone is not valid!',
  homePhone: 'Home phone is not valid!',
  workPhone: 'Work phone is not valid!',
  ext: 'Phone Extention is not valid!',
  address1: 'Address line 1 is not valid!',
  address2: 'Address line 2 is not valid!',
  city: 'City name is not valid!',
  state: 'State name/code is not valid!',
  zip: 'Zip code is not valid!',
  providerName: 'Provider Name is not valid!',
  providerEmail: 'Provider Email is not valid!',
  patientName: 'Patient Name is not valid!',
  subject: 'Subject is not valid!',
  message: "Message can't be empty!",
  claim: 'Select a claim!',
  invalidFileFormat: 'Invalid file format or extension found!',
  invalidFileSize: 'Attachments must be smaller than 25MB!',
  claimDate: 'Claim date not valid!',
  searchStartDate: 'Claim start date not valid!',
  searchEndDate: 'Claim end date not valid!',
};
