import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import LoadingBlock from 'components/LoadingBlock';
import { useSelector } from 'react-redux';
import { IoAlertCircleOutline, IoChevronBack } from 'react-icons/io5';
import styled from '@emotion/styled';
import ResultCard from 'containers/ResultCard';

let IoAlertCircleOutlineStyled = styled(IoAlertCircleOutline)`
  font-size: 140px;
  color: rgba(0, 0, 0, 0.2);
`;

const ResultPage = (props) => {
  const history = useHistory();
  const searchPatient = useSelector(({ searchPatient }) => searchPatient);
  const [resultStatus, setResultStatus] = useState('in-progress');
  let isFromClaimantHistoryPage =
    props.location?.state?.isFromClaimantHistoryPage || false;

  useEffect(() => {
    if (
      searchPatient?.documentData?.document_found === false ||
      searchPatient?.documentData?.document_found === true
    ) {
      setResultStatus(
        searchPatient?.documentData?.document_found ? 'found' : 'not-found',
      );
    }
  }, [searchPatient?.documentData?.document_found]);

  return (
    <div className="px-5 py-4 scrollable-page d-flex col-12">
      {resultStatus === 'in-progress' && (
        <div className="col-12">
          <h5 className="color-primary-dark mb-4 mt-0">Searching...</h5>
          <LoadingBlock />
        </div>
      )}
      {resultStatus === 'found' && (
        <ResultCard
          searchPatient={searchPatient}
          isFromClaimantHistoryPage={isFromClaimantHistoryPage}
        />
      )}
      {resultStatus === 'not-found' && (
        <div className="d-flex flex-column col-12 flex-justify-center flex-items-center mb-6 pb-6">
          <IoAlertCircleOutlineStyled />
          <h1 className="color-primary-light mb-5">No Results Found.</h1>
          <button
            className="btn-secondary py-3 px-6 d-flex flex-items-center"
            onClick={() => {
              history.push({ pathname: '/search' });
            }}
          >
            <IoChevronBack className="mr-1" />
            Go Back To Search
          </button>
        </div>
      )}
    </div>
  );
};
export default ResultPage;
