export const REQUEST_DASHBOARD = 'dashboard/requestDashboard';
export const SET_LOADING = 'dashboard/isLoading';
export const SET_ERROR = 'dashboard/isError';

export const requestDashboard = (payload = {}) => ({
  type: REQUEST_DASHBOARD,
  payload,
});

export const isLoading = (payload = {}) => ({
  type: SET_LOADING,
  payload,
});

export const isError = (payload = {}) => ({
  type: SET_ERROR,
  payload,
});
