import React from 'react';
import PropTypes from 'prop-types';
import { IoOptionsSharp } from 'react-icons/io5';

const TextInput = ({
  className,
  value,
  isError,
  isFilterOpen,
  placeholder,
  size,
  onChange,
  onKeyUp,
  onFocus,
  onBlur,
  withAdvSearch,
  onAdvSearchClick,
}) => {
  const handleChange = (e) => {
    !!onChange && onChange(e);
  };

  const handleKeyUp = (e) => {
    !!onKeyUp && onKeyUp(e);
  };

  const handleFocus = (e) => {
    !!onFocus && onFocus(e);
  };

  const handleBlur = (e) => {
    !!onBlur && onBlur(e);
  };

  return (
    <div
      className={`${withAdvSearch ? 'position-relative' : ''}`}
      style={isFilterOpen ? { zIndex: 115 } : {}}
    >
      <input
        type="text"
        className={`form-input ${
          isError ? 'border-error' : ''
        } input-${size} ${className}`}
        value={value}
        placeholder={placeholder}
        onChange={(e) => handleChange(e)}
        onKeyUp={(e) => handleKeyUp(e)}
        onFocus={(e) => handleFocus(e)}
        onBlur={(e) => handleBlur(e)}
        style={withAdvSearch ? { paddingRight: '52px' } : {}}
      />
      {withAdvSearch && (
        <IoOptionsSharp className="adv-search-option" onClick={onAdvSearchClick} />
      )}
    </div>
  );
};

TextInput.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string,
  isError: PropTypes.bool,
  placeholder: PropTypes.string,
  size: PropTypes.string,
  onChange: PropTypes.func,
  onKeyUp: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  withAdvSearch: PropTypes.bool,
  onAdvSearchClick: PropTypes.func,
};

TextInput.defaultProps = {
  className: '',
  value: '',
  isError: false,
  placeholder: '',
  size: 'auto', // values for size : sm, md, lg, auto
  withAdvSearch: false,
};

export default TextInput;
