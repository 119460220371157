import React from 'react';
import moment from 'moment';

const DashboardTable = ({ data }) => {
  const format_date = (date) => moment(moment(date, 'YYYY-MM-DD')).format('MM/DD/YYYY');
  const format_datetime = (datetime) => moment(datetime).format('MM/DD/YYYY hh:mm A ');

  return !!data.length ? (
    <table className="table">
      <thead>
        <tr>
          <th className="color-text-secondary fw-medium h6">Patient Name</th>
          <th className="color-text-secondary fw-medium h6">Date Of Birth</th>
          <th className="color-text-secondary fw-medium h6">Progress</th>
          <th className="color-text-secondary fw-medium h6">Initiated By</th>
          <th className="color-text-secondary fw-medium h6">Initiated At</th>
        </tr>
      </thead>
      <tbody>
        {data.map((row, index) => (
          <tr key={index}>
            <td>
              <p>{row.name}</p>
            </td>
            <td>
              <p>{format_date(row.dob)}</p>
            </td>
            <td>
              <p>{row.progress}%</p>
            </td>
            <td>
              <p>{row.initiated_by}</p>
            </td>
            <td>
              <p>{format_datetime(row.initiated_at)}</p>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  ) : (
    <div className="">
      <h4 className="color-text-secondary fw-medium text-center">
        No reports in progress.
      </h4>
    </div>
  );
};
export default DashboardTable;
