import axios from 'axios';

export const getPhysicianStatements = async ({ token, filters = {}, page }) => {
  let nonEmptyKeys = Object.keys(filters).filter((item) => !!filters[item]);
  let nonEmptyFilters = nonEmptyKeys.reduce(
    (obj, item) => Object.assign(obj, { [item]: filters[item] }),
    {},
  );

  return axios({
    method: 'get',
    url: 'physicians-statements/',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    params: {
      ...nonEmptyFilters,
      ...(page !== 1 && { page }),
    },
  })
    .then((response) => ({ response: response.data }))
    .catch((error) => ({ error: error.response }));
};

export const getClaimantHistory = async ({ key = '', token, page }) => {
  return axios({
    method: 'get',
    url: `claimant-history/`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    params: {
      ...(page !== 1 && { page }),
      ...(key !== '' && { search: key }),
    },
  })
    .then((response) => ({ response: response.data }))
    .catch((error) => ({ error: error.response }));
};
