import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { formatAddress } from 'utils/addressFormat';
import moment from 'moment';
import { setClaimantHistoryInResults } from 'redux/modules/claimantHistory/actions';
import { useDispatch } from 'react-redux';
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';

const HistoryCard = ({
  claimant: { name, dob, gender, patient_id, address, searched_at, searched_by },
}) => {
  const dispatch = useDispatch();
  let date_of_birth = moment(moment(dob, 'YYYY-MM-DD')).format('MM/DD/YYYY');
  let age = moment(moment(dob, 'YYYY-MM-DD')).fromNow(true);
  let searched_at_formatted = moment(moment(searched_at)).format('MM/DD/YYYY h:mm A');
  const [error, setError] = useState('');
  const history = useHistory();
  const [expand, setExpand] = useState(false);

  const handleProceedToGenerate = () => {
    let [first_name, last_name] = name.split(' ');
    dispatch(
      setClaimantHistoryInResults({
        data: {
          data: {
            first_name,
            last_name,
            dob,
            gender,
            address,
            resource_id: patient_id,
          },
          document_found: true,
        },
        callback: () => {
          setError('');
          history.push({
            pathname: '/result',
            state: { isFromClaimantHistoryPage: true },
          });
        },
        onError: onError,
      }),
    );
  };

  const onError = () => {
    setError('Error in generating data, please try again later.');
  };

  return (
    <div className="col-12">
      <div className="bg-white card overflow-auto d-md-flex">
        <div className="p-5 info-table flex-1">
          <div className="d-flex mb-3">
            <p className="m-0 h6 fw-medium color-text-secondary info-head pr-2 d-flex flex-justify-between">
              <span>Patient Name</span> <span>:</span>
            </p>
            <p className="flex-1 m-0 h6 fw-medium color-text-primary">{name}</p>
          </div>
          <div className="d-flex mb-3">
            <p className="m-0 h6 fw-medium color-text-secondary info-head pr-2 d-flex flex-justify-between">
              <span>Date Of Birth</span>
              <span>:</span>
            </p>
            <p className="flex-1 m-0 h6 fw-medium color-text-primary">
              {date_of_birth} ({age} old)
            </p>
          </div>
          <div className="d-flex mb-3">
            <p className="m-0 h6 fw-medium color-text-secondary info-head pr-2 d-flex flex-justify-between">
              <span>Gender</span>
              <span>:</span>
            </p>
            <p className="flex-1 m-0 h6 fw-medium text-capitalize color-text-primary">
              {gender}
            </p>
          </div>
          <div className="d-flex mb-3">
            <p className="m-0 h6 fw-medium color-text-secondary info-head pr-2 d-flex flex-justify-between">
              <span>Address</span>
              <span>:</span>
            </p>
            <div>
              <p className="m-0 mb-2 h6 fw-medium color-text-primary">
                {formatAddress(address[0])}{' '}
                {address.length > 1 &&
                  (!expand ? (
                    <BsChevronDown
                      className="align-middle"
                      onClick={() => {
                        setExpand(true);
                      }}
                    />
                  ) : (
                    <BsChevronUp
                      className="align-middle"
                      onClick={() => {
                        setExpand(false);
                      }}
                    />
                  ))}
              </p>

              {address.length > 1 &&
                !!expand &&
                address
                  .slice(1)
                  .map((addr) => (
                    <p className="m-0 mb-2 h6 fw-medium color-text-primary">
                      {formatAddress(addr)}
                    </p>
                  ))}
            </div>
          </div>
        </div>
        <div className="divider flex-self-stretch"></div>
        <div className="col-12 col-md-4 p-5 d-flex flex-column flex-justify-between">
          <div className="mb-4 d-flex flex-column">
            <div className="d-flex mb-3">
              <p className="m-0 h6 fw-medium color-text-secondary info-head pr-2 d-flex flex-justify-between">
                <span>Searched at</span>
                <span>:</span>
              </p>
              <p className="flex-1 m-0 h6 fw-medium color-text-secondary ">
                {searched_at_formatted}
              </p>
            </div>
            <div className="d-flex mb-3">
              <p className="m-0 h6 fw-medium color-text-secondary info-head pr-2 d-flex flex-justify-between">
                <span>Searched by</span>
                <span>:</span>
              </p>
              <p className="flex-1 m-0 h6 fw-medium color-text-secondary ">
                {searched_by}
              </p>
            </div>
          </div>
          {/* <ProgressButton /> */}
          <button
            className={`btn-primary py-3 px-6 col-12`}
            onClick={handleProceedToGenerate}
          >
            Proceed
          </button>
          {!!error && (
            <p className="color-error pl-0">
              {error} or{' '}
              <span
                className="cursor-pointer color-secondary"
                onClick={() => window.location.reload()}
              >
                Reload
              </span>
            </p>
          )}
        </div>
      </div>
    </div>
  );
};
export default HistoryCard;
