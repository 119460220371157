import React, { useState, useEffect, useCallback } from 'react';
import RichTextEditor from 'react-rte';
import { toolbarConfig } from 'pages/ComposeMessage';
import { searchFormErrorStr } from 'constants/errorStrings';
import { useParams } from 'react-router-dom';
import { IoAttach, IoCloseSharp, IoFilter } from 'react-icons/io5';
import { toast } from 'react-toastify';
import convertSize from 'convert-size';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch } from 'react-redux';
import { sendReply, requestSignedUrls } from 'redux/modules/inbox/actions';
import { useHistory } from 'react-router-dom';
import AuthLoader from './AuthLoader';
import { uploadUsingPresignedUrl, onFileChange, getFiles } from 'utils/upload';
import { getRawToken } from 'utils/auth';

const ReplyBox = (props) => {
  let initialState = {
    message: {
      value: RichTextEditor.createEmptyValue(),
      isValid: true,
      errorStr: searchFormErrorStr.message,
    },
    files: [],
  };

  let { id } = useParams();
  let threadId = id;
  const [formState, setFormState] = useState(initialState);
  const [inputFile, setInputFile] = useState([React.createRef()]);
  const [skippedFiles, setSkippedFiles] = useState({});
  const [showToasts, setShowToasts] = useState(false);
  const history = useHistory();
  const [error, setError] = useState('');
  const [isClicked, setIsClicked] = useState(false);
  const { getIdTokenClaims } = useAuth0();
  const dispatch = useDispatch();
  const [size, setSize] = useState({ x: 500, y: 450 });

  const resizeHandler = useCallback(() => {
    const onMouseMove = (e) => {
      setSize((currentSize) => ({
        x: currentSize.x + e.movementX * -1,
        y: currentSize.y + e.movementY * -1,
      }));
    };
    const onMouseUp = () => {
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseup', onMouseUp);
    };
    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseup', onMouseUp);
  }, []);

  useEffect(() => {
    const handleWindowClose = (ev) => {
      ev.preventDefault();
      return checkIsDataEntered(formState) && !isClicked
        ? (ev.returnValue = checkIsDataEntered(formState) && !isClicked)
        : null;
    };
    window.addEventListener('beforeunload', handleWindowClose);
    return () => {
      window.removeEventListener('beforeunload', handleWindowClose);
    };
  }, [formState, isClicked, skippedFiles]);

  useEffect(() => {
    showToasts && promptErrors(formState);
  }, [formState, showToasts]);

  useEffect(() => {
    if (inputFile.length > 1) {
      inputFile[inputFile.length - 2].current.click();
    }
  }, [inputFile]);

  const getFormattedData = (attachmentuuids) => {
    let htmlMessage = formState.message.value.toString('html');
    const formData = new FormData();

    formData.append('body', htmlMessage);
    if (!!attachmentuuids) {
      let all_uuid = attachmentuuids.map((dt) => dt.attachment_uuid);
      for (let i = 0; i < all_uuid.length; i++) {
        formData.append(`attachment_uuids[${i}]`, all_uuid[i]);
      }
    }

    return formData;
  };

  const checkIsFormValid = () => {
    let trimmedMessage = formState.message.value
      .toString('html')
      .replace(/(<([^>]+)>)/gi, '')
      .trim()
      .replaceAll(/\s/g, '');

    return !!trimmedMessage;
  };

  const isMessageEmpty = (msg) => {
    return !msg
      .toString('html')
      .replace(/(<([^>]+)>)/gi, '')
      .trim()
      .replaceAll(/\s/g, '');
  };

  const checkIsDataEntered = (formState) => {
    return (
      !isMessageEmpty(formState.message.value) ||
      (!!getFiles(formState, skippedFiles) && !!getFiles(formState, skippedFiles).length)
    );
  };

  const promptErrors = (state) => {
    for (let key in state) {
      if (!state[key].isValid) {
        toast.error(state[key].errorStr);
        break;
      }
    }
    setShowToasts(false);
    setIsClicked(false);
  };

  const navigateToDetail = (id) => {
    if (!id) {
      history.push('/inbox');
    }
  };

  const onError = () => {
    setIsClicked(false);
    setError('Error sending reply, please try again later.');
  };

  const submitForm = async () => {
    let trimmedMessage = formState.message.value
      .toString('html')
      .replace(/(<([^>]+)>)/gi, '')
      .trim()
      .replaceAll(/\s/g, '');

    if (!!trimmedMessage) {
      if (!isClicked) {
        // handle multi clicks
        setIsClicked(true);
        let isFormValid = checkIsFormValid();
        if (isFormValid) {
          // getSignedUrls
          if (!!formState.files.length && !!getFileNames().filenames?.length) {
            const jwtToken = await getIdTokenClaims();
            dispatch(
              requestSignedUrls({
                data: getFileNames(),
                token: getRawToken(jwtToken),
                onSuccess: onSuccessSignedURLs,
                onError: onErrorSignedURLs,
              }),
            );
          } else {
            createReply(threadId);
          }
        } else {
          setShowToasts(true);
        }
      }
    } else {
      toast.error(searchFormErrorStr.message);
    }
  };

  const createReply = async (threadId, data = null) => {
    const jwtToken = await getIdTokenClaims();
    dispatch(
      sendReply({
        formData: getFormattedData(data),
        token: getRawToken(jwtToken),
        threadId: threadId,
        navigateToDetail: navigateToDetail,
        onError,
      }),
    );
  };

  const getFileNames = () => {
    let fileNames = [];
    if (!!formState.files.length) {
      formState.files.forEach((file, index) => {
        for (const key of Object.keys(file)) {
          if (!skippedFiles[index]?.includes(file[key].name)) {
            fileNames.push(file[key].name);
          }
        }
      });

      return {
        filenames: fileNames,
      };
    } else {
      return false;
    }
  };

  const onSuccessSignedURLs = async (data) => {
    let allFiles = getFiles(formState, skippedFiles);
    let uuids = [];
    let uploadingAllFiles = Promise.all(
      data.map(async (dt) => {
        let file = allFiles.find((fl, i) => {
          let isNameFound = fl.name === dt.filename && !uuids.includes(i);
          uuids.push(i);
          return isNameFound;
        });
        let response = await uploadUsingPresignedUrl(dt.url, dt.fields, file);
        if (!response.ok) {
          setError('Error uploading files, please try again later.');
          throw Error('Error uploading files.');
        }
      }),
    );

    uploadingAllFiles
      .then(() => {
        createReply(threadId, data);
      })
      .catch((err) => {
        setIsClicked(false);
        setError('Error uploading files, please try again later.');
      });
  };

  const onErrorSignedURLs = () => {
    setIsClicked(false);
    setError('Error uploading files, please try again later.');
  };

  return (
    <div
      className="reply-box"
      style={{
        width: size.x,
        height: size.y,
      }}
    >
      <button className="cursor-pointer drag-button" onMouseDown={resizeHandler}>
        <IoFilter size={18} cursor="pointer" />
      </button>
      <button
        className={`reply-close ${isClicked ? 'cursor-no-drop' : 'cursor-pointer'}`}
        onClick={() => !isClicked && props.state(false)}
      >
        <IoCloseSharp size={26} cursor="pointer" />
      </button>
      <div className="pt-4 px-4 pb-3 bg-white reply-form-wrapper">
        <div className="mb-3 width-100p flex-1 richtext_wrapper">
          <div className="width-100p height-100p">
            <RichTextEditor
              toolbarConfig={toolbarConfig}
              className="reply-rte-editor"
              value={formState.message.value}
              onChange={(value) => {
                setFormState((formState) => ({
                  ...formState,
                  message: { ...formState.message, value: value },
                }));
              }}
            />
          </div>
        </div>
        <div className="width-100p">
          <button
            htmlFor="attachments-upload"
            className="btn-secondary py-3 px-6 bg-transparent d-inline-flex flex-items-center"
            onClick={() => {
              setInputFile((inputFile) => [...inputFile, React.createRef()]);
            }}
          >
            <IoAttach size={22} className="mr-2" />
            Attach File
          </button>

          {inputFile.map((file, i) => (
            <input
              key={i}
              onChange={(e) =>
                onFileChange(e, formState, setFormState, skippedFiles, setError)
              }
              id={`attachments-upload-${i}`}
              type="file"
              style={{ display: 'none' }}
              multiple
              ref={file}
              accept=".DOC,.DOCX,.PDF,.XLS,.XLSX,.PPT,.doc,.docx,.pdf,.xls,.xlsx,.ppt"
            />
          ))}

          <button className="btn-primary float-right py-3 px-6 ml-6" onClick={submitForm}>
            {isClicked ? 'Replying...' : 'Reply'}
          </button>
          {isClicked && <AuthLoader absolute={true} faded={true} />}
          <div className="mt-2 ltr">
            {!!formState.files &&
              formState.files.map((file, index) =>
                Object.keys(file).map((key, i) => {
                  if (skippedFiles[index]?.includes(file[key].name)) {
                    return null;
                  }
                  return (
                    <p
                      className="fw-semibold color-secondary my-1 d-flex align-items-center"
                      key={i}
                    >
                      <IoAttach size={22} className="mr-2" />
                      {file[key].name}{' '}
                      <span className="small color-text-secondary ml-2">
                        (Size: {convertSize(file[key].size, { accuracy: 1 })})
                      </span>
                      <IoCloseSharp
                        className="modal-close-btn color-primary"
                        title="Close"
                        size={22}
                        onClick={() =>
                          setSkippedFiles((skippedFiles) => ({
                            ...skippedFiles,
                            [index]: !!skippedFiles[index]
                              ? [...skippedFiles[index], file[key].name]
                              : [file[key].name],
                          }))
                        }
                        style={{ cursor: 'pointer' }}
                      />
                    </p>
                  );
                }),
              )}
            {!!error && <p className="color-error text-right">{error}</p>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReplyBox;
