import React from 'react';

const Table = ({ data }) => {
  const headers = Object.keys(data[0]);

  return (
    <div>
      <div className="color-text-secondary patient-details-tbl-title">
        Patient Details
      </div>
      <table className="csv-render-table">
        <thead>
          <tr>
            <th></th>
            {headers.map((header) => (
              <th className="color-text-secondary fw-medium h6 highlighted-th">
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((row, rowIndex) => (
            <tr key={row.id}>
              <td className="highlighted-td">{rowIndex + 1}</td>
              {headers.map((header) => (
                <td key={header}>{row[header]}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
