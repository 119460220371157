import { searchFormErrorStr } from 'constants/errorStrings';
import { toast } from 'react-toastify';

export const MAX_ATTACHMENTS_SIZE = 25000000;

export const fileExtensions = [
  'DOC',
  'DOCX',
  'PDF',
  'XLS',
  'XLSX',
  'PPT',
  'doc',
  'docx',
  'pdf',
  'xls',
  'xlsx',
  'ppt',
];

export const uploadUsingPresignedUrl = async (preSignedUrl, fields, file) => {
  let form = new FormData();
  Object.keys(fields).forEach((key) => form.append(key, fields[key]));
  form.append('file', file);
  return await fetch(preSignedUrl, {
    method: 'POST',
    body: form,
    headers: {
      'x-amz-server-side-encryption': 'AES256',
    },
  });
};

export const getFiles = (formState, skippedFiles) => {
  let files = [];
  if (!!formState.files.length) {
    formState.files.forEach((file, index) => {
      for (const key of Object.keys(file)) {
        if (!skippedFiles[index]?.includes(file[key].name)) {
          files.push(file[key]);
        }
      }
    });

    return files;
  } else {
    return false;
  }
};

export const getTotalSize = (formState, skippedFiles) => {
  let files = getFiles(formState, skippedFiles);
  if (files && !!files.length) {
    let totalFileSize = [...files]
      .map((file) => file.size)
      .reduce((prev, next) => prev + next);
    return totalFileSize;
  }
  return 0;
};

export const onFileChange = (e, formState, setFormState, skippedFiles, setError) => {
  let allFiles = e.target.files;
  let isAnyInvalidExtensions = false;

  let currentFileSize = [...allFiles]
    .map((file) => file.size)
    .reduce((prev, next) => prev + next);
  let totalFileSize = getTotalSize(formState, skippedFiles);

  for (const key of Object.keys(allFiles)) {
    let splitName = allFiles[key].name.split('.');
    if (!fileExtensions.includes(splitName[splitName.length - 1])) {
      isAnyInvalidExtensions = true;
    }
  }

  if (currentFileSize > MAX_ATTACHMENTS_SIZE) {
    toast.error(searchFormErrorStr.invalidFileSize);
    setError(searchFormErrorStr.invalidFileSize);
  } else if (isAnyInvalidExtensions && !Object.keys(allFiles).length) {
    toast.error(searchFormErrorStr.invalidFileFormat);
  } else if (totalFileSize + currentFileSize > MAX_ATTACHMENTS_SIZE) {
    toast.error(searchFormErrorStr.invalidFileSize);
    setError(searchFormErrorStr.invalidFileSize);
  } else {
    let updatedFiles = formState.files;
    updatedFiles.push(allFiles);
    setFormState((formState) => ({
      ...formState,
      files: updatedFiles,
    }));
    setError('');
  }
};
