import React from 'react';
import Modal from 'react-modal';
import { IoCloseSharp } from 'react-icons/io5';
import PropTypes from 'prop-types';

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#root');

const CommonModal = ({
  isOpen,
  handleClose,
  handleConfirm,
  cancelText,
  confirmText,
  isHideCancelBtn,
  isHideConfirmBtn,
  modalTitle,
  modalDesc,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleClose}
      className="modal p-6"
      overlayClassName="modal-overlay"
    >
      <div className="p-4 text-center">
        <h4 className="color-text-primary mt-0 mb-4">{modalTitle}</h4>
        <p className="color-text-secondary m-0">{modalDesc}</p>
        <div className="d-inline-block mt-5">
          {!isHideCancelBtn && (
            <button className="btn-secondary py-3 px-6" onClick={handleClose}>
              {cancelText}
            </button>
          )}
          {!isHideConfirmBtn && (
            <button
              className={`btn-primary py-3 px-6 ${isHideCancelBtn ? '' : 'ml-4'}`}
              onClick={handleConfirm}
            >
              <span className="px-5">{confirmText}</span>
            </button>
          )}
        </div>
      </div>
      <IoCloseSharp className="modal-close-btn" title="Close" onClick={handleClose} />
    </Modal>
  );
};

CommonModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func,
  cancelText: PropTypes.string,
  confirmText: PropTypes.string,
  isHideCancelBtn: PropTypes.bool,
  isHideConfirmBtn: PropTypes.bool,
  modalTitle: PropTypes.string.isRequired,
  modalDesc: PropTypes.string.isRequired,
};

CommonModal.defaultProps = {
  isOpen: false,
  cancelText: 'No, Cancel',
  confirmText: 'Yes',
  isHideCancelBtn: false,
  isHideConfirmBtn: false,
};

export default CommonModal;
