import {
  SET_SEARCHED_PATIENT_KEYS,
  SET_DOCUMENTS_DATA,
  RESET_SEARCH_DATA,
} from './actions';

const reducer = (state = {}, { payload, type }) => {
  switch (type) {
    case SET_SEARCHED_PATIENT_KEYS:
      return { ...state, searchedPatientKeys: payload.data };
    case SET_DOCUMENTS_DATA:
      return { ...state, documentData: payload.data };
    case RESET_SEARCH_DATA:
      return {};
    default:
      return state;
  }
};

export default reducer;
