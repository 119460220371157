import { viewArtifact } from 'api/';
import { call, takeLatest, put } from 'redux-saga/effects';
import {
  REQUEST_GET_ARTIFACT_DATA,
  setArtifactData,
  setLoading,
  resetData,
  REQUEST_PROGRESS_NOTES,
  REQUEST_LAB_REPORTS,
} from './actions';

export function* getArtifactData({ payload: { id, token, onError } }) {
  try {
    yield put(resetData());
    yield put(setLoading({ data: true }));
    const { response } = yield call(viewArtifact.viewArtifact, {
      id,
      token,
    });

    if (!!response) {
      yield put(setArtifactData({ data: response }));
      yield put(setLoading({ data: false }));
    } else {
      yield put(setLoading({ data: false }));
      onError?.();
    }
  } catch (error) {
    yield put(setLoading({ data: false }));
    onError?.();
  }
}

export function* getProgressNotes({
  payload: { token, setProgressNotes, id, guid, onError },
}) {
  try {
    const { response } = yield call(viewArtifact.getProgressNotes, {
      token,
      id,
      guid,
    });

    if (!!response) {
      setProgressNotes?.(response);
    } else {
      onError?.();
    }
  } catch (error) {
    onError?.();
  }
}

export function* getLabReports({ payload: { token, setLabReports, id, guid, onError } }) {
  try {
    const { response } = yield call(viewArtifact.getLabReports, {
      token,
      id,
      guid,
    });

    if (!!response) {
      setLabReports?.(response);
    } else {
      onError?.();
    }
  } catch (error) {
    onError?.();
  }
}

function* watchRequestGetArtifactData() {
  yield takeLatest(REQUEST_GET_ARTIFACT_DATA, getArtifactData);
}

function* watchGetProgressNotes() {
  yield takeLatest(REQUEST_PROGRESS_NOTES, getProgressNotes);
}

function* watchGetLabReports() {
  yield takeLatest(REQUEST_LAB_REPORTS, getLabReports);
}

export const sagas = [
  watchRequestGetArtifactData,
  watchGetProgressNotes,
  watchGetLabReports,
];
