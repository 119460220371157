import React from 'react';

const ProgressButton = ({ progress }) => {
  return (
    <button className={`btn-in-progress py-3 px-6 col-12 position-relative`}>
      In Progress
      <div className="position-absolute progress-track">
        <div className="progress-indicator" style={{ width: `${progress}%` }}></div>
      </div>
    </button>
  );
};
export default ProgressButton;
