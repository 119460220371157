export const formatAddress = (address) => {
  if (
    !address ||
    (!!address.line &&
      (!address.line.length || !address.line?.[0]) &&
      !address?.city &&
      !address?.state &&
      !address?.postalCode)
  ) {
    return 'Not available';
  }

  return `${address?.line ? address?.line?.toString() : ''}, ${
    address?.city ? address?.city : ''
  }, ${address?.state ? address?.state : ''} ${
    address?.postalCode ? address?.postalCode : ''
  }, ${address?.country ? address?.country : ''}`;
};
