import axios from 'axios';

const CancelToken = axios.CancelToken;
let source = null;

export const getMessages = async ({
  token,
  activeTab,
  pageSize,
  searchKey,
  providerKey,
  patientKey,
  shouldCancel = false,
}) => {
  let subRoute =
    activeTab === 'UNANSWERED' ? 'pending/' : activeTab === 'NEW' ? 'new/' : '';

  if (source && shouldCancel) {
    source.cancel();
  }

  source = shouldCancel ? CancelToken.source() : null;

  return axios({
    method: 'get',
    url: `ticket/${subRoute}?page_size=${pageSize}&page=1${
      !!searchKey ? '&search=' + searchKey : ''
    }${!!providerKey ? '&provider=' + providerKey : ''}${
      !!patientKey ? '&patient=' + patientKey : ''
    }`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    cancelToken: shouldCancel ? source.token : null,
  })
    .then((response) => ({ response: response.data }))
    .catch((error) => {
      if (axios.isCancel(error)) {
        return { error: 'canceled' };
      }
      return { error: error.response };
    });
};

export const getNextMessages = async ({ token, nextUrl }) => {
  return axios({
    method: 'get',
    url: nextUrl,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => ({ response: response.data }))
    .catch((error) => ({ error: error.response }));
};

export const sendMessage = async ({ token, formData }) => {
  return axios({
    method: 'post',
    url: 'ticket/',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    data: formData,
  })
    .then((response) => ({ response: response.data }))
    .catch((error) => ({ error: error.response }));
};

export const sendReply = async ({ token, threadId, formData }) => {
  return axios({
    method: 'post',
    url: `ticket/${threadId}/message/`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    data: formData,
  })
    .then((response) => ({ response: response.data }))
    .catch((error) => ({ error: error.response }));
};

export const getThreadMessages = async ({ token, threadId, pageSize }) => {
  return axios({
    method: 'get',
    url: `ticket/${threadId}/message/?page_size=${pageSize}&page=1`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => ({ response: response.data }))
    .catch((error) => ({ error: error.response }));
};

export const markThreadAsRead = async ({ token, threadId }) => {
  return axios({
    method: 'put',
    url: `ticket/${threadId}/markread/`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => ({ response: response.data }))
    .catch((error) => ({ error: error.response }));
};

export const getSignedUrls = async ({ token, data }) => {
  return axios({
    method: 'post',
    url: 'attachment_endpoint/',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    data: data,
  })
    .then((response) => ({ response: response.data }))
    .catch((error) => ({ error: error.response }));
};

export const getMsgDeliveryProgress = async ({ job_id, token }) => {
  return axios({
    method: 'get',
    url: `/celery-progress/${job_id}/`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => ({ response: response.data }))
    .catch((error) => ({ error: error.response }));
};
