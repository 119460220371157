/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { IoWarning, IoLayers, IoMailUnread } from 'react-icons/io5';
import LoadingMessageBlock from 'components/LoadingMessageBlock';
import { requestMessages, requestNextMessages } from 'redux/modules/inbox/actions';
import moment from 'moment';
import InfiniteScroll from 'react-infinite-scroll-component';
import { TextInput } from 'components/form-input';
import { IoSearchOutline, IoCloseSharp, IoPencil } from 'react-icons/io5';
import { useHistory, useLocation } from 'react-router-dom';
import { getRawToken } from 'utils/auth';
import AsyncSelect from 'react-select/async';
import { searchPatientsCompose, searchProvidersCompose } from 'api/compose';
import _ from 'lodash';

const dropdownStyles = {
  control: (provided) => ({
    ...provided,
    minHeight: 38,
    outline: 'none',
    boxShadow: 'none',
    border: 0,
  }),
  container: (provided) => ({
    ...provided,
    padding: 0,
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? 'white' : 'white',
    color: state.isSelected ? 'inherit' : 'inherit',
  }),
};

let IoSearchOutlineStyled = styled(IoSearchOutline)`
  font-size: 24px;
`;

const TABNAMES = {
  ALL: 'ALL',
  UNANSWERED: 'UNANSWERED',
  NEW: 'NEW',
};

const PAGE_SIZE = 15;

const Inbox = () => {
  const dispatch = useDispatch();
  const { getIdTokenClaims } = useAuth0();
  const location = useLocation();

  const [activeTab, setActiveTab] = useState(
    !location?.state?.activeTab ? TABNAMES.UNANSWERED : location?.state?.activeTab,
  );

  const [searchKey, setSearchKey] = useState({ value: '', switch: false });
  const [patientKey, setPatientKey] = useState({ value: '', switch: false });
  const [providerKey, setProviderKey] = useState({ value: '', switch: false });

  const [resultStatus, setResultStatus] = useState('in-progress');
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const [nextUrl, setNextUrl] = useState(null);
  const [messages, setMessages] = useState([]);
  const [unansweredCount, setUnansweredCount] = useState(-1);
  const [allCount, setAllCount] = useState(-1);
  const [newCount, setNewCount] = useState(-1);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [providerOptions, setProviderOptions] = useState([]);
  const [patientsOptions, setPatientsOptions] = useState([]);
  const [activeFilters, setActiveFilters] = useState({
    search: '',
    provider: '',
    patient: '',
  });
  const history = useHistory();
  const [error, setError] = useState('');
  let inboxState = useSelector((state) => state.inbox);

  useEffect(() => {
    getInitialCount(activeTab);
  }, []);

  useEffect(() => {
    getMessages(activeTab, setMessagesToState);
    history.replace(location.state, { activeTab: activeTab });
  }, [activeTab]);

  useEffect(() => {
    if (!!inboxState.newCount && inboxState.newCount > 0) {
      //fetch new messages
      if (activeTab === TABNAMES.NEW) {
        getMessages(activeTab, setMessagesToState);
      }
      getNewCount();
    }
  }, [inboxState.newCount]);

  useEffect(() => {
    getMessages(activeTab, setMessagesToState);
    getInitialCount(activeTab);
  }, [providerKey.switch, patientKey.switch, searchKey.switch]);

  useEffect(() => {
    if (!isInitialLoad) {
      if (!!messages.length) {
        setResultStatus('found');
      } else {
        setResultStatus('not-found');
      }
    }
  }, [messages, isInitialLoad]);

  const setMessagesToState = (response) => {
    setError('');
    setIsInitialLoad(false);
    setMessages(response.results);
    setNextUrl(response.next);
    setCount(response.count);
    if (!response.next) {
      setHasMore(false);
    } else {
      setHasMore(true);
    }
  };

  const getInitialCount = (activeTab) => {
    if (activeTab === TABNAMES.UNANSWERED) {
      getMessages(
        TABNAMES.ALL,
        (response) => {
          setAllCount(response.count);
        },
        1,
      );

      getMessages(
        TABNAMES.NEW,
        (response) => {
          setNewCount(response.count);
        },
        1,
      );
    } else if (activeTab === TABNAMES.NEW) {
      getMessages(
        TABNAMES.ALL,
        (response) => {
          setAllCount(response.count);
        },
        1,
      );

      getMessages(
        TABNAMES.UNANSWERED,
        (response) => {
          setUnansweredCount(response.count);
        },
        1,
      );
    } else {
      getMessages(
        TABNAMES.UNANSWERED,
        (response) => {
          setUnansweredCount(response.count);
        },
        1,
      );
      getMessages(
        TABNAMES.NEW,
        (response) => {
          setNewCount(response.count);
        },
        1,
      );
    }
  };

  const setCount = (count) => {
    if (activeTab === TABNAMES.UNANSWERED) {
      setUnansweredCount(count);
    } else if (activeTab === TABNAMES.NEW) {
      setNewCount(count);
    } else {
      setAllCount(count);
    }
  };

  const getNewCount = async () => {
    const token = await getIdTokenClaims();
    dispatch(
      requestMessages({
        token: getRawToken(token),
        activeTab: TABNAMES.NEW,
        setMessagesToState: (response) => {
          setNewCount(response.count);
        },
        pageSize: 1,
        searchKey: searchKey.value,
        patientKey: patientKey.value,
        providerKey: providerKey.value,
        onError: onError,
      }),
    );
  };

  const setNextMessagesToState = (response) => {
    setMessages((msg) => [...msg, ...response.results]);
    setNextUrl(response.next);
    if (!response.next) {
      setHasMore(false);
    } else {
      setHasMore(true);
    }
  };

  const getMessages = async (activeTab, setMessagesToState, pageSize = PAGE_SIZE) => {
    setResultStatus('in-progress');
    const token = await getIdTokenClaims();
    dispatch(
      requestMessages({
        token: getRawToken(token),
        activeTab,
        setMessagesToState,
        pageSize,
        searchKey: searchKey.value,
        patientKey: patientKey.value,
        providerKey: providerKey.value,
        onError: onError,
        shouldCancel: pageSize === PAGE_SIZE,
      }),
    );
  };

  const onError = () => {
    setResultStatus('not-found');
    setError('Error in fetching data, please try again later.');
  };

  const getNextMessages = async () => {
    if (!!nextUrl) {
      const token = await getIdTokenClaims();
      dispatch(
        requestNextMessages({
          token: getRawToken(token),
          nextUrl: nextUrl,
          setNextMessagesToState,
        }),
      );
    }
  };

  const handleTabSwitch = (tab) => {
    if (unansweredCount === -1 || newCount === -1 || allCount === -1) {
      return;
    }
    if (tab === activeTab || isInitialLoad) {
      return;
    }

    setHasMore(true);
    setIsInitialLoad(true);
    setMessages([]);
    setActiveTab(tab);
  };

  const getInitials = (name) => {
    let initials = '-';
    if (!!name) {
      let fullName = name.split(' ');
      if (fullName.length === 1) {
        return fullName[0][0].toUpperCase();
      }
      initials = `${fullName[0][0].toUpperCase()}${
        !!fullName[1][0] ? fullName[1][0].toUpperCase() : ''
      }`;
    }
    return initials;
  };

  const getSendType = (initiated) => {
    if (initiated === 'provider') {
      return 'From : ';
    }
    if (initiated === 'claim_admin') {
      return 'To : ';
    }
    return '';
  };

  const getTimeFormatted = (date) => {
    return moment(moment(new Date(date))).calendar({
      sameDay: 'h:mm A',
      nextDay: '[Tomorrow]',
      nextWeek: 'dddd',
      lastDay: '[Yesterday], h:mm A',
      lastWeek: 'Do MMM, h:mm A',
      sameElse: 'DD/MM/YYYY, h:mm A',
    });
  };

  const searchMessages = () => {
    setSearchKey({ ...searchKey, switch: !searchKey.switch });
    setPatientKey({ ...patientKey, switch: !patientKey.switch });
    setProviderKey({ ...providerKey, switch: !providerKey.switch });
    setActiveFilters({
      search: searchKey.value,
      provider: providerKey.value,
      patient: patientKey.value,
    });
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      submitSearch();
    }
  };

  const navigateToCompose = () => {
    history.push('/compose');
  };

  const handleViewThread = (id) => {
    history.push({
      pathname: `/thread-details/${id}`,
      state: {
        activeTab,
      },
    });
  };

  const submitSearch = () => {
    searchMessages();
    setIsFilterOpen(false);
  };

  const loadPatientsOptions = async (keyword) => {
    const jwtToken = await getIdTokenClaims();

    let { response } = await searchPatientsCompose({
      keyword,
      token: jwtToken.__raw,
    });

    let options = response.map((item) => ({
      value: item.resource_id,
      label: item.name,
      ...item,
    }));

    options = _.uniqBy(options, 'label');

    setPatientsOptions([...options]);

    return options;
  };

  const loadProvidersOptions = async (key) => {
    const jwtToken = await getIdTokenClaims();

    let { response } = await searchProvidersCompose({
      key,
      token: jwtToken.__raw,
    });

    let options = response.results.map((item) => ({
      value: item.guid,
      label: item.name,
      ...item,
    }));

    options = _.uniqBy(options, 'label');

    setProviderOptions([...options]);

    return options;
  };

  return (
    <div className="px-5 pt-4 scrollable-page d-flex flex-column col-12">
      <div className="d-flex flex-justify-between mb-3 flex-content-center">
        <h5 className="color-primary-dark mb-0 mt-0">Inbox</h5>
        <div className="d-flex flex-row flex-justify-start position-relative">
          <TextInput
            className="search-icon"
            placeholder="Search"
            value={searchKey.value}
            onChange={(e) => setSearchKey({ ...searchKey, value: e.target.value })}
            onKeyUp={handleKeyDown}
            size="lg"
            withAdvSearch
            isFilterOpen={isFilterOpen}
            onAdvSearchClick={() => setIsFilterOpen((st) => !st)}
          />
          <button
            className="btn-search"
            type="submit"
            style={{ height: 40, width: 45, zIndex: isFilterOpen ? 115 : 1 }}
            onClick={() => {
              submitSearch();
            }}
          >
            <IoSearchOutlineStyled />
          </button>
          <button
            className="btn-primary bg-round-faded pt-2 pl-2 pb-2 pr-3 ml-3"
            onClick={() => navigateToCompose()}
          >
            <IoPencil className="" /> Compose
          </button>
          {isFilterOpen && <div className="filter-overlay"></div>}
          {isFilterOpen && (
            <div className="position-absolute bg-white p-4 filter-modal-2">
              <div className="mb-4">
                <p className="mt-0 mb-2 color-text-primary">Provider Name</p>
                <AsyncSelect
                  cacheOptions={false}
                  isClearable
                  noOptionsMessage={() =>
                    !!providerKey?.value
                      ? 'No providers found.'
                      : 'Type something to search...'
                  }
                  value={
                    providerOptions.filter((i) => i.name === providerKey.value) ?? null
                  }
                  defaultOptions={providerOptions}
                  isSearchable
                  loadOptions={loadProvidersOptions}
                  onInputChange={(e, action) => {
                    if (action.action !== 'input-change') return;
                    setProviderKey({ ...providerKey, value: e });
                  }}
                  onChange={(item) => {
                    setProviderKey({
                      ...providerKey,
                      value: item?.name || '',
                      guid: item?.guid || '',
                    });
                  }}
                  className="form-input"
                  styles={dropdownStyles}
                />
              </div>
              <div className="mb-4">
                <p className="mt-0 mb-2 color-text-primary">Patient Name</p>
                <AsyncSelect
                  cacheOptions={false}
                  isClearable
                  noOptionsMessage={() =>
                    !!patientKey?.value
                      ? 'No patients found.'
                      : 'Type something to search...'
                  }
                  defaultOptions={patientsOptions}
                  isSearchable
                  value={
                    patientsOptions.filter((i) => i.name === patientKey.value) ?? null
                  }
                  loadOptions={loadPatientsOptions}
                  onInputChange={(e, action) => {
                    if (action.action !== 'input-change') return;
                    setPatientKey({ ...patientKey, value: e });
                  }}
                  onChange={(item) => {
                    setPatientKey({
                      ...patientKey,
                      value: item?.name || '',
                      resource_id: item?.resource_id || '',
                    });
                  }}
                  className="form-input"
                  styles={dropdownStyles}
                />
              </div>

              <div className="d-flex flex-justify-between" style={{ marginTop: '200px' }}>
                <span
                  className="btn-secondary py-3 px-3"
                  onClick={() => setIsFilterOpen(false)}
                >
                  Cancel
                </span>
                <span
                  className="btn-primary py-3 px-3"
                  onClick={() => {
                    submitSearch();
                  }}
                >
                  Apply
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
      {!!error && (
        <p className="color-error pl-0">
          {error} or{' '}
          <span
            className="cursor-pointer color-secondary"
            onClick={() => window.location.reload()}
          >
            Reload
          </span>
        </p>
      )}
      <div className="tab-wrapper d-flex">
        <p
          className={`fw-semibold tab-item p-3 m-0 d-flex flex-items-center flex-justify-center ${
            activeTab === TABNAMES.UNANSWERED
              ? 'active color-text-primary'
              : 'color-text-secondary '
          }`}
          onClick={() => handleTabSwitch(TABNAMES.UNANSWERED)}
        >
          <IoWarning className="h5 pr-1" />
          Pending {unansweredCount === -1 ? '' : `(${unansweredCount})`}
        </p>
        <p
          className={`fw-semibold tab-item p-3 m-0 d-flex flex-items-center flex-justify-center ${
            activeTab === TABNAMES.NEW
              ? 'active color-text-primary'
              : 'color-text-secondary '
          }`}
          onClick={() => handleTabSwitch(TABNAMES.NEW)}
        >
          <IoMailUnread className="h5 pr-1" />
          New {newCount === -1 ? '' : `(${newCount})`}
        </p>
        <p
          className={`fw-semibold tab-item p-3 m-0 d-flex flex-items-center flex-justify-center ${
            activeTab === TABNAMES.ALL
              ? 'active color-text-primary'
              : 'color-text-secondary '
          }`}
          onClick={() => handleTabSwitch(TABNAMES.ALL)}
        >
          <IoLayers className="h5 pr-1" />
          All {allCount === -1 ? '' : `(${allCount})`}
        </p>

        <p className="text-right flex-1 color-text-secondary small flex-self-center d-flex flex-items-center flex-justify-end">
          {!!activeFilters.search && (
            <>
              <span className="fw-semibold">"{activeFilters.search}"</span>
              <span
                className="filter-tag color-text-primary mx-1 mr-2"
                onClick={() => {
                  setActiveFilters((st) => ({
                    ...st,
                    search: '',
                  }));
                  setSearchKey({ value: '', switch: !searchKey.switch });
                }}
              >
                <IoCloseSharp className="filter-remove-cross" />
              </span>
            </>
          )}
          {!!activeFilters.patient && (
            <>
              <span className="fw-semibold">"{activeFilters.patient}"</span>
              <span
                className="filter-tag color-text-primary mx-1 mr-2"
                onClick={() => {
                  setActiveFilters((st) => ({
                    ...st,
                    patient: '',
                  }));
                  setPatientKey({ value: '', switch: !patientKey.switch });
                }}
              >
                <IoCloseSharp className="filter-remove-cross" />
              </span>
            </>
          )}
          {!!activeFilters.provider && (
            <>
              <span className="fw-semibold">"{activeFilters.provider}"</span>
              <span
                className="filter-tag color-text-primary mx-1"
                onClick={() => {
                  setActiveFilters((st) => ({
                    ...st,
                    provider: '',
                  }));
                  setProviderKey({ value: '', switch: !providerKey.switch });
                }}
              >
                <IoCloseSharp className="filter-remove-cross" />
              </span>
            </>
          )}
        </p>
      </div>
      <div className="inbox-wrapper">
        {resultStatus === 'found' && (
          <InfiniteScroll
            dataLength={messages.length}
            next={getNextMessages}
            hasMore={hasMore}
            loader={<LoadingMessageBlock />}
            scrollThreshold="300px"
            height="100%"
            endMessage={
              <p className="text-center color-text-secondary">No more messages</p>
            }
          >
            {messages.map((message) => (
              <div
                key={message.id}
                className={`inbox-message d-flex ${message.read ? '' : 'unread'} `}
                onClick={() => handleViewThread(message.id)}
              >
                <div
                  className={`avatar-initials mx-3 d-flex flex-items-center flex-justify-center avatar_bg_${message?.provider_name[0]?.toUpperCase()}`}
                >
                  <h5 className="color-white m-0 p-0">
                    {getInitials(message?.provider_name)}
                  </h5>
                </div>
                <div className="flex-1 pt-3 pr-3">
                  <div className="d-flex flex-justify-between">
                    <div>
                      <span className="x-small color-text-secondary">
                        {getSendType(message.last_message_role)}
                      </span>
                      <span className="x-small color-text-primary fw-semibold">
                        {message?.provider_name}
                      </span>
                      <span className="x-small color-text-secondary ml-1">
                        {`<${message?.provider_email}>`}
                      </span>
                    </div>
                    <span className="x-small color-text-secondary">
                      {getTimeFormatted(message.updated_at)}
                    </span>
                  </div>
                  <div className="text-ellipsis">
                    <p className="small fw-semibold color-text-primary mt-2 mb-0">
                      {message.subject}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </InfiniteScroll>
        )}

        {messages.length === 0 && resultStatus === 'in-progress' && !error && (
          <LoadingMessageBlock />
        )}

        {resultStatus === 'not-found' && (
          <div className="d-flex flex-column col-12 flex-justify-center flex-items-center mb-6 pb-6 mt-6">
            <h5 className="color-primary-light mb-2 mt-5">No messages found.</h5>
          </div>
        )}
      </div>
    </div>
  );
};
export default Inbox;
