export const REQUEST_GET_ARTIFACT_DATA = 'viewArtifact/requestGetArtifactData';
export const REQUEST_PROGRESS_NOTES = 'viewArtifact/requestProgressNotes';
export const REQUEST_LAB_REPORTS = 'viewArtifact/requestLabReports';
export const SET_ARTIFACT_DATA = 'viewArtifact/setArtifactData';
export const SET_ERROR = 'viewArtifact/setError';
export const SET_LOADING = 'viewArtifact/setLoading';
export const RESET_DATA = 'viewArtifact/resetData';

export const requestGetArtifactData = (payload = {}) => ({
  type: REQUEST_GET_ARTIFACT_DATA,
  payload,
});

export const requestProgressNotes = (payload = {}) => ({
  type: REQUEST_PROGRESS_NOTES,
  payload,
});

export const requestLabReports = (payload = {}) => ({
  type: REQUEST_LAB_REPORTS,
  payload,
});

export const setArtifactData = (payload = []) => ({
  type: SET_ARTIFACT_DATA,
  payload,
});

export const setError = (payload = {}) => ({
  type: SET_ERROR,
  payload,
});

export const setLoading = (payload = []) => ({
  type: SET_LOADING,
  payload,
});

export const resetData = (payload = []) => ({
  type: RESET_DATA,
  payload,
});
