import { searchPatient } from 'api/';
import { call, put, takeLatest, delay } from 'redux-saga/effects';
import {
  REQUEST_SEARCH_PATIENT,
  REQUEST_PATIENT_DOCUMENTS,
  setSearhedPatientKeys,
  requestPatientDocuments,
  setDocumentData,
  resetSearchData,
} from './actions';

export function* getSearchedPatientKeys({
  payload: { searchFormData, token, navigateToResults, onError, navigateToSearch },
}) {
  try {
    yield put(resetSearchData());
    const { response } = yield call(searchPatient.searchPatient, {
      searchFormData,
      token,
    });

    if (!!response) {
      yield put(setSearhedPatientKeys({ data: response }));
      if (!!response.job_id) {
        navigateToResults?.();
      } else {
        onError?.();
      }
    } else {
      onError?.();
    }
  } catch (error) {
    onError?.();
  }
}

function* watchRequestSeachPatient() {
  yield takeLatest(REQUEST_SEARCH_PATIENT, getSearchedPatientKeys);
}

export const sagas = [watchRequestSeachPatient];
