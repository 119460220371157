import { SET_LOADING, SET_ERROR } from './actions';

let initialState = {
  isError: false,
  isLoading: false,
};

const reducer = (state = initialState, { payload, type }) => {
  switch (type) {
    case SET_ERROR:
      return { ...state, isError: payload.data };
    case SET_LOADING:
      return { ...state, isLoading: payload.data };
    default:
      return state;
  }
};

export default reducer;
