import React, { useState } from 'react';
import { IoAttach } from 'react-icons/io5';
import Table from './BatchProcessingTable';
import { uploadPatientDetails } from 'api/batchProcessing';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useAuth0 } from '@auth0/auth0-react';

const BatchProcessingFileUpload = () => {
  const { getIdTokenClaims } = useAuth0();
  const [file, setFile] = useState(null);
  const [uploadedData, setUploadedData] = useState([]);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('file', file);

    try {
      const jwtToken = await getIdTokenClaims();
      let { response } = await uploadPatientDetails({
        formData,
        token: jwtToken.__raw,
      });
      setFile(null);
      if (response.valid_data.length > 0) {
        setUploadedData(response.valid_data);
        toast.success('Batch processing job submitted successfully!', {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 5000,
          closeButton: true,
        });
      }
      console.log(response.validation_errors);
      if (response.validation_errors) {
        const errorMessages = response.validation_errors;
        toast.error(<CustomToastContent message={errorMessages} />, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 20000,
          closeButton: true,
        });
      }
    } catch (err) {
      toast.error('Unexpected error. Please try again.', {
        position: toast.POSITION.TOP_CENTER,
        autoClose: false,
      });
      console.error(err);
    }
  };

  const CustomToastContent = ({ message }) => (
    <div dangerouslySetInnerHTML={{ __html: message }} />
  );

  return (
    <div className="file-upload-and-render-page">
      <ToastContainer />
      <h5 className="color-primary-dark mb-4 mt-0 batch-processing-header">
        Batch Processing
      </h5>
      <div className="upload-csv-section">
        <h5 className="color-primary-dark mb-4 mt-0 upload-csv-btn">
          Upload patient details file
        </h5>
        <form onSubmit={handleSubmit}>
          <button
            className="attach-button"
            type="button"
            name="attach-button"
            onClick={() => document.getElementById('file-input').click()}
          >
            <IoAttach size={22} className="mr-2" />
            Upload File
          </button>
          {file != null ? <span className="uploaded-file">{file.name}</span> : ''}
          <input
            type="file"
            accept=".csv"
            id="file-input"
            onChange={handleFileChange}
            style={{ display: 'none' }}
          />
          <button
            className={`batch-submit-btn ${file == null ? 'disabled' : ''}`}
            type="submit"
            disabled={file == null}
            style={{
              cursor: file == null ? 'not-allowed' : 'pointer',
              opacity: file == null ? 0.5 : 1,
              pointerEvents: file == null ? 'none' : 'auto',
            }}
          >
            Submit
          </button>
        </form>
      </div>
      {uploadedData.length > 0 && (
        <>
          <h5 className="color-primary-dark mb-4 tbl-tilte">
            Accepted Patients for Batch process
          </h5>
          <div className="table-section">
            <Table data={uploadedData} />
          </div>
        </>
      )}
    </div>
  );
};
export default BatchProcessingFileUpload;
