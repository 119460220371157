import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import AuthLoader from 'components/AuthLoader';

const Auth = () => {
  const { loginWithRedirect, isAuthenticated, isLoading } = useAuth0();

  useEffect(() => {
    !isAuthenticated && !isLoading && loginWithRedirect();
  }, [isAuthenticated, isLoading, loginWithRedirect]);

  return <AuthLoader />;
};
export default Auth;
