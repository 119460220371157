import { history } from 'api/';
import { call, takeLatest, put } from 'redux-saga/effects';
import { REQUEST_PHYSICIAN_STATEMENTS, isLoading } from './actions';

export function* getPhysicianStatements({
  payload: { token, setHistoryData, filters, page, onError },
}) {
  yield put(isLoading({ data: true }));
  try {
    const { response } = yield call(history.getPhysicianStatements, {
      token,
      filters,
      page,
    });

    if (!!response) {
      setHistoryData?.(response.results, response.count);
      yield put(isLoading({ data: false }));
    } else {
      onError?.();
      yield put(isLoading({ data: false }));
    }
  } catch (error) {
    onError?.();
    yield put(isLoading({ data: false }));
  }
}

function* watchRequestGetPhysicianStatements() {
  yield takeLatest(REQUEST_PHYSICIAN_STATEMENTS, getPhysicianStatements);
}

export const sagas = [watchRequestGetPhysicianStatements];
