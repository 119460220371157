import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-dropdown-select';

const DropdownInput = ({
  values,
  isError,
  placeholder,
  size,
  options,
  onChange,
  ...props
}) => {
  return (
    <div>
      <Select
        options={options}
        values={values}
        onChange={(value) => onChange(value)}
        className={`form-input pl-3 reset-focus-style ${
          isError ? 'border-error' : 'reset-hover-style'
        } input-${size} `}
        placeholder={placeholder}
        {...props}
      />
    </div>
  );
};

DropdownInput.propTypes = {
  value: PropTypes.array,
  isError: PropTypes.bool,
  placeholder: PropTypes.string,
  size: PropTypes.string,
  options: PropTypes.array,
};

DropdownInput.defaultProps = {
  value: '',
  isError: false,
  placeholder: '',
  size: 'auto', // values for size : sm, md, lg, auto
  options: [],
};

export default DropdownInput;
