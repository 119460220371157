import axios from 'axios';

export const searchPatientsCompose = async ({ keyword, token }) => {
  return axios({
    method: 'get',
    url: `patient/?search=${keyword}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => ({ response: response.data }))
    .catch((error) => ({ error: error.response }));
};

export const searchClaimsCompose = async ({ resource_id, token }) => {
  return axios({
    method: 'get',
    url: `patient/${resource_id}/claims/`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => ({ response: response.data }))
    .catch((error) => ({ error: error.response }));
};

export const searchProvidersCompose = async ({ key, token }) => {
  return axios({
    method: 'get',
    url: `provider-addressbook/?search=${key}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => ({ response: response.data }))
    .catch((error) => ({ error: error.response }));
};

export const addNewProvider = async ({ token, data }) => {
  return axios({
    method: 'post',
    url: `provider-addressbook/`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  })
    .then((response) => ({ response: response.data }))
    .catch((error) => ({ error: error.response }));
};
