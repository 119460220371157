import React, { useEffect, useState } from 'react';

const Version = () => {
  const [backendVersion, setBackendVersion] = useState('Fetching...');

  useEffect(() => {
    setBackendVersionCall();
  }, []);

  const setBackendVersionCall = () => {
    fetch(`${process.env.REACT_APP_API_PATH}/version/`)
      .then((response) => response.json())
      .then((data) => {
        setBackendVersion(data.version);
      });
  };
  return (
    <h5>
      Frontend Deployment Version : {process.env.REACT_APP_FRONTEND_DEPLOYMENT_VERSION}
      <br />
      <br />
      Backend Deployment Version : {backendVersion}
    </h5>
  );
};
export default Version;
