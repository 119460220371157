import React from 'react';
import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom';
import Statistics from 'pages/Statistics';
import Search from 'pages/Search';
import PhysicianStatement from 'pages/PhysicianStatement';
import ClaimantHistory from 'pages/ClaimantHistory';
import BatchProcessingFileUpload from 'pages/BatchProcessing';
import ResultPage from 'pages/ResultPage';
import ViewReport from 'pages/ViewReport';
import ThreadDetails from 'pages/ThreadDetails';
import Inbox from 'pages/Inbox';
import ComposeMessage from 'pages/ComposeMessage';

const AppRoutes = () => {
  let { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path}>
        <Statistics />
      </Route>
      <Route exact path={`/search`}>
        {(props) => <Search {...props} />}
      </Route>
      <Route exact path={`/physician-statement`}>
        <PhysicianStatement />
      </Route>
      <Route exact path={`/claimant-history`}>
        <ClaimantHistory />
      </Route>
      <Route exact path={`/batch-processing`}>
        <BatchProcessingFileUpload />
      </Route>
      <Route exact path={`/result`}>
        {(props) => <ResultPage {...props} />}
      </Route>
      <Route exact path={`/view-report/:id/:patient_id`}>
        <ViewReport />
      </Route>
      <Route exact path={`/thread-details/:id`}>
        <ThreadDetails />
      </Route>
      <Route exact path={`/inbox`}>
        <Inbox />
      </Route>
      <Route exact path={`/compose`}>
        <ComposeMessage />
      </Route>
      <Route path={`${path}/`}>
        <Redirect to={path} />
      </Route>
    </Switch>
  );
};

export default AppRoutes;
