import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import TopNav from 'components/TopNav';
import SideNav from 'components/SideNav';
import AppRoutes from 'routes/AppRoutes';
import Modal from 'components/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { showErrorModal } from 'redux/modules/errorModal/actions';
import { useHistory } from 'react-router-dom';

const Dashboard = () => {
  let modalState = useSelector((state) => state.errorModal);
  const dispatch = useDispatch();
  const history = useHistory();
  const { logout } = useAuth0();
  const {
    user: { name },
  } = useAuth0();

  const closeModal = () => {
    let fallbackUrl = modalState.fallbackUrl || '/dashboard';
    dispatch(
      showErrorModal({
        isVisible: false,
        fallbackUrl: '',
      }),
    );
    history.push(fallbackUrl);
  };

  return (
    <div className="app-container d-flex col-12">
      <SideNav></SideNav>
      <div className="main-area position-relative flex-1">
        <TopNav logout={logout} name={name}></TopNav>
        <AppRoutes />
      </div>
      <Modal
        isOpen={modalState.isVisible}
        handleClose={closeModal}
        handleConfirm={closeModal}
        modalTitle="Error!"
        isHideCancelBtn
        confirmText="Retry"
        modalDesc="Something went wrong."
      />
    </div>
  );
};
export default Dashboard;
