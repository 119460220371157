export const REQUEST_PHYSICIAN_STATEMENTS = 'generateReport/requestPhysicianStatements';
export const SET_LOADING = 'generateReport/isLoading';
export const SET_ERROR = 'generateReport/isError';

export const requestPhysicianStatements = (payload = {}) => ({
  type: REQUEST_PHYSICIAN_STATEMENTS,
  payload,
});

export const isLoading = (payload = {}) => ({
  type: SET_LOADING,
  payload,
});

export const isError = (payload = {}) => ({
  type: SET_ERROR,
  payload,
});
