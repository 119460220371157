import React, { useState, useRef, useEffect } from 'react';
import { IoLogOutOutline, IoNotificationsSharp } from 'react-icons/io5';
import NotificationModal from 'containers/NotificationModal';
import {
  requestMessages,
  updateNewNotificationsCount,
} from 'redux/modules/inbox/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { getRawToken } from 'utils/auth';

const TopNav = ({ logout, name }) => {
  const [isNotificationMenuActive, setIsNotificationMenuActive] = useState(false);
  const [isNewNotifications, setIsNewNotifications] = useState(false);
  const [notificationCount, setNotificationCount] = useState(-1);
  const [notificationLastCount, setNotificationLastCount] = useState(-1);
  const [lastMessageId, setLastMessageId] = useState(null);
  const wrapperRef = useRef(null);
  const dispatch = useDispatch();
  const { getIdTokenClaims } = useAuth0();
  let inboxState = useSelector((state) => state.inbox);
  let timer = useRef();
  const history = useHistory();

  const logoutAfterInactivity_Seconds = 900000; // 15min
  let logoutTimer = null;

  useEffect(() => {
    const events = ['load', 'mousemove', 'mousedown', 'click', 'scroll', 'keypress'];

    const resetTimeout = () => {
      if (logoutTimer) clearTimeout(logoutTimer);
      logoutTimer = setTimeout(logout, logoutAfterInactivity_Seconds);
    };

    for (let i in events) {
      window.addEventListener(events[i], resetTimeout);
    }

    logoutTimer = setTimeout(logout, logoutAfterInactivity_Seconds);

    return () => {
      for (let i in events) {
        window.removeEventListener(events[i], resetTimeout);
        if (logoutTimer) clearTimeout(logoutTimer);
      }
    };
  }, []);

  useEffect(() => {
    checkIsNewNotifications();
    return () => {
      clearInterval(timer.current);
    };
  }, []);

  useEffect(() => {
    if (!!inboxState.markReadCount.length) {
      setTimeout(() => {
        checkIsNewNotifications();
      }, 1000);
    }
  }, [inboxState.markReadCount.length]);

  useEffect(() => {
    if (notificationLastCount < notificationCount && notificationLastCount !== -1) {
      !!lastMessageId &&
        toast.success(
          <div onClick={() => history.push(`/thread-details/${lastMessageId}`)}>
            You have received a new message.
          </div>,
        );
      dispatch(updateNewNotificationsCount(Math.floor(Math.random() * 100000 + 1)));
    }
    setNotificationLastCount(notificationCount);
  }, [notificationCount]);

  const checkIsNewNotifications = () => {
    getCount();
    clearInterval(timer.current);
    timer.current = setInterval(() => {
      getCount();
    }, 15000);
  };

  const getCount = async () => {
    const token = await getIdTokenClaims();
    dispatch(
      requestMessages({
        token: getRawToken(token),
        activeTab: 'NEW',
        setMessagesToState: (response) => {
          if (!!response?.results?.length) {
            setLastMessageId(response?.results[0]?.id || null);
          } else {
            setLastMessageId(null);
          }
          setNotificationCount(Number(response.count));
          setIsNewNotifications(!!response.count);
        },
        pageSize: 1,
        searchKey: '',
      }),
    );
  };

  const useOutsideAlerter = (ref, cb) => {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && event.target.className === 'filter-overlay') {
          cb();
        }
      }
      document.addEventListener('click', handleClickOutside);
      return () => {
        document.removeEventListener('click', handleClickOutside);
      };
    }, [ref]);
  };

  useOutsideAlerter(wrapperRef, () => {
    setIsNotificationMenuActive((menu) => !menu);
  });

  return (
    <div className="topnav position-absolute top-0 right-0 left-0 d-flex flex-items-center flex-justify-end">
      {isNotificationMenuActive && (
        <div className="filter-overlay" ref={wrapperRef}>
          <NotificationModal
            toggleMenu={() => setIsNotificationMenuActive((menu) => !menu)}
          />
        </div>
      )}
      <div className={`position-relative ${isNotificationMenuActive ? 'bell-icon' : ''}`}>
        <IoNotificationsSharp
          size={22}
          className="mr-3 color-text-primary above-overlay"
          onClick={() => setIsNotificationMenuActive((menu) => !menu)}
          style={{ cursor: 'pointer' }}
        />
        {isNewNotifications && <span className="active-noti-dot"></span>}
      </div>

      <p className="fw-medium color-text-secondary mr-2 user-name">{name}</p>
      <button
        className="link h4 lh-condensed-ultra d-flex"
        title="Logout"
        onClick={() => logout()}
      >
        <IoLogOutOutline />
      </button>
    </div>
  );
};
export default TopNav;
