import React, { useState, useEffect } from 'react';
import LoadingBlock from 'components/LoadingBlock';
import { useDispatch, useSelector } from 'react-redux';
import {
  IoAlertCircleOutline,
  IoArrowUpOutline,
  IoArrowDownOutline,
  IoCloseSharp,
} from 'react-icons/io5';
import styled from '@emotion/styled';
import HistoryCard from 'containers/HistoryCard';
import ReactPaginate from 'react-paginate';
import { requestPhysicianStatements } from 'redux/modules/physicianStatements/actions';
import { useAuth0 } from '@auth0/auth0-react';
import { TextInput, DatePickerInput } from 'components/form-input';
import moment from 'moment';
import { getRawToken } from 'utils/auth';

let IoAlertCircleOutlineStyled = styled(IoAlertCircleOutline)`
  font-size: 140px;
  color: rgba(0, 0, 0, 0.2);
`;

let initialFilters = {
  patient__first_name: '',
  patient__last_name: '',
  claim_start_date: '',
  claim_end_date: '',
  ordering: '',
};

const PhysicianStatement = () => {
  const dispatch = useDispatch();
  let modalState = useSelector((state) => state.errorModal);
  const { getIdTokenClaims } = useAuth0();
  const [results, setResults] = useState([]); // in-progress
  const [count, setCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [filters, setFilters] = useState(initialFilters);
  const [tempfilters, setTempFilters] = useState(initialFilters);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [isFetchData, setIsFetchData] = useState(false);
  const [resultStatus, setResultStatus] = useState('in-progress'); // in-progress
  let numberOfPages = Math.ceil(count / 10);
  const [error, setError] = useState('');

  const handlePageClick = (data) => {
    setCurrentPage(Number(data.selected));
  };

  const setHistoryData = (data, count) => {
    setError('');
    setResults(data);
    setCount(Number(count));
    setResultStatus(!!data.length ? 'found' : 'not-found');
  };

  const getArtiFactData = async () => {
    const token = await getIdTokenClaims();
    dispatch(
      requestPhysicianStatements({
        token: getRawToken(token),
        setHistoryData,
        filters,
        page: currentPage + 1,
        onError: onError,
      }),
    );
  };

  const onError = () => {
    setError('Error in fetching data, please try again later.');
  };

  const setFilter = (key, value) => {
    if (key === 'claim_start_date' || key === 'claim_end_date') {
      value = !!value ? moment(value).format('YYYY-MM-DD') : '';
    }
    setTempFilters((filters) => ({
      ...filters,
      [key]: value,
    }));
  };

  const removeFilter = (key) => {
    setTempFilters((filters) => ({
      ...filters,
      [key]: '',
    }));
    setIsFetchData(true);
  };

  const resetFilter = () => {
    setFilters(initialFilters);
    setIsFetchData(true);
  };

  const applyFilters = () => {
    setFilters(tempfilters);
    setIsFilterOpen(false);
    setIsFetchData(false);
    setResultStatus('in-progress');
  };

  const cancelFilter = () => {
    setTempFilters(filters);
    setIsFilterOpen(false);
  };

  const sortByName = () => {
    let sortOrder = filters.ordering;
    if (sortOrder === '' || sortOrder === '-patient__first_name') {
      setFilter('ordering', 'patient__first_name');
    } else if (sortOrder === 'patient__first_name') {
      setFilter('ordering', '-patient__first_name');
    }
    setIsFetchData(true);
  };

  useEffect(() => {
    if (!modalState.isVisible) {
      setResultStatus('in-progress');
      getArtiFactData();
    }
  }, [dispatch, getIdTokenClaims, currentPage, modalState, filters]);

  useEffect(() => {
    if (isFetchData) {
      applyFilters();
    }
  }, [isFetchData]);

  return (
    <div className="px-5 py-4 scrollable-page d-flex flex-column col-12">
      <div className="d-flex flex-justify-between mb-3 flex-content-center">
        <h5 className="color-primary-dark mb-4 mt-0">
          Physician Statements ({count} Found)
        </h5>
        <div className="d-flex flex-items-start">
          {isFilterOpen && <div className="filter-overlay"></div>}
          <button
            className={`btn-actions mr-2 py-2 px-4 position-relative filter-btn ${
              isFilterOpen ? 'bg-white active-filter-btn' : ''
            }`}
            onClick={() => !isFilterOpen && setIsFilterOpen(true)}
          >
            Filter
            {isFilterOpen && (
              <div className="position-absolute bg-white p-4 filter-modal">
                <div className="mb-4">
                  <p className="mt-0 mb-2 color-text-primary">First Name</p>
                  <TextInput
                    value={tempfilters.patient__first_name}
                    onChange={(e) => setFilter('patient__first_name', e.target.value)}
                  />
                </div>
                <div className="mb-4">
                  <p className="mt-0 mb-2 color-text-primary">Last Name</p>
                  <TextInput
                    value={tempfilters.patient__last_name}
                    onChange={(e) => setFilter('patient__last_name', e.target.value)}
                  />
                </div>
                <div className="mb-4">
                  <p className="mt-0 mb-2 color-text-primary">Claim start date</p>
                  <DatePickerInput
                    value={
                      !!tempfilters.claim_start_date
                        ? moment(tempfilters.claim_start_date).toDate()
                        : ''
                    }
                    onChange={(date) => setFilter('claim_start_date', date)}
                    placeholder="Select start date"
                    maxDate={
                      !!tempfilters.claim_end_date
                        ? moment(tempfilters.claim_end_date).toDate()
                        : new Date()
                    }
                  />
                </div>
                <div className="mb-4">
                  <p className="mt-0 mb-2 color-text-primary">Claim end date</p>
                  <DatePickerInput
                    value={
                      !!tempfilters.claim_end_date
                        ? moment(tempfilters.claim_end_date).toDate()
                        : ''
                    }
                    minDate={
                      !!tempfilters.claim_start_date
                        ? moment(tempfilters.claim_start_date).toDate()
                        : undefined
                    }
                    maxDate={new Date()}
                    onChange={(date) => setFilter('claim_end_date', date)}
                    placeholder="Select end date"
                  />
                </div>
                <div className="d-flex flex-justify-between">
                  <span
                    className="btn-secondary py-3 px-3"
                    onClick={() => cancelFilter()}
                  >
                    Cancel
                  </span>
                  <span className="btn-primary py-3 px-3" onClick={() => applyFilters()}>
                    Apply
                  </span>
                </div>
              </div>
            )}
          </button>

          <button
            className={`btn-actions mr-2 py-2 px-4 d-flex flex-items-center ${
              !!filters.ordering ? 'bg-white' : ''
            }`}
            onClick={sortByName}
          >
            Sort{' '}
            {filters.ordering === 'patient__first_name' && (
              <IoArrowUpOutline className="ml-2" />
            )}
            {filters.ordering === '-patient__first_name' && (
              <IoArrowDownOutline className="ml-2" />
            )}
          </button>
        </div>
      </div>
      {!!error && (
        <p className="color-error pl-0">
          {error} or{' '}
          <span
            className="cursor-pointer color-secondary"
            onClick={() => window.location.reload()}
          >
            Reload
          </span>
        </p>
      )}
      <div className="col-12 text-right mb-3">
        {!!filters.patient__first_name && (
          <span className="filter-tag color-text-primary">
            First Name : {filters.patient__first_name}{' '}
            <IoCloseSharp
              className="ml-3 filter-remove-cross"
              onClick={() => removeFilter('patient__first_name')}
            />
          </span>
        )}

        {!!filters.patient__last_name && (
          <span className="filter-tag color-text-primary">
            Last Name : {filters.patient__last_name}{' '}
            <IoCloseSharp
              className="ml-3 filter-remove-cross"
              onClick={() => removeFilter('patient__last_name')}
            />
          </span>
        )}

        {!!filters.claim_start_date && (
          <span className="filter-tag color-text-primary">
            Claim Start Date : {filters.claim_start_date}{' '}
            <IoCloseSharp
              className="ml-3 filter-remove-cross"
              onClick={() => removeFilter('claim_start_date')}
            />
          </span>
        )}

        {!!filters.claim_end_date && (
          <span className="filter-tag color-text-primary">
            Claim End Date : {filters.claim_end_date}{' '}
            <IoCloseSharp
              className="ml-3 filter-remove-cross"
              onClick={() => removeFilter('claim_end_date')}
            />
          </span>
        )}

        {!!filters.ordering && (
          <span className="filter-tag color-text-primary">
            Sorted by Name{' '}
            <IoCloseSharp
              className="ml-3 filter-remove-cross"
              onClick={() => removeFilter('ordering')}
            />
          </span>
        )}
      </div>

      {resultStatus === 'in-progress' && !error && (
        <div className="col-12">
          <LoadingBlock />
        </div>
      )}
      {resultStatus === 'found' && (
        <div className="d-flex flex-column flex-1 flex-justify-between">
          <div className="col-12">
            {results.map((data, index) => (
              <HistoryCard data={data} key={index} />
            ))}
          </div>
          <div className="col-12 d-flex flex-justify-center mt-3">
            {numberOfPages > 1 && (
              <ReactPaginate
                previousLabel={'Prev'}
                nextLabel={'Next'}
                initialPage={currentPage}
                previousClassName="pagination_prev-btn"
                nextClassName="pagination_next-btn"
                breakLabel={'...'}
                breakClassName={'break-me'}
                pageCount={numberOfPages}
                marginPagesDisplayed={3}
                pageRangeDisplayed={7}
                onPageChange={handlePageClick}
                containerClassName={'pagination d-inline-flex m-0 p-0'}
                activeClassName={'active'}
              />
            )}
          </div>
        </div>
      )}
      {resultStatus === 'not-found' && (
        <div className="d-flex flex-column col-12 flex-justify-center flex-items-center mb-6 pb-6">
          <IoAlertCircleOutlineStyled />
          <h1 className="color-primary-light mb-5">No Results Found.</h1>
        </div>
      )}
    </div>
  );
};
export default PhysicianStatement;
