import { inbox } from 'api/';
import { call, takeEvery, put } from 'redux-saga/effects';
import {
  REQUEST_MESSAGES,
  REQUEST_NEXT_MESSAGES,
  isLoading,
  SEND_MESSAGES,
  SEND_REPLY,
  REQUEST_THREAD_MESSAGES,
  MARK_THREAD_READ,
  REQUEST_NEXT_THREAD_MESSAGES,
  REQUEST_SIGNED_URLS,
  getMessageData,
} from './actions';

export function* getMessages({
  payload: {
    token,
    setMessagesToState,
    activeTab,
    pageSize,
    searchKey,
    providerKey,
    patientKey,
    onError,
    shouldCancel = false,
  },
}) {
  try {
    const { response, error } = yield call(inbox.getMessages, {
      token,
      activeTab,
      pageSize,
      searchKey,
      providerKey,
      patientKey,
      shouldCancel,
    });
    yield put(isLoading({ data: false }));
    if (!!response) {
      setMessagesToState?.(response);
      yield put(isLoading({ data: false }));
    } else {
      if (error !== 'canceled') {
        onError?.();
      }
      yield put(isLoading({ data: false }));
    }
  } catch (error) {
    onError?.();
    yield put(isLoading({ data: false }));
  }
}

export function* getThreadMessages({
  payload: { token, setThreadMessagesToState, pageSize, threadId, setError },
}) {
  try {
    const { response } = yield call(inbox.getThreadMessages, {
      token,
      threadId,
      pageSize,
    });
    yield put(isLoading({ data: false }));
    if (!!response) {
      setThreadMessagesToState?.(response);
      yield put(isLoading({ data: false }));
    } else {
      setError('Failed to get the messages');
      yield put(isLoading({ data: false }));
    }
  } catch (error) {
    setError(error.toString() || 'Failed to get the messages');
    yield put(isLoading({ data: false }));
  }
}

export function* setThreadAsRead({ payload: { token, threadId } }) {
  try {
    const { response } = yield call(inbox.markThreadAsRead, {
      token,
      threadId,
    });
  } catch (error) {
    // do nothing
  }
}

export function* getNextMessages({
  payload: { token, setNextMessagesToState, nextUrl, setError },
}) {
  try {
    const { response } = yield call(inbox.getNextMessages, {
      token,
      nextUrl,
    });
    yield put(isLoading({ data: false }));
    if (!!response) {
      setNextMessagesToState?.(response);
      yield put(isLoading({ data: false }));
    } else {
      setError('Failed to get the messages');
      yield put(isLoading({ data: false }));
    }
  } catch (error) {
    setError('Failed to get the messages');
    yield put(isLoading({ data: false }));
  }
}

export function* sendMessages({
  payload: { formData, token, navigateToDetail, onError },
}) {
  try {
    const { response } = yield call(inbox.sendMessage, {
      formData,
      token,
    });

    if (!!response) {
      yield put(getMessageData({ sendMessageData: response?.results[0] }));
      navigateToDetail?.(response?.results[0].id || null);
    } else {
      onError?.();
    }
  } catch (error) {
    onError?.();
  }
}

export function* sendReplies({
  payload: { formData, token, navigateToDetail, threadId, onError },
}) {
  try {
    const { response } = yield call(inbox.sendReply, {
      token,
      threadId,
      formData,
    });

    if (!!response) {
      yield put(
        getMessageData({
          sendMessageData: response?.results[0],
          pageType: 'replyBox',
        }),
      );
      navigateToDetail?.(threadId || null);
    } else {
      onError?.();
    }
  } catch (error) {
    onError?.();
  }
}

export function* requestSignedUrls({ payload: { data, token, onSuccess, onError } }) {
  try {
    const { response } = yield call(inbox.getSignedUrls, {
      data,
      token,
    });

    if (!!response) {
      onSuccess?.(response || null);
    } else {
      onError?.();
    }
  } catch (error) {
    onError?.();
  }
}

function* watchRequestMessages() {
  yield takeEvery(REQUEST_MESSAGES, getMessages);
}

function* watchRequestNextMessages() {
  yield takeEvery(REQUEST_NEXT_MESSAGES, getNextMessages);
}

function* watchSendMessages() {
  yield takeEvery(SEND_MESSAGES, sendMessages);
}

function* watchSendReplies() {
  yield takeEvery(SEND_REPLY, sendReplies);
}

function* watchRequestThreadMessages() {
  yield takeEvery(REQUEST_THREAD_MESSAGES, getThreadMessages);
}

function* watchMarkThreadAsRead() {
  yield takeEvery(MARK_THREAD_READ, setThreadAsRead);
}

function* watchRequestNextThreadMessages() {
  yield takeEvery(REQUEST_NEXT_THREAD_MESSAGES, getNextMessages);
}
function* watchRequestSignedUrls() {
  yield takeEvery(REQUEST_SIGNED_URLS, requestSignedUrls);
}

export const sagas = [
  watchRequestMessages,
  watchRequestNextMessages,
  watchSendMessages,
  watchSendReplies,
  watchRequestThreadMessages,
  watchMarkThreadAsRead,
  watchRequestNextThreadMessages,
  watchRequestSignedUrls,
];
