import { compose, inbox } from 'api/';
import { call, put, takeLatest, takeEvery } from 'redux-saga/effects';
import {
  SEARCH_PATIENT_COMPOSE,
  SEARCH_CLAIMS_COMPOSE,
  SEARCH_PROIVDERS_COMPOSE,
  ADD_NEW_PROVIDER,
  REQUEST_MAIL_SENT_PROGRESS,
} from './actions';

export function* getMsgDeliveryProgress({
  payload: { job_id, token, setProgress, onError },
}) {
  try {
    const { response } = yield call(inbox.getMsgDeliveryProgress, {
      job_id,
      token,
    });

    if (!!response) {
      setProgress(response);
    } else {
      onError?.();
    }
  } catch (error) {
    onError?.();
  }
}

export function* initSearchPatients({
  payload: { keyword, setPatients, token, onError },
}) {
  try {
    const { response } = yield call(compose.searchPatientsCompose, {
      keyword,
      token,
    });
    if (!!response) {
      setPatients?.(response);
    } else {
      onError?.();
    }
  } catch (error) {
    onError?.();
  }
}

export function* initSearchClaims({
  payload: { resource_id, setClaims, token, onError },
}) {
  try {
    const { response } = yield call(compose.searchClaimsCompose, {
      resource_id,
      token,
    });
    if (!!response) {
      setClaims?.(response);
    } else {
      onError?.();
    }
  } catch (error) {
    onError?.();
  }
}

export function* initSearchProviders({ payload: { key, setProviders, token, onError } }) {
  try {
    const { response } = yield call(compose.searchProvidersCompose, {
      key,
      token,
    });
    if (!!response) {
      setProviders?.(response);
    } else {
      onError?.();
    }
  } catch (error) {
    onError?.();
  }
}

export function* addNewProvider({ payload: { data, onSuccess, token, onError } }) {
  try {
    const { response, error } = yield call(compose.addNewProvider, {
      data,
      token,
    });
    if (!!response) {
      onSuccess?.(response);
    } else if (!!error) {
      onError?.(error);
    }
  } catch (e) {
    onError?.(e);
  }
}

function* watchSearchPatients() {
  yield takeLatest(SEARCH_PATIENT_COMPOSE, initSearchPatients);
}

function* watchSearchClaims() {
  yield takeLatest(SEARCH_CLAIMS_COMPOSE, initSearchClaims);
}

function* watchSearchProviders() {
  yield takeLatest(SEARCH_PROIVDERS_COMPOSE, initSearchProviders);
}

function* watchAddNewProvider() {
  yield takeLatest(ADD_NEW_PROVIDER, addNewProvider);
}

function* watchMsgDeliveryProgress() {
  yield takeEvery(REQUEST_MAIL_SENT_PROGRESS, getMsgDeliveryProgress);
}

export const sagas = [
  watchSearchPatients,
  watchSearchClaims,
  watchSearchProviders,
  watchAddNewProvider,
  watchMsgDeliveryProgress,
];
