import axios from 'axios';
import * as searchPatient from './searchPatient';
import * as generateReport from './generateReport';
import * as viewArtifact from './viewArtifact';
import * as history from './history';
import * as dashboard from './dashboard';
import * as inbox from './inbox';
import * as compose from './compose';

axios.defaults.baseURL = process.env.REACT_APP_API_PATH;
export {
  searchPatient,
  generateReport,
  viewArtifact,
  history,
  dashboard,
  inbox,
  compose,
};
