import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';

const DatePickerInput = ({
  value,
  isError,
  placeholder,
  size,
  maxDate,
  onChange,
  onFocus,
  onBlur,
  ...props
}) => {
  const handleChange = (date) => {
    !!onChange && onChange(date);
  };

  const handleFocus = (e) => {
    !!onFocus && onFocus(e);
  };

  const handleBlur = (e) => {
    !!onBlur && onBlur(e);
  };
  return (
    <DatePicker
      dateFormat="MM/dd/yyyy"
      maxDate={maxDate}
      selected={value}
      placeholderText={placeholder}
      onChange={(date) => handleChange(date)}
      onFocus={(e) => handleFocus(e)}
      onBlur={(e) => handleBlur(e)}
      peekNextMonth
      showMonthDropdown
      showYearDropdown
      dropdownMode="select"
      className={`form-input ${isError ? 'border-error' : ''} input-${size} `}
      {...props}
    />
  );
};

DatePickerInput.propTypes = {
  value: PropTypes.any,
  isError: PropTypes.bool,
  placeholder: PropTypes.string,
  size: PropTypes.string,
  maxDate: PropTypes.any,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
};

DatePickerInput.defaultProps = {
  value: '',
  isError: false,
  placeholder: '',
  size: 'auto', // values for size : sm, md, lg, auto
  maxDate: '',
};

export default DatePickerInput;
