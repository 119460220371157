import { all } from 'redux-saga/effects';
import { sagas as searchPatient } from './modules/searchPatient/saga';
import { sagas as generateReport } from './modules/generateReport/saga';
import { sagas as viewArtifact } from './modules/viewArtifact/saga';
import { sagas as physicianStatements } from './modules/physicianStatements/saga';
import { sagas as claimantHistory } from './modules/claimantHistory/saga';
import { sagas as dashboard } from './modules/dashboard/saga';
import { sagas as inbox } from './modules/inbox/saga';
import { sagas as compose } from './modules/compose/saga';

export default function* rootSaga() {
  yield all(
    [
      ...searchPatient,
      ...generateReport,
      ...viewArtifact,
      ...physicianStatements,
      ...claimantHistory,
      ...dashboard,
      ...inbox,
      ...compose,
    ]
      .map((func) => func())
      .concat(),
  );
}
