import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import Auth from 'pages/Auth';
import Dashboard from 'pages/Dashboard';
import StyleGuide from 'pages/StyleGuide';
import Version from 'pages/Version';
import { useAuth0 } from '@auth0/auth0-react';
import AuthLoader from 'components/AuthLoader';

const Routes = () => {
  const { isAuthenticated, isLoading } = useAuth0();
  return !isLoading ? (
    isAuthenticated ? (
      <Router>
        <Switch>
          <Route path="/dashboard">
            <Dashboard />
          </Route>
          <Route exact path="/styleguide">
            <StyleGuide />
          </Route>
          <Route path="/">
            <Dashboard />
          </Route>
        </Switch>
      </Router>
    ) : (
      <Router>
        <Switch>
          <Route exact path="/">
            <Auth />
          </Route>
          <Route exact path="/version">
            <Version />
          </Route>
          <Route path="/">
            <Redirect to="/" />
          </Route>
        </Switch>
      </Router>
    )
  ) : (
    <AuthLoader />
  );
};

export default Routes;
