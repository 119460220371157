import React, { useState, useEffect } from 'react';
import { TextInput, DatePickerInput, DropdownInput } from 'components/form-input';
import { IoAddOutline, IoCloseSharp } from 'react-icons/io5';
import {
  validateName,
  validateEmail,
  validatePhone,
  validateExt,
  validateZip,
  formatPhoneNumber,
} from 'utils/validations';
import { useDispatch } from 'react-redux';
import { searchPatient, resetSearchData } from 'redux/modules/searchPatient/actions';
import { useAuth0 } from '@auth0/auth0-react';
import { useHistory } from 'react-router-dom';
import AuthLoader from 'components/AuthLoader';
import { formatDateToYYYY_MM_DD } from 'utils/dateFormats';
import { toast } from 'react-toastify';
import { searchFormErrorStr } from 'constants/errorStrings';
import { getRawToken } from 'utils/auth';
import moment from 'moment';
import Modal from '../components/Modal';

const Search = (props) => {
  let initialAddress = {
    address1: {
      value: '',
      isValid: true,
      errorStr: searchFormErrorStr.address1,
    },
    address2: {
      value: '',
      isValid: true,
      errorStr: searchFormErrorStr.address2,
    },
    city: {
      value: '',
      isValid: true,
      errorStr: searchFormErrorStr.city,
    },
    state: {
      value: '',
      isValid: true,
      errorStr: searchFormErrorStr.state,
    },
    zip: {
      value: '',
      isValid: true,
      errorStr: searchFormErrorStr.zip,
    },
  };

  let initialState = {
    firstName: {
      value: '',
      isValid: true,
      errorStr: searchFormErrorStr.firstName,
    },
    lastName: {
      value: '',
      isValid: true,
      errorStr: searchFormErrorStr.lastName,
    },
    dob: {
      value: '',
      isValid: true,
      errorStr: searchFormErrorStr.dob,
    },
    gender: {
      value: [{ label: 'Male', value: 'male' }],
      isValid: true,
      errorStr: searchFormErrorStr.gender,
    },
    email: {
      value: '',
      isValid: true,
      errorStr: searchFormErrorStr.email,
    },
    mobilePhone: {
      value: '',
      isValid: true,
      errorStr: searchFormErrorStr.mobilePhone,
    },
    homePhone: {
      value: '',
      isValid: true,
      errorStr: searchFormErrorStr.homePhone,
    },
    workPhone: {
      value: '',
      isValid: true,
      errorStr: searchFormErrorStr.workPhone,
    },
    ext: {
      value: '',
      isValid: true,
      errorStr: searchFormErrorStr.ext,
    },
    address: [initialAddress],
    claimDate: {
      value: '',
      isValid: true,
      errorStr: searchFormErrorStr.claimDate,
    },
    searchStartDate: {
      value: '',
      isValid: true,
      errorStr: searchFormErrorStr.searchStartDate,
    },
    searchEndDate: {
      value: '',
      isValid: true,
      errorStr: searchFormErrorStr.searchEndDate,
    },
  };

  const [formState, setFormState] = useState(initialState);
  const [showToasts, setShowToasts] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const { getIdTokenClaims } = useAuth0();
  const dispatch = useDispatch();
  const history = useHistory();
  const [error, setError] = useState('');
  const isShowError = !!props.location?.state?.showError;
  const [claimDate, setClaimDate] = useState({ value: null, isValid: true });
  const [searchStartDate, setSearchStartDate] = useState({ value: null, isValid: true });
  const [searchEndDate, setSearchEndDate] = useState({ value: null, isValid: true });
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isClickedOnGenerateBtn, setIsClickedOnGenerateBtn] = useState(false);

  useEffect(() => {
    dispatch(resetSearchData());
  }, []);

  useEffect(() => {
    if (isShowError) {
      onError();
      if (
        history?.location &&
        history?.location?.state &&
        history?.location?.state?.showError
      ) {
        const state = { ...history.location.state };
        delete state.showError;
        history.replace({ ...history.location, state });
      }
    }
  }, []);

  useEffect(() => {
    showToasts && promptErrors(formState);
  }, [formState, showToasts]);

  const handleFirstNameBlur = () => {
    setFormState((formState) => ({
      ...formState,
      firstName: {
        ...formState.firstName,
        isValid: validateName(formState.firstName.value),
      },
    }));
  };

  const handleLastNameBlur = () => {
    setFormState((formState) => ({
      ...formState,
      lastName: {
        ...formState.lastName,
        isValid: validateName(formState.lastName.value),
      },
    }));
  };

  const handleDobBlur = () => {
    setFormState((formState) => ({
      ...formState,
      dob: {
        ...formState.dob,
        isValid: !!formState.dob.value,
      },
    }));
  };

  const handleGenderChange = (value) => {
    setFormState((formState) => ({
      ...formState,
      gender: { ...formState.gender, value: value },
    }));
  };

  const handleGenderBlur = () => {
    setFormState((formState) => ({
      ...formState,
      gender: {
        ...formState.gender,
        isValid: formState.gender.value.length,
      },
    }));
  };

  //email handles
  const handleEmailBlur = () => {
    setFormState((formState) => ({
      ...formState,
      email: {
        ...formState.email,
        isValid: !formState.email.value || validateEmail(formState.email.value),
      },
    }));
  };

  //mobile phone handles
  const handleMobileBlur = (name) => {
    setFormState((formState) => ({
      ...formState,
      [name]: {
        ...formState[name],
        isValid: !formState[name].value || validatePhone(formState[name].value),
      },
    }));
  };

  const handleExtBlur = () => {
    setFormState((formState) => ({
      ...formState,
      ext: {
        ...formState.ext,
        isValid: !formState.ext.value || validateExt(formState.ext.value),
      },
    }));
  };

  //generic handles
  const handleFocus = (name) => {
    setFormState((formState) => ({
      ...formState,
      [name]: { ...formState[name], isValid: true },
    }));
  };

  const handleChange = (value, name) => {
    if (name === 'mobilePhone' || name === 'homePhone' || name === 'workPhone') {
      value = value.replace(/([a-zA-Z])/g, '');
      value = !!formatPhoneNumber(value) ? formatPhoneNumber(value) : value;
      if (value.length > 14) {
        return;
      }
    }

    if (name === 'ext') {
      if (value.length > 4) {
        return;
      }
    }

    setFormState((formState) => ({
      ...formState,
      [name]: { ...formState[name], value: value },
    }));
  };

  //address handles
  const handleAddressFocus = (name, index) => {
    let address = formState.address;
    address[index] = {
      ...address[index],
      [name]: { ...address[index][name], isValid: true },
    };

    setFormState((formState) => ({
      ...formState,
      address: address,
    }));
  };

  const handleAddressChange = (value, name, index) => {
    let address = formState.address;
    address[index] = {
      ...address[index],
      [name]: { ...address[index][name], value: value },
    };

    setFormState((formState) => ({
      ...formState,
      address: address,
    }));
  };

  const handleAddressBlur = (name, index) => {
    let address = formState.address;
    address[index] = {
      ...address[index],
      [name]: {
        ...address[index][name],
        isValid:
          name === 'zip'
            ? validateZip(address[index][name].value)
            : validateName(address[index][name].value),
      },
    };

    setFormState((formState) => ({
      ...formState,
      address: address,
    }));
  };

  const addAddress = () => {
    let address = [...formState.address, initialAddress];
    setFormState((formState) => ({
      ...formState,
      address: address,
    }));
  };

  const removeAddress = (index) => {
    let address = formState.address;
    address.splice(index, 1);
    setFormState((formState) => ({
      ...formState,
      address: address,
    }));
  };

  const resetForm = () => {
    setFormState(initialState);
  };

  const confirmGenerate = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const isClaimDateinRange = () => {
    if (
      !!formState.claimDate.value &&
      !!formState.searchStartDate.value &&
      !!formState.searchEndDate.value
    ) {
      let isBetween = moment(formState.claimDate.value).isBetween(
        formState.searchStartDate.value,
        formState.searchEndDate.value,
      );
      let isAtStartOrEnd =
        moment(formState.claimDate.value).isSame(formState.searchStartDate.value) ||
        moment(formState.claimDate.value).isSame(formState.searchEndDate.value);
      return isBetween || isAtStartOrEnd;
    } else {
      return false;
    }
  };

  const showValidation = () => {
    if (!formState.claimDate.value) {
      setClaimDate((claimDate) => ({
        ...claimDate,
        isValid: false,
      }));
    }
    if (!formState.searchStartDate.value) {
      setSearchStartDate((searchStartDate) => ({
        ...searchStartDate,
        isValid: false,
      }));
    }
    if (!formState.searchEndDate.value) {
      setSearchEndDate((searchEndDate) => ({
        ...searchEndDate,
        isValid: false,
      }));
    }
    setIsClickedOnGenerateBtn(true);
  };

  const getFormattedData = () => {
    let basicDetails = {
      first_name: formState.firstName.value,
      last_name: formState.lastName.value,
      dob: formatDateToYYYY_MM_DD(formState.dob.value),
      gender: formState.gender.value[0].value,
    };

    let contactDetails = {
      ...(!!formState.email.value && { email: formState.email.value }),
      ...(!!formState.ext.value && { extension: formState.ext.value }),
      contact_details: [
        ...(!!formState.mobilePhone.value
          ? [
              {
                system: 'phone',
                value: '8800554831',
                use: 'mobile',
              },
            ]
          : []),
        ...(!!formState.workPhone.value
          ? [
              {
                system: 'phone',
                value: '23232326',
                use: 'work',
              },
            ]
          : []),
        ...(!!formState.homePhone.value
          ? [
              {
                system: 'phone',
                value: '23232326',
                use: 'home',
              },
            ]
          : []),
      ],
    };

    let addressDetails = {
      address: formState.address.map((item) => ({
        line: [
          item.address1.value,
          ...(!!item.address2.value ? [item.address2.value] : []),
        ],
        city: item.city.value,
        state: item.state.value,
        postalCode: item.zip.value,
      })),
    };
    let claimDateDetails = {
      claim_date: formatDateToYYYY_MM_DD(formState.claimDate.value),
      claim_start_date: formatDateToYYYY_MM_DD(formState.searchStartDate.value),
      claim_end_date: formatDateToYYYY_MM_DD(formState.searchEndDate.value),
    };
    return { ...basicDetails, ...contactDetails, ...addressDetails, ...claimDateDetails };
  };

  const checkIsFormValid = () => {
    !validateName(formState.firstName.value) && handleFirstNameBlur();
    !validateName(formState.lastName.value) && handleLastNameBlur();
    !formState.dob.value && handleDobBlur();
    !formState.gender.value.length && handleGenderBlur();
    (formState.email.value || validateEmail(formState.email.value)) && handleEmailBlur();
    (formState.mobilePhone.value || validatePhone(formState.mobilePhone.value)) &&
      handleMobileBlur('mobilePhone');
    (formState.workPhone.value || validatePhone(formState.workPhone.value)) &&
      handleMobileBlur('workPhone');
    (formState.homePhone.value || validatePhone(formState.homePhone.value)) &&
      handleMobileBlur('homePhone');
    (formState.ext.value || validateExt(formState.ext.value)) && handleEmailBlur();

    let isAddressValid = formState.address.map((item, index) => {
      let validityList = [];
      for (let key in item) {
        if (item.hasOwnProperty(key)) {
          if (key === 'zip') {
            validityList.push(validateZip(item[key].value));
            !validateZip(item[key].value) && handleAddressBlur(key, index);
          } else if (key !== 'address2') {
            validityList.push(validateName(item[key].value));
            !validateName(item[key].value) && handleAddressBlur(key, index);
          }
        }
      }
      return validityList;
    });
    isAddressValid = [].concat.apply([], isAddressValid);
    isAddressValid = !isAddressValid.includes(false);
    return (
      validateName(formState.firstName.value) &&
      validateName(formState.lastName.value) &&
      !!formState.dob.value &&
      !!formState.gender.value.length &&
      (!formState.email.value || validateEmail(formState.email.value)) &&
      (!formState.mobilePhone.value || validatePhone(formState.mobilePhone.value)) &&
      (!formState.workPhone.value || validatePhone(formState.workPhone.value)) &&
      (!formState.homePhone.value || validatePhone(formState.homePhone.value)) &&
      (!formState.ext.value || validateExt(formState.ext.value)) &&
      isAddressValid
    );
  };

  const promptErrors = (state) => {
    for (let key in state) {
      if (key !== 'address') {
        if (!state[key].isValid) {
          toast.error(state[key].errorStr);
          break;
        }
      } else {
        state[key].forEach((item) => {
          for (let subkey in item) {
            if (!item[subkey].isValid) {
              toast.error(item[subkey].errorStr);
              break;
            }
          }
        });
      }
    }
    setShowToasts(false);
    setIsClicked(false);
  };

  const submitForm = async () => {
    closeModal();
    if (!isClicked) {
      // handle multi clicks
      setIsClicked(true);

      let isFormValid = checkIsFormValid();
      if (isFormValid) {
        const jwtToken = await getIdTokenClaims();
        dispatch(
          searchPatient({
            searchFormData: getFormattedData(),
            token: getRawToken(jwtToken),
            navigateToResults: navigateToResults,
            navigateToSearch: navigateToSearch,
            onError: onError,
          }),
        );
      } else {
        setShowToasts(true);
      }
    }
  };

  const onError = () => {
    setIsLoading(false);
    setError('Error in searching data, please try again later');
  };

  const navigateToResults = () => {
    setIsLoading(true);
    setError('');
    setTimeout(() => {
      history.push('/physician-statement');
      // Reload the 'physician-statement' page
      // window.location.reload();
      setIsLoading(false);
    }, 7000); // 7000 milliseconds = 7 seconds
  };

  const navigateToSearch = () => {
    history.push({
      pathname: '/search',
      state: {
        showError: true,
      },
    });
  };

  return isLoading ? (
    <AuthLoader />
  ) : (
    <div className="px-5 py-4 scrollable-page">
      <h5 className="color-primary-dark mb-4 mt-0">Search Claimant</h5>
      <div className="pt-5 px-5 pb-1 bg-white card overflow-auto">
        <h6 className="color-text-secondary fw-medium mt-0 mb-4">Basic Details</h6>
        <div className="mb-3 d-flex flex-wrap">
          <div className="mr-4 mb-3">
            <p className="mt-0 mb-2 color-text-primary">First Name*</p>
            <TextInput
              value={formState.firstName.value}
              isError={!formState.firstName.isValid}
              size="md"
              onChange={(e) => handleChange(e.target.value, 'firstName')}
              onFocus={() => handleFocus('firstName')}
              onBlur={handleFirstNameBlur}
            />
          </div>
          <div className="mr-4 mb-3">
            <p className="mt-0 mb-2 color-text-primary">Last Name*</p>
            <TextInput
              value={formState.lastName.value}
              isError={!formState.lastName.isValid}
              size="md"
              onChange={(e) => handleChange(e.target.value, 'lastName')}
              onFocus={() => handleFocus('lastName')}
              onBlur={handleLastNameBlur}
            />
          </div>
          <div className="mr-4 mb-3">
            <p className="mt-0 mb-2 color-text-primary">Date Of Birth*</p>
            <DatePickerInput
              value={formState.dob.value}
              isError={!formState.dob.isValid}
              size="md"
              maxDate={new Date()}
              onChange={(date) => handleChange(date, 'dob')}
              onFocus={() => handleFocus('dob')}
              onBlur={handleDobBlur}
              placeholder="Select Date"
            />
          </div>
          <div className="mr-4 mb-3">
            <p className="mt-0 mb-2 color-text-primary">Gender</p>
            <DropdownInput
              values={formState.gender.value}
              isError={!formState.gender.value.length}
              size="md"
              placeholder="Select..."
              options={[
                { label: 'Male', value: 'male' },
                { label: 'Female', value: 'female' },
              ]}
              onChange={handleGenderChange}
              onBlur={handleGenderBlur}
            />
          </div>
        </div>

        <h6 className="color-text-secondary fw-medium mt-0 mb-4">Contact Details</h6>
        <div className="mb-3">
          <div className="mr-4 mb-3">
            <p className="mt-0 mb-2 color-text-primary">Email</p>
            <TextInput
              value={formState.email.value}
              isError={!formState.email.isValid}
              size="lg"
              onChange={(e) => handleChange(e.target.value, 'email')}
              onFocus={() => handleFocus('email')}
              onBlur={handleEmailBlur}
            />
          </div>
        </div>
        <div className="mb-3 d-flex flex-wrap">
          <div className="mr-4 mb-3">
            <p className="mt-0 mb-2 color-text-primary">Mobile Phone</p>
            <TextInput
              value={formState.mobilePhone.value}
              isError={!formState.mobilePhone.isValid}
              size="md"
              onChange={(e) => handleChange(e.target.value, 'mobilePhone')}
              onFocus={() => handleFocus('mobilePhone')}
              onBlur={() => handleMobileBlur('mobilePhone')}
              placeholder="(xxx) xxx-xxxx"
            />
          </div>
          <div className="mr-4 mb-3">
            <p className="mt-0 mb-2 color-text-primary">Home Phone</p>
            <TextInput
              value={formState.homePhone.value}
              isError={!formState.homePhone.isValid}
              size="md"
              onChange={(e) => handleChange(e.target.value, 'homePhone')}
              onFocus={() => handleFocus('homePhone')}
              onBlur={() => handleMobileBlur('homePhone')}
              placeholder="(xxx) xxx-xxxx"
            />
          </div>
          <div className="mr-4 mb-3">
            <p className="mt-0 mb-2 color-text-primary">Work Phone</p>
            <TextInput
              value={formState.workPhone.value}
              isError={!formState.workPhone.isValid}
              size="md"
              onChange={(e) => handleChange(e.target.value, 'workPhone')}
              onFocus={() => handleFocus('workPhone')}
              onBlur={() => handleMobileBlur('workPhone')}
              placeholder="(xxx) xxx-xxxx"
            />
          </div>
          <div className="mr-4 mb-3">
            <p className="mt-0 mb-2 color-text-primary">Ext</p>
            <TextInput
              value={formState.ext.value}
              isError={!formState.ext.isValid}
              size="md"
              onChange={(e) => handleChange(e.target.value, 'ext')}
              onFocus={() => handleFocus('ext')}
              onBlur={handleExtBlur}
            />
          </div>
        </div>

        <h6 className="color-text-secondary fw-medium mt-0 mb-4 d-flex">
          Communication Address
          <p
            className="link ml-3 d-inline-flex fw-medium my-0 flex-items-center"
            onClick={addAddress}
          >
            <IoAddOutline /> Add Address
          </p>
        </h6>
        {formState.address.map((_, index) => (
          <div key={index} className="position-relative mb-4 pr-6">
            {formState.address.length > 1 && (
              <span
                className="link color-error position-absolute top-0 right-0 mt-4"
                title="Remove"
                onClick={() => removeAddress(index)}
              >
                <IoCloseSharp />
              </span>
            )}
            <div className="mb-3 d-flex flex-wrap">
              <div className="mr-4 mb-3">
                <p className="mt-0 mb-2 color-text-primary">Address 1*</p>
                <TextInput
                  value={formState.address[index].address1.value}
                  isError={!formState.address[index].address1.isValid}
                  size="lg"
                  onChange={(e) => handleAddressChange(e.target.value, 'address1', index)}
                  onFocus={() => handleAddressFocus('address1', index)}
                  onBlur={() => handleAddressBlur('address1', index)}
                />
              </div>
              <div className="mr-4 mb-3">
                <p className="mt-0 mb-2 color-text-primary">Address 2</p>
                <TextInput
                  value={formState.address[index].address2.value}
                  isError={false}
                  size="lg"
                  onChange={(e) => handleAddressChange(e.target.value, 'address2', index)}
                />
              </div>
            </div>
            <div className="d-flex flex-wrap">
              <div className="mr-4 mb-3">
                <p className="mt-0 mb-2 color-text-primary">City*</p>
                <TextInput
                  value={formState.address[index].city.value}
                  isError={!formState.address[index].city.isValid}
                  size="md"
                  onChange={(e) => handleAddressChange(e.target.value, 'city', index)}
                  onFocus={() => handleAddressFocus('city', index)}
                  onBlur={() => handleAddressBlur('city', index)}
                />
              </div>
              <div className="mr-4 mb-3">
                <p className="mt-0 mb-2 color-text-primary">State*</p>
                <TextInput
                  value={formState.address[index].state.value}
                  isError={!formState.address[index].state.isValid}
                  size="md"
                  onChange={(e) => handleAddressChange(e.target.value, 'state', index)}
                  onFocus={() => handleAddressFocus('state', index)}
                  onBlur={() => handleAddressBlur('state', index)}
                />
              </div>
              <div className="mr-4 mb-3">
                <p className="mt-0 mb-2 color-text-primary">Zip Code*</p>
                <TextInput
                  value={formState.address[index].zip.value}
                  isError={!formState.address[index].zip.isValid}
                  size="md"
                  onChange={(e) => handleAddressChange(e.target.value, 'zip', index)}
                  onFocus={() => handleAddressFocus('zip', index)}
                  onBlur={() => handleAddressBlur('zip', index)}
                />
              </div>
            </div>
          </div>
        ))}

        <h6 className="color-text-secondary fw-medium mt-0 mb-4">Claim Date Details</h6>
        <div className="mb-4 d-flex flex-wrap">
          <div className="mb-4">
            <div className="mr-4 mb-4">
              <p className="mt-0 mb-2 color-text-primary">Claim Date*</p>
              <DatePickerInput
                value={formState.claimDate.value}
                isError={!formState.claimDate.isValid}
                size="md"
                maxDate={new Date()}
                onChange={(date) => handleChange(date, 'claimDate')}
                onFocus={() => handleFocus('claimDate')}
                onBlur={handleDobBlur}
                placeholder="Select Date"
              />
            </div>
          </div>
          <div className="mb-4">
            <div className="mr-4 mb-4">
              <p className="mt-0 mb-2 color-text-primary">Search Start Date*</p>
              <DatePickerInput
                value={formState.searchStartDate.value}
                isError={!formState.searchStartDate.isValid}
                maxDate={formState.searchEndDate.value || new Date()}
                onChange={(date) => handleChange(date, 'searchStartDate')}
                onFocus={() => handleFocus('searchStartDate')}
                onBlur={handleDobBlur}
                placeholder="Select Start Date"
                disabled={!formState.claimDate.isValid || !formState.claimDate.value}
              />
            </div>
          </div>
          <div className="mb-4">
            <div className="mr-4 mb-4">
              <p className="mt-0 mb-2 color-text-primary">Search End Date*</p>
              <DatePickerInput
                value={formState.searchEndDate.value}
                isError={!formState.searchEndDate.isValid}
                maxDate={new Date()}
                minDate={formState.searchStartDate.value || null}
                onChange={(date) => handleChange(date, 'searchEndDate')}
                onFocus={() => handleFocus('searchEndDate')}
                onBlur={handleDobBlur}
                placeholder="Select End Date"
                disabled={!formState.claimDate.isValid || !formState.claimDate.value}
              />
            </div>
          </div>
        </div>
        <div className="mb-4">
          {isClickedOnGenerateBtn &&
            formState.claimDate.isValid &&
            !!formState.claimDate.value &&
            !!formState.searchStartDate.isValid &&
            !!formState.searchEndDate.isValid &&
            !isClaimDateinRange() && (
              <p className="color-error small mb-0 mt-1">
                Claim date is not in between the search date range.
              </p>
            )}
        </div>
        {!!error && (
          <p className="color-error pl-0">
            {error} or{' '}
            <span
              className="cursor-pointer color-secondary"
              onClick={() => {
                window.location.reload();
              }}
            >
              reload.
            </span>
          </p>
        )}
      </div>
      <div className="d-flex flex-justify-between mt-3">
        <button className="btn-secondary py-3 px-6" onClick={resetForm}>
          Reset
        </button>
        <button
          className="btn-primary py-3 px-6"
          onClick={() => (isClaimDateinRange() ? confirmGenerate() : showValidation())}
        >
          Search Network
        </button>
      </div>

      <Modal
        isOpen={isModalOpen}
        handleClose={closeModal}
        handleConfirm={submitForm}
        modalTitle="Are you sure that you want to generate report?"
        modalDesc="It might take upto few hours to finish generating report."
      />
    </div>
  );
};
export default Search;
