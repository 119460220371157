import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useAuth0 } from '@auth0/auth0-react';
import AuthLoader from 'components/AuthLoader';
import { IoChevronBack, IoAttach, IoChatbubble } from 'react-icons/io5';
import { FiExternalLink } from 'react-icons/fi';
import { useHistory, useLocation } from 'react-router-dom';
import {
  requestThreadMessages,
  markThreadAsRead,
  requestNextThreadMessages,
  clearMessageData,
} from 'redux/modules/inbox/actions';
import { requestMailSentProgress } from 'redux/modules/compose/actions';
import moment from 'moment';
import InfiniteScroll from 'react-infinite-scroll-component';
import LoadingMessageBlock from 'components/LoadingMessageBlock';
import ReplyBox from 'components/ReplyBox';
import { Link } from 'react-router-dom';
import { getRawToken } from 'utils/auth';

const PAGE_SIZE = 15;
const MessageRow = ({ message, index }) => {
  const [isCollapsed, setIsCollapsed] = useState(!!index);

  const getInitials = (name) => {
    let initials = '-';
    if (!!name) {
      let fullName = name.split(' ');
      if (fullName.length === 1) {
        return fullName[0][0].toUpperCase();
      }
      initials = `${fullName[0][0].toUpperCase()}${
        !!fullName[1][0] ? fullName[1][0].toUpperCase() : ''
      }`;
    }
    return initials;
  };

  const getTimeFormatted = (date) => {
    return moment(moment(new Date(date))).calendar({
      sameDay: 'h:mm A',
      nextDay: '[Tomorrow]',
      nextWeek: 'dddd',
      lastDay: '[Yesterday], h:mm A',
      lastWeek: 'Do MMM, h:mm A',
      sameElse: 'DD/MM/YYYY, h:mm A',
    });
  };

  return (
    <div
      className={`inbox-message d-flex ${
        isCollapsed ? 'pb-2 collapsed' : 'pb-3'
      } mb-2 msg-divider`}
    >
      <div
        className={`avatar-initials mr-3 ${
          isCollapsed ? 'mt-0' : ''
        } d-flex flex-items-center flex-justify-center avatar_bg_${message?.author_name[0]?.toUpperCase()}`}
      >
        <h5 className="color-white m-0 p-0">{getInitials(message?.author_name)}</h5>
      </div>
      <div
        className={`flex-1 pt-1 pr-3 ${isCollapsed ? 'cursor-pointer' : ''}`}
        onClick={() => isCollapsed && setIsCollapsed(false)}
      >
        <div
          className="d-flex flex-justify-between cursor-pointer"
          onClick={() => !isCollapsed && setIsCollapsed(true)}
        >
          <div className="d-flex flex-items-center">
            <span className="small color-text-primary fw-semibold">
              {message?.author_name}
            </span>
            <span className="small color-text-secondary ml-1">
              {`<${message?.author_email}>`}
            </span>
            {isCollapsed && !!message.attachments.length && (
              <IoAttach size={18} className="ml-2 color-secondary" />
            )}
            {!message.read && <span className="new-tag ml-3">New</span>}
          </div>
          <span className="x-small color-text-secondary">
            {getTimeFormatted(message.created_at)}
          </span>
        </div>
        <div className={`${isCollapsed ? 'text-ellipsis' : ''}`}>
          <p
            className={`small fw-normal color-text-primary mt-2 mb-0 lh-default thread-message ${
              isCollapsed ? 'child-tags-with-no-margin' : ''
            }`}
            dangerouslySetInnerHTML={{ __html: message.body }}
          ></p>
          {!isCollapsed && !!message.attachments.length && (
            <div className="mt-3">
              {message.attachments.map((file, index) => (
                <a
                  href={file.download_url}
                  target="_blank"
                  className="no-underline"
                  rel="noreferrer"
                  key={index}
                >
                  <p
                    className="fw-semibold color-secondary my-1 d-inline-flex flex-items-center small"
                    key={index}
                  >
                    <IoAttach size={22} className="mr-1" />
                    {file.name}{' '}
                  </p>
                </a>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const ThreadDetails = () => {
  let { id } = useParams();
  let threadId = id;
  const location = useLocation();
  const dispatch = useDispatch();
  const { getIdTokenClaims } = useAuth0();
  const history = useHistory();
  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [threadDetails, setThreadDetails] = useState({});
  const [nextUrl, setNextUrl] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [errorMsg, setErrorMsg] = useState('');
  const [replyBox, setReplyBox] = useState(false);
  const [progress, setProgress] = useState(-1);
  const [msgDeliveryState, setMsgDelivery] = useState('');

  const [errorMail, setErrorMail] = useState('');

  let timer = useRef();
  let inboxState = useSelector((state) => state.inbox);
  let activeTab = location?.state?.activeTab || null;
  const { sendMessageData, pageType } = inboxState;
  const { task_id } = sendMessageData;
  const getProgressData = useCallback(async () => {
    if (
      !sendMessageData ||
      !task_id ||
      msgDeliveryState === 'NOTFOUND' ||
      msgDeliveryState === 'FAIL'
    ) {
      return;
    }
    const token = await getIdTokenClaims();
    dispatch(
      requestMailSentProgress({
        token: getRawToken(token),
        job_id: task_id,
        setProgress: setMsgDeliveryProgress,
        onError: onError,
      }),
    );
  }, [dispatch, getIdTokenClaims, msgDeliveryState, task_id]);

  const onError = () => {
    toast.error(`Couldn't send the email`);
    dispatch(clearMessageData());
    setReplyBox(false);
    setErrorMail(
      `Email didn't send. Please try again or contact administrator if problem persists.`,
    );
  };

  const setMsgDeliveryProgress = (data) => {
    setErrorMail('');
    if (!data) return;
    setProgress(data?.progress?.percent);
    if (
      data.result === 'NOTFOUND' ||
      data.result === 'FAIL' ||
      data.state === 'FAILURE'
    ) {
      clearInterval(timer.current);
      if (data.state === 'FAILURE') {
        toast.error(`Couldn't send the email`);
        dispatch(clearMessageData());
        setReplyBox(false);
        setMsgDelivery('FAIL');
      } else {
        setMsgDelivery(data.result);
      }
      return;
    }
    if (!!data.complete) {
      clearInterval(timer.current);
      toast.success('Mesage sent!');
      setReplyBox(false);
      if (pageType === 'replyBox') {
        dispatch(clearMessageData());
        history.go(0);
      } else {
        dispatch(clearMessageData());
      }
    }
  };

  useEffect(() => {
    if (!!timer.current) {
      clearInterval(timer.current);
    }
    if (Number(progress) < 100 && Number(progress) !== -1) {
      timer.current = setInterval(() => getProgressData(), 2000);
    } else {
      clearInterval(timer.current);
    }
  }, [progress, getProgressData, msgDeliveryState]);

  useEffect(() => {
    if (msgDeliveryState !== 'NOTFOUND' && msgDeliveryState !== 'FAIL') {
      getProgressData();
    }
    return () => {
      clearInterval(timer.current);
    };
  }, [getProgressData, msgDeliveryState]);
  useEffect(() => {
    getThreadMessages(setThreadMessagesToState, PAGE_SIZE, threadId, setError);
  }, [threadId, dispatch, getIdTokenClaims, location?.state]);

  useEffect(() => {
    if (!!inboxState.newCount && inboxState.newCount > 0) {
      //fetch new messages
      getThreadMessages(setThreadMessagesToState, PAGE_SIZE, threadId, setError);
    }
  }, [inboxState.newCount]);

  const getThreadMessages = async (
    setThreadMessagesToState,
    pageSize = PAGE_SIZE,
    threadId,
    setError,
  ) => {
    const token = await getIdTokenClaims();
    dispatch(
      requestThreadMessages({
        token: getRawToken(token),
        setThreadMessagesToState,
        pageSize,
        threadId,
        setError,
      }),
    );
  };

  const setThreadAsRead = async (threadId) => {
    const token = await getIdTokenClaims();
    dispatch(
      markThreadAsRead({
        token: getRawToken(token),
        threadId,
      }),
    );
  };

  const setThreadMessagesToState = (response) => {
    setErrorMsg('');
    let otherDetails = {
      subject: response.subject || '',
      provider_name: response.provider_name || '',
      provider_email: response.provider_email || '',
      patient_id: response.patient_id || '',
      patient_name: response.patient_name || '',
      claim_id: response.claim_id || '',
      claim_date: response.claim_date || '',
    };
    setThreadDetails(otherDetails);
    setMessages(response.results);
    setThreadAsRead(threadId);
    setIsLoading(false);
    setNextUrl(response.next);
    if (!response.next) {
      setHasMore(false);
    } else {
      setHasMore(true);
    }
  };

  const setError = (status) => {
    setErrorMsg(status);
    setIsLoading(false);
  };

  const getNextMessages = async () => {
    if (!!nextUrl) {
      const token = await getIdTokenClaims();
      dispatch(
        requestNextThreadMessages({
          token: getRawToken(token),
          nextUrl: nextUrl,
          setNextMessagesToState,
          setError,
        }),
      );
    }
  };

  const setNextMessagesToState = (response) => {
    setErrorMsg('');
    setMessages((msg) => [...msg, ...response.results]);
    setNextUrl(response.next);
    if (!response.next) {
      setHasMore(false);
    } else {
      setHasMore(true);
    }
  };

  return isLoading ? (
    <AuthLoader />
  ) : (
    <div className="px-5 py-4 scrollable-page">
      <h5 className="color-primary-dark mb-4 mt-0 d-flex flex-items-center">
        <IoChevronBack
          onClick={() => {
            history.push({
              pathname: `/inbox`,
              state: {
                activeTab,
              },
            });
          }}
          className="btn-round-back mr-3"
        />
      </h5>
      {!!errorMail && !task_id && <p className="color-error pl-0">{errorMail}</p>}
      <h6 className="m-0">{threadDetails.subject}</h6>
      <div className="mt-3">
        <div className="d-flex flex-items-center">
          <span className="small color-text-secondary">Provider : </span>
          <span className="small color-text-primary fw-semibold ml-1">
            {threadDetails.provider_name}
          </span>
          <span className="small color-text-secondary ml-1">{`<${threadDetails.provider_email}>`}</span>
        </div>
      </div>
      <div className="mt-3">
        <span className="small color-text-secondary">Patient : </span>
        <span className="small color-text-primary fw-semibold">
          {threadDetails.patient_name}
        </span>
      </div>
      <div className="mt-3">
        <span className="small color-text-secondary">Claim Date : </span>
        <span className="small color-text-primary fw-semibold d-inline-flex">
          {threadDetails.claim_date}
          <Link
            className="p color-text-primary clean-link ml-1 "
            to={`/view-report/${threadDetails.claim_id}/${threadDetails.patient_id}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FiExternalLink />
          </Link>
        </span>
      </div>
      {!!errorMsg ? (
        <p className="color-error text-right">{errorMsg}</p>
      ) : (
        <div>
          {replyBox && <ReplyBox show={replyBox} state={setReplyBox} />}
          <div className="mt-3 thread-detail-wrapper">
            <InfiniteScroll
              dataLength={messages.length}
              next={getNextMessages}
              hasMore={hasMore}
              loader={<LoadingMessageBlock />}
              scrollThreshold="300px"
              height="100%"
              endMessage={
                <p className="text-center color-text-secondary">No more messages</p>
              }
            >
              {messages.map((message, index) => (
                <MessageRow key={message.id} message={message} index={index} />
              ))}
            </InfiniteScroll>
            {!replyBox && (
              <button
                className="btn-primary launcher bg-round-faded pt-2 pl-2 pb-2 pr-3 ml-3"
                onClick={() => setReplyBox(!replyBox)}
              >
                <IoChatbubble size={35} className="" />
                Reply
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
export default ThreadDetails;
