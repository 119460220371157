import { history } from 'api/';
import { call, takeLatest, put } from 'redux-saga/effects';
import {
  REQUEST_CLAIMANT_HISTORY,
  SET_CLAIMANT_HISTORY_IN_RESULTS,
  isLoading,
} from './actions';
import { setDocumentData } from '../searchPatient/actions';

export function* getClaimantHistory({
  payload: { token, key = '', page, setClaimantHistoryData, onError },
}) {
  yield put(isLoading({ data: true }));
  try {
    const { response } = yield call(history.getClaimantHistory, {
      token,
      key,
      page,
    });

    if (!!response) {
      setClaimantHistoryData?.(response.results, response.count);
      yield put(isLoading({ data: false }));
    } else {
      onError?.();
      yield put(isLoading({ data: false }));
    }
  } catch (error) {
    onError?.();
    yield put(isLoading({ data: false }));
  }
}

export function* setClaimantHistoryDataInResults({
  payload: { data, callback, onError },
}) {
  try {
    yield put(setDocumentData({ data }));
    callback?.();
  } catch (error) {
    yield put(setDocumentData({ data: {} }));
    onError?.();
  }
}

function* watchRequestGetClaimantHistory() {
  yield takeLatest(REQUEST_CLAIMANT_HISTORY, getClaimantHistory);
}

function* watchSetClaimantHistoryInResults() {
  yield takeLatest(SET_CLAIMANT_HISTORY_IN_RESULTS, setClaimantHistoryDataInResults);
}

export const sagas = [watchRequestGetClaimantHistory, watchSetClaimantHistoryInResults];
