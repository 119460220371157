import React from 'react';
import { Link, useRouteMatch, useLocation } from 'react-router-dom';
import Logo from 'assets/images/cornerstone_logo.png';
import {
  IoPodiumOutline,
  IoSearchOutline,
  IoRefreshOutline,
  IoReceiptOutline,
  IoMailOutline,
  IoServerOutline,
} from 'react-icons/io5';

const SideNav = () => {
  let { url } = useRouteMatch();
  let { pathname } = useLocation();

  if (url.slice(-1) === '/') {
    url = url.substring(0, url.length - 1);
  }
  return (
    <div className="sidenav bg-white">
      <div className="logo mt-6 mb-5 text-center px-3 col-12 float-left">
        <img src={Logo} alt="Cornerstone logo" />
      </div>
      <div className="px-3 col-12 float-left mt-5">
        <Link
          to={`/dashboard`}
          className={`nav-link p fw-medium d-flex flex-items-center no-underline ${
            pathname === url ? 'active' : ''
          }`}
        >
          <IoPodiumOutline className="pl-3 pr-4 h6 fw-regular" />
          Dashboard
        </Link>

        <Link
          to={`/search`}
          className={`nav-link p fw-medium d-flex flex-items-center no-underline mt-2 ${
            pathname === url + '/search' ? 'active' : ''
          }`}
        >
          <IoSearchOutline className="pl-3 pr-4 h6 fw-regular" />
          Search
        </Link>

        <Link
          to={`/batch-processing`}
          className={`nav-link p fw-medium d-flex flex-items-center no-underline mt-2 ${
            pathname === url + '/batch-processing' ? 'active' : ''
          }`}
        >
          <IoServerOutline className="pl-3 pr-4 h6 fw-regular" />
          Batch Processing
        </Link>

        <Link
          to={`/physician-statement`}
          className={`nav-link p fw-medium d-flex flex-items-center no-underline mt-2 ${
            pathname === url + '/physician-statement' ? 'active' : ''
          }`}
        >
          <IoRefreshOutline className="pl-3 pr-4 h6 fw-regular" />
          Physician Statement
        </Link>

        <Link
          to={`/claimant-history`}
          className={`nav-link p fw-medium d-flex flex-items-center no-underline mt-2 ${
            pathname === url + '/claimant-history' ? 'active' : ''
          }`}
        >
          <IoReceiptOutline className="pl-3 pr-4 h6 fw-regular" />
          Claimant History
        </Link>

        <Link
          to={`/inbox`}
          className={`nav-link p fw-medium d-flex flex-items-center no-underline mt-2 ${
            pathname === url + '/inbox' ? 'active' : ''
          }`}
        >
          <IoMailOutline className="pl-3 pr-4 h6 fw-regular" />
          Inbox
        </Link>
      </div>
    </div>
  );
};
export default SideNav;
