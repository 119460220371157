export const REQUEST_GENERATE_REPORT = 'generateReport/requestGenerateReport';
export const REQUEST_PDF_REPORT = 'generateReport/requestPdfReport';
export const REQUEST_REPORT_PROGRESS = 'generateReport/requestReportProgress';

export const requestGenerateReport = (payload = {}) => ({
  type: REQUEST_GENERATE_REPORT,
  payload,
});

export const requestPdfReport = (payload = {}) => ({
  type: REQUEST_PDF_REPORT,
  payload,
});

export const requestReportProgress = (payload = {}) => ({
  type: REQUEST_REPORT_PROGRESS,
  payload,
});
