import {
  MARK_THREAD_READ,
  NEW_NOTIFICATIONS_COUNT,
  GET_MESSAGE_DATA,
  CLEAR_MESSAGE_DATA,
} from './actions';

let initialState = {
  markReadCount: [],
  count: 0,
  newCount: 0,
  sendMessageData: {},
  pageType: '',
};

const reducer = (state = initialState, { payload, type }) => {
  switch (type) {
    case MARK_THREAD_READ:
      return { ...state, markReadCount: [...state.markReadCount, state.count + 1] };
    case NEW_NOTIFICATIONS_COUNT:
      return { ...state, newCount: payload };
    case GET_MESSAGE_DATA:
      return {
        ...state,
        sendMessageData: payload?.sendMessageData,
        pageType: payload?.pageType || '',
      };
    case CLEAR_MESSAGE_DATA:
      return { ...state, sendMessageData: {}, pageType: '' };
    default:
      return state;
  }
};

export default reducer;
