export const REQUEST_MESSAGES = 'inbox/requestMessages';
export const REQUEST_NEXT_MESSAGES = 'inbox/requestNextMessages';
export const SEND_MESSAGES = 'inbox/sendMessage';
export const SEND_REPLY = 'inbox/sendReply';
export const SET_LOADING = 'inbox/isLoading';
export const SET_ERROR = 'inbox/isError';
export const REQUEST_THREAD_MESSAGES = 'inbox/requestThreadMessages';
export const MARK_THREAD_READ = 'inbox/markThreadAsRead';
export const REQUEST_NEXT_THREAD_MESSAGES = 'inbox/requestNextThreadMessages';
export const REQUEST_SIGNED_URLS = 'inbox/requestSignedUrls';
export const NEW_NOTIFICATIONS_COUNT = 'inbox/newNotificationsCount';
export const GET_MESSAGE_DATA = 'inbox/getMessageData';
export const CLEAR_MESSAGE_DATA = 'inbox/clearMessageData';

export const sendMessage = (payload = {}) => ({
  type: SEND_MESSAGES,
  payload,
});

export const getMessageData = (payload = {}) => ({
  type: GET_MESSAGE_DATA,
  payload,
});

export const clearMessageData = (payload = {}) => ({
  type: CLEAR_MESSAGE_DATA,
  payload,
});

export const sendReply = (payload = {}) => ({
  type: SEND_REPLY,
  payload,
});

export const requestMessages = (payload = {}) => ({
  type: REQUEST_MESSAGES,
  payload,
});

export const requestNextMessages = (payload = {}) => ({
  type: REQUEST_NEXT_MESSAGES,
  payload,
});

export const isLoading = (payload = {}) => ({
  type: SET_LOADING,
  payload,
});

export const isError = (payload = {}) => ({
  type: SET_ERROR,
  payload,
});

export const requestThreadMessages = (payload = {}) => ({
  type: REQUEST_THREAD_MESSAGES,
  payload,
});

export const markThreadAsRead = (payload = {}) => ({
  type: MARK_THREAD_READ,
  payload,
});

export const requestNextThreadMessages = (payload = {}) => ({
  type: REQUEST_NEXT_THREAD_MESSAGES,
  payload,
});

export const requestSignedUrls = (payload = {}) => ({
  type: REQUEST_SIGNED_URLS,
  payload,
});

export const updateNewNotificationsCount = (payload = {}) => ({
  type: NEW_NOTIFICATIONS_COUNT,
  payload,
});
