import { dashboard } from 'api/';
import { call, takeLatest, put } from 'redux-saga/effects';
import { REQUEST_DASHBOARD, isLoading } from './actions';

export function* initDashboard({ payload: { token, setDashboard, onError } }) {
  try {
    const { response } = yield call(dashboard.getDashboard, {
      token,
    });
    yield put(isLoading({ data: false }));
    if (!!response) {
      setDashboard?.(response);
      yield put(isLoading({ data: false }));
    } else {
      onError?.();
      yield put(isLoading({ data: false }));
    }
  } catch (error) {
    onError?.();
    yield put(isLoading({ data: false }));
  }
}

function* watchRequestDashboard() {
  yield takeLatest(REQUEST_DASHBOARD, initDashboard);
}

export const sagas = [watchRequestDashboard];
