import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { requestMessages, requestNextMessages } from 'redux/modules/inbox/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import AuthLoader from 'components/AuthLoader';
import { getRawToken } from 'utils/auth';

const PAGE_SIZE = 15;

const NotificationModal = ({ toggleMenu }) => {
  const dispatch = useDispatch();

  const [resultStatus, setResultStatus] = useState('in-progress');
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const [nextUrl, setNextUrl] = useState(null);
  const [messages, setMessages] = useState([]);
  const { getIdTokenClaims } = useAuth0();
  const history = useHistory();
  const [error, setError] = useState('');
  let inboxState = useSelector((state) => state.inbox);

  useEffect(() => {
    getMessages(setMessagesToState);
  }, []);

  useEffect(() => {
    if (!!inboxState.newCount && inboxState.newCount > 0) {
      //fetch new messages
      getMessages(setMessagesToState);
    }
  }, [inboxState.newCount]);

  useEffect(() => {
    if (!isInitialLoad) {
      if (!!messages.length) {
        setResultStatus('found');
      } else {
        setResultStatus('not-found');
      }
    }
  }, [messages, isInitialLoad]);

  const getMessages = async (setMessagesToState, pageSize = PAGE_SIZE) => {
    const token = await getIdTokenClaims();
    dispatch(
      requestMessages({
        token: getRawToken(token),
        activeTab: 'NEW',
        setMessagesToState,
        pageSize,
        searchKey: '',
        onError: onError,
      }),
    );
  };

  const onError = () => {
    setError('Error in fetching data, please try again later.');
  };

  const getNextMessages = async () => {
    if (!!nextUrl) {
      const token = await getIdTokenClaims();
      dispatch(
        requestNextMessages({
          token: getRawToken(token),
          nextUrl: nextUrl,
          setNextMessagesToState,
        }),
      );
    }
  };

  const setNextMessagesToState = (response) => {
    setMessages((msg) => [...msg, ...response.results]);
    setNextUrl(response.next);
    if (!response.next) {
      setHasMore(false);
    } else {
      setHasMore(true);
    }
  };

  const setMessagesToState = (response) => {
    setError('');
    setIsInitialLoad(false);
    setMessages(response.results);
    setNextUrl(response.next);
  };

  const handleViewThread = (id) => {
    toggleMenu();
    history.push({
      pathname: `/thread-details/${id}`,
      state: { reloadTimeStamp: new Date() },
    });
  };

  const getTimeFormatted = (date) => {
    return moment(moment(new Date(date))).calendar({
      sameDay: 'h:mm A',
      nextDay: '[Tomorrow]',
      nextWeek: 'dddd',
      lastDay: '[Yesterday], h:mm A',
      lastWeek: 'Do MMM, h:mm A',
      sameElse: 'DD/MM/YYYY, h:mm A',
    });
  };

  return (
    <div className="notification-modal">
      <div className="notification-header">
        <h6 className="p-4 m-0 color-primary-dark">Notifications</h6>
      </div>
      <div className="notification-body py-3 px-4 position-relative">
        {resultStatus === 'not-found' && (
          <div className="d-flex flex-column col-12 flex-justify-center flex-items-center mb-6 pb-6 mt-6">
            <h5 className="color-primary-light mb-2 mt-5">No new messages found.</h5>
          </div>
        )}
        {messages.length === 0 && resultStatus === 'in-progress' && (
          <AuthLoader absolute={true} />
        )}
        {resultStatus === 'found' && (
          <InfiniteScroll
            dataLength={messages.length}
            next={getNextMessages}
            hasMore={hasMore}
            loader={<div></div>}
            scrollThreshold="300px"
            height="100%"
            endMessage={
              <p className="text-center color-text-secondary">No more messages</p>
            }
          >
            {messages.map((message) => (
              <div
                className="single-notifiaction d-flex"
                onClick={() => handleViewThread(message.id)}
              >
                <div className="flex-1">
                  <div className="d-flex flex-justify-between">
                    <div>
                      <span className="x-small color-text-primary fw-semibold">
                        {message?.provider_name}
                      </span>
                    </div>
                    <span className="x-small color-text-secondary">
                      {getTimeFormatted(message.updated_at)}
                    </span>
                  </div>
                  <div className="text-ellipsis">
                    <p className="small fw-normal color-text-primary mt-2 mb-0 lh-default">
                      {message.subject}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </InfiniteScroll>
        )}
        {!!error && (
          <p className="color-error pl-0">
            {error} or{' '}
            <span
              className="cursor-pointer color-secondary"
              onClick={() => window.location.reload()}
            >
              Reload
            </span>
          </p>
        )}
      </div>
    </div>
  );
};

export default NotificationModal;
