export const SEARCH_PATIENT_COMPOSE = 'compose/searchPatientCompose';
export const SEARCH_CLAIMS_COMPOSE = 'compose/searchClaimsCompose';
export const SEARCH_PROIVDERS_COMPOSE = 'compose/searchProvidersCompose';
export const ADD_NEW_PROVIDER = 'compose/addNewProvider';
export const REQUEST_MAIL_SENT_PROGRESS = 'compose/mailSentProgress';

export const requestSearchPatientsCompose = (payload = {}) => ({
  type: SEARCH_PATIENT_COMPOSE,
  payload,
});

export const requestSearchClaimsCompose = (payload = {}) => ({
  type: SEARCH_CLAIMS_COMPOSE,
  payload,
});

export const requestSearchProvidersCompose = (payload = {}) => ({
  type: SEARCH_PROIVDERS_COMPOSE,
  payload,
});

export const addNewProvider = (payload = {}) => ({
  type: ADD_NEW_PROVIDER,
  payload,
});

export const requestMailSentProgress = (payload = {}) => ({
  type: REQUEST_MAIL_SENT_PROGRESS,
  payload,
});
