import { combineReducers } from 'redux';
import searchPatient from './searchPatient/reducer';
import viewArtifact from './viewArtifact/reducer';
import physicianStatements from './physicianStatements/reducer';
import errorModal from './errorModal/reducer';
import inbox from './inbox/reducer';

export default combineReducers({
  searchPatient,
  viewArtifact,
  physicianStatements,
  errorModal,
  inbox,
});
