import React, { useState } from 'react';
import Modal from 'react-modal';
import { IoCloseSharp } from 'react-icons/io5';
import PropTypes from 'prop-types';
import { TextInput } from 'components/form-input';
// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#root');

const ProviderModal = ({
  isOpen,
  handleClose,
  handleConfirm,
  formState,
  handleNameBlur,
  handleEmailBlur,
  handleChange,
  handleFocus,
  modalError,
}) => {
  const [error, setError] = useState('');
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleClose}
      className="modal p-6"
      overlayClassName="modal-overlay"
    >
      <div className="p-4 text-center">
        <h4 className="color-text-primary mt-0 mb-5">Add New Provider</h4>
        <div className="text-left modal-input-wrapper">
          <div className="mb-3">
            <p className="mt-0 mb-2 color-text-primary">Provider Name*</p>
            <TextInput
              value={formState.newProviderName.value}
              isError={!formState.newProviderName.isValid}
              onChange={(e) => handleChange(e.target.value, 'newProviderName')}
              onFocus={() => handleFocus('newProviderName')}
              onBlur={() => handleNameBlur('newProviderName')}
            />
          </div>
          <div className="mb-3">
            <p className="mt-0 mb-2 color-text-primary">Provider Email*</p>
            <TextInput
              value={formState.newProviderEmail.value}
              isError={!formState.newProviderEmail.isValid}
              onChange={(e) => handleChange(e.target.value, 'newProviderEmail')}
              onFocus={() => handleFocus('newProviderEmail')}
              onBlur={() => handleEmailBlur('newProviderEmail')}
            />
          </div>
          <div className="text-right">
            <p className="color-error text-right">{modalError || error}</p>
          </div>
        </div>
        <div className="d-inline-block mt-3">
          <button className="btn-secondary py-3 px-6" onClick={handleClose}>
            Cancel
          </button>

          <button
            className={`btn-primary py-3 px-6 ml-4`}
            onClick={() => {
              if (
                !formState.newProviderName.isValid ||
                !formState.newProviderName.value
              ) {
                setError('Invalid Provider Name.');
                return;
              }
              if (
                !formState.newProviderEmail.isValid ||
                !formState.newProviderEmail.value
              ) {
                setError('Invalid Provider Email.');
                return;
              }

              setError('');
              handleConfirm();
            }}
          >
            <span className="px-5">Add</span>
          </button>
        </div>
      </div>
      <IoCloseSharp className="modal-close-btn" title="Close" onClick={handleClose} />
    </Modal>
  );
};

export default ProviderModal;
