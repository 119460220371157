import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import ProgressButton from 'components/ProgressButton';
import moment from 'moment';
import { formatAddress } from 'utils/addressFormat';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch } from 'react-redux';
import { requestReportProgress } from 'redux/modules/generateReport/actions';
import { getRawToken } from 'utils/auth';
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';

const HistoryCard = ({
  data: {
    name,
    dob,
    gender,
    address,
    claim_date,
    claim_start_date,
    claim_end_date,
    started_at,
    started_by,
    celery_job_id,
    claim_resource_id,
    patient_resource_id,
    status,
  },
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [progress, setProgress] = useState(-1);
  const [expand, setExpand] = useState(false);
  const [isReportGenerated, setIsReportGenerated] = useState(false);
  const { getIdTokenClaims } = useAuth0();
  const [statusState, setStatus] = useState(status);
  const [error, setError] = useState('');

  const handleViewReport = (claim_resource_id) => {
    history.push(`/view-report/${claim_resource_id}/${patient_resource_id}`);
  };

  let date_of_birth = moment(moment(dob, 'YYYY-MM-DD')).format('MM/DD/YYYY');
  let age = moment(moment(dob, 'YYYY-MM-DD')).fromNow(true);
  let started_at_formatted = moment(started_at).format('MM/DD/YYYY');
  let timer = useRef();

  const getProgressData = useCallback(async () => {
    if (statusState === 'NOTFOUND' || statusState === 'FAIL') {
      return;
    }
    const token = await getIdTokenClaims();
    dispatch(
      requestReportProgress({
        token: getRawToken(token),
        job_id: celery_job_id,
        setProgress: setReportProgress,
        onError: onError,
      }),
    );
  }, [progress]);

  const onError = () => {
    setError('Error in fetching progress, please try again later.');
  };

  const setReportProgress = (data) => {
    setError('');
    if (
      data.result === 'NOTFOUND' ||
      data.result === 'FAIL' ||
      data.state === 'FAILURE'
    ) {
      clearInterval(timer.current);
      if (data.state === 'FAILURE') {
        setStatus('FAIL');
      } else {
        setStatus(data.result);
      }
    }
    if (data.complete) {
      clearInterval(timer.current);
    }
    setProgress(data.progress.percent);
    setIsReportGenerated(data.complete);
  };

  useEffect(() => {
    if (!!timer.current) {
      clearInterval(timer.current);
    }
    if (Number(progress) < 100 && Number(progress) !== -1) {
      timer.current = setInterval(() => getProgressData(), 2000);
    } else {
      clearInterval(timer.current);
    }
  }, [progress]);

  useEffect(() => {
    if (statusState !== 'NOTFOUND' && statusState !== 'FAIL') {
      getProgressData();
    }
    return () => {
      clearInterval(timer.current);
    };
  }, []);

  return (
    <div className="col-12 mb-3">
      <div
        className={`${
          statusState === 'FAIL' ? 'failed-report' : 'bg-white'
        } card overflow-auto d-md-flex`}
      >
        <div className="p-5 info-table flex-1">
          <div className="d-flex mb-3">
            <p className="m-0 h6 fw-medium color-text-secondary info-head pr-2 d-flex flex-justify-between">
              <span>Patient Name</span> <span>:</span>
            </p>
            <p className="flex-1 m-0 h6 fw-medium color-text-primary">{name}</p>
          </div>
          <div className="d-flex mb-3">
            <p className="m-0 h6 fw-medium color-text-secondary info-head pr-2 d-flex flex-justify-between">
              <span>Date Of Birth</span>
              <span>:</span>
            </p>
            <p className="flex-1 m-0 h6 fw-medium color-text-primary">
              {' '}
              {date_of_birth} ({age} old)
            </p>
          </div>
          <div className="d-flex mb-3">
            <p className="m-0 h6 fw-medium color-text-secondary info-head pr-2 d-flex flex-justify-between">
              <span>Gender</span>
              <span>:</span>
            </p>
            <p className="flex-1 m-0 h6 fw-medium text-capitalize color-text-primary">
              {gender}
            </p>
          </div>
          <div className="d-flex mb-3">
            <p className="m-0 h6 fw-medium color-text-secondary info-head pr-2 d-flex flex-justify-between">
              <span>Address</span>
              <span>:</span>
            </p>
            <div>
              <p className="m-0 mb-2 h6 fw-medium color-text-primary">
                {formatAddress(address[0])}{' '}
                {address.length > 1 &&
                  (!expand ? (
                    <BsChevronDown
                      className="align-middle"
                      onClick={() => {
                        setExpand(true);
                      }}
                    />
                  ) : (
                    <BsChevronUp
                      className="align-middle"
                      onClick={() => {
                        setExpand(false);
                      }}
                    />
                  ))}
              </p>

              {address.length > 1 &&
                !!expand &&
                address
                  .slice(1)
                  .map((addr) => (
                    <p className="m-0 mb-2 h6 fw-medium color-text-primary">
                      {formatAddress(addr)}
                    </p>
                  ))}
            </div>
          </div>
          <div className="d-flex mb-3">
            <p className="m-0 h6 fw-medium color-text-secondary info-head pr-2 d-flex flex-justify-between">
              <span>Claim Date</span>
              <span>:</span>
            </p>
            <p className="flex-1 m-0 h6 fw-medium color-text-secondary ">
              {moment(moment(claim_date, 'YYYY-MM-DD')).format('MM/DD/YYYY')}
            </p>
          </div>
          <div className="d-flex">
            <p className="m-0 h6 fw-medium color-text-secondary info-head pr-2 d-flex flex-justify-between">
              <span>Date Range</span>
              <span>:</span>
            </p>
            <p className="flex-1 m-0 h6 fw-medium color-text-secondary ">
              {moment(moment(claim_start_date, 'YYYY-MM-DD')).format('MM/DD/YYYY')} -{' '}
              {moment(moment(claim_end_date, 'YYYY-MM-DD')).format('MM/DD/YYYY')}
            </p>
          </div>
        </div>
        <div className="divider flex-self-stretch"></div>
        <div className="col-12 col-md-4 p-5 d-flex flex-column flex-justify-between">
          <div className="mb-4 d-flex flex-column">
            <div className="d-flex mb-3">
              <p className="m-0 h6 fw-medium color-text-secondary info-head pr-2 d-flex flex-justify-between">
                <span>Started At</span>
                <span>:</span>
              </p>
              <p className="flex-1 m-0 h6 fw-medium color-text-secondary ">
                {started_at_formatted}
              </p>
            </div>
            <div className="d-flex mb-3">
              <p className="m-0 h6 fw-medium color-text-secondary info-head pr-2 d-flex flex-justify-between">
                <span>Started By</span>
                <span>:</span>
              </p>
              <p className="flex-1 m-0 h6 fw-medium color-text-secondary ">
                {started_by}
              </p>
            </div>
          </div>
          {statusState !== 'NOTFOUND' &&
            statusState !== 'FAIL' &&
            (!isReportGenerated ? (
              <ProgressButton progress={progress} />
            ) : (
              <button
                className={`btn-primary py-3 px-6 col-12`}
                onClick={() => handleViewReport(claim_resource_id)}
              >
                View Report
              </button>
            ))}
          {statusState === 'NOTFOUND' && (
            <button
              className={`btn-disabled py-3 px-6 col-12`}
              onClick={() => {
                return false;
              }}
            >
              Not Found
            </button>
          )}
          {statusState === 'FAIL' && (
            <button
              className={`btn-disabled py-3 px-6 col-12`}
              onClick={() => {
                return false;
              }}
            >
              Failed
            </button>
          )}
          {!!error && (
            <p className="color-error pl-0">
              {error} or{' '}
              <span
                className="cursor-pointer color-secondary"
                onClick={() => window.location.reload()}
              >
                Reload
              </span>
            </p>
          )}
        </div>
      </div>
    </div>
  );
};
export default HistoryCard;
