import React, { useState } from 'react';
import { DatePickerInput } from 'components/form-input';
import Modal from 'components/Modal';
import { formatDateToYYYY_MM_DD } from 'utils/dateFormats';
import moment from 'moment';
import { requestGenerateReport } from 'redux/modules/generateReport/actions';
import { useDispatch } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { useHistory } from 'react-router-dom';
import { formatAddress } from 'utils/addressFormat';
import { getRawToken } from 'utils/auth';
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';

const ResultCard = ({ searchPatient, isFromClaimantHistoryPage }) => {
  const [claimDate, setClaimDate] = useState({ value: null, isValid: true });
  const [searchStartDate, setSearchStartDate] = useState({ value: null, isValid: true });
  const [searchEndDate, setSearchEndDate] = useState({ value: null, isValid: true });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [expand, setExpand] = useState(false);
  const [isClickedOnGenerateBtn, setIsClickedOnGenerateBtn] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const { getIdTokenClaims } = useAuth0();
  const [error, setError] = useState('');

  let {
    documentData: { data },
  } = searchPatient;
  let date_of_birth = moment(moment(data.dob, 'YYYY-MM-DD')).format('MM/DD/YYYY');
  let age = moment(moment(data.dob, 'YYYY-MM-DD')).fromNow(true);

  const handleClaimDateChange = (value) => {
    setClaimDate((claimDate) => ({
      ...claimDate,
      value,
    }));
  };

  const handleClaimDateBlur = () => {
    setClaimDate((claimDate) => ({
      ...claimDate,
      isValid: !!claimDate.value,
    }));
  };

  const handleClaimDateFocus = () => {
    setClaimDate((claimDate) => ({
      ...claimDate,
      isValid: true,
    }));
    setIsClickedOnGenerateBtn(false);
  };

  const handleSearchStartDateChange = (value) => {
    setSearchStartDate((searchStartDate) => ({
      ...searchStartDate,
      value,
    }));
  };

  const handleSearchStartDateBlur = () => {
    setSearchStartDate((searchStartDate) => ({
      ...searchStartDate,
      isValid: !!searchStartDate.value,
    }));
  };

  const handleSearchStartDateFocus = () => {
    setSearchStartDate((searchStartDate) => ({
      ...searchStartDate,
      isValid: true,
    }));
    setIsClickedOnGenerateBtn(false);
  };

  const handleSearchEndDateChange = (value) => {
    setSearchEndDate((searchEndDate) => ({
      ...searchEndDate,
      value,
    }));
  };

  const handleSearchEndDateBlur = () => {
    setSearchEndDate((searchEndDate) => ({
      ...searchEndDate,
      isValid: !!searchEndDate.value,
    }));
  };

  const handleSearchEndDateFocus = () => {
    setSearchEndDate((searchEndDate) => ({
      ...searchEndDate,
      isValid: true,
    }));
    setIsClickedOnGenerateBtn(false);
  };

  const isClaimDateinRange = () => {
    if (!!claimDate.value && !!searchStartDate.value && !!searchEndDate.value) {
      let isBetween = moment(claimDate.value).isBetween(
        searchStartDate.value,
        searchEndDate.value,
      );
      let isAtStartOrEnd =
        moment(claimDate.value).isSame(searchStartDate.value) ||
        moment(claimDate.value).isSame(searchEndDate.value);
      return isBetween || isAtStartOrEnd;
    } else {
      return false;
    }
  };

  const confirmGenerate = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const navigateToPhysicianStatements = () => {
    setError('');
    history.push('/physician-statement');
  };

  const generateReport = async () => {
    closeModal();
    const token = await getIdTokenClaims();
    dispatch(
      requestGenerateReport({
        params: {
          patient_resource_id: data.resource_id,
          claim_date: formatDateToYYYY_MM_DD(claimDate.value),
          claim_start_date: formatDateToYYYY_MM_DD(searchStartDate.value),
          claim_end_date: formatDateToYYYY_MM_DD(searchEndDate.value),
        },
        token: getRawToken(token),
        navigateToPhysicianStatements: navigateToPhysicianStatements,
        onError: onError,
      }),
    );
  };

  const onError = () => {
    setError('Error in fetching data, please try again later.');
  };

  const showValidation = () => {
    if (!claimDate.value) {
      setClaimDate((claimDate) => ({
        ...claimDate,
        isValid: false,
      }));
    }
    if (!searchStartDate.value) {
      setSearchStartDate((searchStartDate) => ({
        ...searchStartDate,
        isValid: false,
      }));
    }
    if (!searchEndDate.value) {
      setSearchEndDate((searchEndDate) => ({
        ...searchEndDate,
        isValid: false,
      }));
    }
    setIsClickedOnGenerateBtn(true);
  };

  return (
    <div className="col-12">
      {!isFromClaimantHistoryPage && (
        <h5 className="color-primary-dark mb-4 mt-0">1 Result Found</h5>
      )}
      <div className="bg-white card overflow-auto d-md-flex">
        <div className="p-5 info-table flex-1">
          <div className="d-flex mb-3">
            <p className="m-0 h6 fw-medium color-text-secondary info-head pr-2 d-flex flex-justify-between">
              <span>Patient Name</span> <span>:</span>
            </p>
            <p className="flex-1 m-0 h6 fw-medium color-text-primary">{`${data.first_name} ${data.last_name}`}</p>
          </div>
          <div className="d-flex mb-3">
            <p className="m-0 h6 fw-medium color-text-secondary info-head pr-2 d-flex flex-justify-between">
              <span>Date Of Birth</span>
              <span>:</span>
            </p>
            <p className="flex-1 m-0 h6 fw-medium color-text-primary">
              {date_of_birth} ({age} old)
            </p>
          </div>
          <div className="d-flex mb-3">
            <p className="m-0 h6 fw-medium color-text-secondary info-head pr-2 d-flex flex-justify-between">
              <span>Gender</span>
              <span>:</span>
            </p>
            <p className="flex-1 m-0 h6 fw-medium text-capitalize color-text-primary">
              {data.gender}
            </p>
          </div>
          <div className="d-flex">
            <p className="m-0 h6 fw-medium color-text-secondary info-head pr-2 d-flex flex-justify-between">
              <span>Address</span>
              <span>:</span>
            </p>
            <div>
              <p className="m-0 mb-2 h6 fw-medium color-text-primary">
                {formatAddress(data.address[0])}{' '}
                {data.address.length > 1 &&
                  (!expand ? (
                    <BsChevronDown
                      className="align-middle"
                      onClick={() => {
                        setExpand(true);
                      }}
                    />
                  ) : (
                    <BsChevronUp
                      className="align-middle"
                      onClick={() => {
                        setExpand(false);
                      }}
                    />
                  ))}
              </p>

              {data.address.length > 1 &&
                !!expand &&
                data.address
                  .slice(1)
                  .map((addr) => (
                    <p className="m-0 mb-2 h6 fw-medium color-text-primary">
                      {formatAddress(addr)}
                    </p>
                  ))}
            </div>
          </div>
        </div>
        <div className="divider flex-self-stretch"></div>
        <div className="col-12 col-md-4 p-5">
          <div className="mb-4 d-flex flex-column">
            <p className="mt-0 mb-2 color-text-primary fw-medium">Claim Date*</p>
            <DatePickerInput
              value={claimDate.value}
              isError={!claimDate.isValid}
              maxDate={new Date()}
              onChange={(date) => handleClaimDateChange(date)}
              onFocus={handleClaimDateFocus}
              onBlur={handleClaimDateBlur}
              placeholder="Select Claim Date"
            />
          </div>
          <div className="mb-4 d-flex flex-column">
            <p className="mt-0 mb-2 color-text-primary fw-medium">Search Start Date*</p>
            <DatePickerInput
              value={searchStartDate.value}
              isError={!searchStartDate.isValid}
              maxDate={searchEndDate.value || new Date()}
              onChange={(date) => handleSearchStartDateChange(date)}
              onFocus={handleSearchStartDateFocus}
              onBlur={handleSearchStartDateBlur}
              placeholder="Select Search Start Date"
              disabled={!claimDate.isValid || !claimDate.value}
            />
          </div>
          <div className="mb-4 d-flex flex-column">
            <p className="mt-0 mb-2 color-text-primary fw-medium">Search End Date*</p>
            <DatePickerInput
              value={searchEndDate.value}
              isError={!searchEndDate.isValid}
              maxDate={new Date()}
              minDate={searchStartDate.value || null}
              onChange={(date) => handleSearchEndDateChange(date)}
              onFocus={handleSearchEndDateFocus}
              onBlur={handleSearchEndDateBlur}
              placeholder="Select Search End Date"
              disabled={!claimDate.isValid || !claimDate.value}
            />
          </div>
          <div className="mb-5 d-flex flex-column">
            {isClickedOnGenerateBtn &&
              claimDate.isValid &&
              !!claimDate.value &&
              !!searchStartDate.isValid &&
              !!searchEndDate.isValid &&
              !isClaimDateinRange() && (
                <p className="color-error small mb-0 mt-1">
                  Claim date is not in between the search date range.
                </p>
              )}
          </div>
          <button
            className={`btn-primary py-3 px-6 col-12`}
            onClick={() => (isClaimDateinRange() ? confirmGenerate() : showValidation())}
          >
            Generate
          </button>
          {!!error && (
            <p className="color-error pl-0">
              {error} or{' '}
              <span
                className="cursor-pointer color-secondary"
                onClick={() => window.location.reload()}
              >
                Reload
              </span>
            </p>
          )}
        </div>
      </div>
      <Modal
        isOpen={isModalOpen}
        handleClose={closeModal}
        handleConfirm={generateReport}
        modalTitle="Are you sure that you want to generate report?"
        modalDesc="It might take upto few hours to finish generating report."
      />
    </div>
  );
};
export default ResultCard;
