import { SET_ARTIFACT_DATA, SET_ERROR, SET_LOADING, RESET_DATA } from './actions';

let initialState = {
  artifactData: {},
  isError: false,
  isLoading: false,
};

const reducer = (state = initialState, { payload, type }) => {
  switch (type) {
    case SET_ARTIFACT_DATA:
      return { ...state, artifactData: payload.data };
    case SET_ERROR:
      return { ...state, isError: payload.data };
    case SET_LOADING:
      return { ...state, isLoading: payload.data };
    case RESET_DATA:
      return { ...state, artifactData: {} };
    default:
      return state;
  }
};

export default reducer;
