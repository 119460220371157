export const REQUEST_CLAIMANT_HISTORY = 'claimantHistory/requestClaimantHistory';
export const SET_CLAIMANT_HISTORY_IN_RESULTS =
  'claimantHistory/setClaimantHistoryInResults';
export const SET_LOADING = 'claimantHistory/isLoading';
export const SET_ERROR = 'claimantHistory/isError';

export const requestClaimantHistory = (payload = {}) => ({
  type: REQUEST_CLAIMANT_HISTORY,
  payload,
});

export const setClaimantHistoryInResults = (payload = {}) => ({
  type: SET_CLAIMANT_HISTORY_IN_RESULTS,
  payload,
});

export const isLoading = (payload = {}) => ({
  type: SET_LOADING,
  payload,
});

export const isError = (payload = {}) => ({
  type: SET_ERROR,
  payload,
});
