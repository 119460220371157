export const REQUEST_SEARCH_PATIENT = 'searchPatient/requestSearchPatient';
export const SET_SEARCHED_PATIENT_KEYS = 'searchPatient/setSearchedPatientKeys';
export const REQUEST_PATIENT_DOCUMENTS = 'searchPatient/requestPatientDocuments';
export const SET_DOCUMENTS_DATA = 'searchPatient/setDocumentData';
export const RESET_SEARCH_DATA = 'searchPatient/resetSearchData';

export const searchPatient = (payload = {}) => ({
  type: REQUEST_SEARCH_PATIENT,
  payload,
});

export const setSearhedPatientKeys = (payload = []) => ({
  type: SET_SEARCHED_PATIENT_KEYS,
  payload,
});

export const requestPatientDocuments = (payload = {}) => ({
  type: REQUEST_PATIENT_DOCUMENTS,
  payload,
});

export const setDocumentData = (payload = []) => ({
  type: SET_DOCUMENTS_DATA,
  payload,
});

export const resetSearchData = (payload = []) => ({
  type: RESET_SEARCH_DATA,
  payload,
});
