import { generateReport } from 'api/';
import { call, takeLatest, takeEvery, put } from 'redux-saga/effects';
import {
  REQUEST_GENERATE_REPORT,
  REQUEST_PDF_REPORT,
  REQUEST_REPORT_PROGRESS,
} from './actions';

export function* initGenerateReport({
  payload: { params, token, navigateToPhysicianStatements, onError },
}) {
  try {
    const { response } = yield call(generateReport.generateReport, {
      params,
      token,
    });

    if (!!response) {
      if (!!response.job_id) {
        navigateToPhysicianStatements?.();
      } else {
        onError?.();
      }
    } else {
      onError?.();
    }
  } catch (error) {
    onError?.();
  }
}
export function* initGeneratePDF({
  payload: { id, token, openPdfInNewTab, setPass, onError },
}) {
  try {
    const { response, error } = yield call(generateReport.generateReportInPDF, {
      id,
      token,
    });

    //TODO: if error then show some notification on the UI that it failed to download the report

    if (!!response) {
      openPdfInNewTab(response);
    } else {
      onError?.();
    }
  } catch (error) {
    onError?.();
  }
}

export function* getGenerateReportProgress({
  payload: { job_id, token, setProgress, onError },
}) {
  try {
    const { response, error } = yield call(generateReport.generateReportProgress, {
      job_id,
      token,
    });

    if (!!response) {
      setProgress(response);
    } else {
      onError?.();
    }
  } catch (error) {
    onError?.();
  }
}

function* watchRequestGenerateReport() {
  yield takeLatest(REQUEST_GENERATE_REPORT, initGenerateReport);
}

function* watchRequestGeneratePDF() {
  yield takeLatest(REQUEST_PDF_REPORT, initGeneratePDF);
}

function* watchGetGenerateReportProgress() {
  yield takeEvery(REQUEST_REPORT_PROGRESS, getGenerateReportProgress);
}

export const sagas = [
  watchRequestGenerateReport,
  watchRequestGeneratePDF,
  watchGetGenerateReportProgress,
];
